import { Cog6ToothIcon, PencilIcon } from '@heroicons/react/24/outline';
import { isNil } from 'lodash';
import { classNames } from 'src/dashboard/App';
import PencilBackgroundSvg from 'src/images/pencil-background.svg';

type BackgroundColor = 'white' | 'gray-100';

// these comments are needed because programatically constructed tailwind classes do not work
// due to tree shaking. see https://v2.tailwindcss.com/docs/optimizing-for-production for more info
// bg-white bg-gray-100
// focus-within:bg-white focus-within:bg-gray-100
// group-hover:bg-white group-hover:bg-gray-100

type EditableIndicatorProps = {
  backgroundColor?: BackgroundColor;
  className?: string;
  children: React.ReactNode;
  readonly: boolean;
} & (
  | {
      // If you make an additional settings menu available via onClickSettings,
      // you must also indicate what to show in readonly mode.
      // null indicates that the settings menu should not be shown at all
      onClickSettings?: (() => void) | null;
      onClickSettingsReadOnly: (() => void) | null;
    }
  | {
      onClickSettings?: undefined;
      onClickSettingsReadOnly?: undefined;
    }
);
export default function EditableIndicator(props: EditableIndicatorProps) {
  const {
    children,
    className,
    onClickSettings,
    onClickSettingsReadOnly,
    readonly,
  } = props;
  const backgroundColor: BackgroundColor = props.backgroundColor ?? 'white';

  const showAdditionalSettings = readonly
    ? !isNil(onClickSettingsReadOnly)
    : !isNil(onClickSettings);

  return (
    <div
      className={classNames(
        `group relative h-full w-full transition-all duration-200 focus-within:bg-${backgroundColor}`,
        !readonly && 'hover:bg-fuchsia-50 cursor-pointer',
      )}
    >
      <div className="flex h-full w-full items-center rounded-md border-2 border-transparent ring-2 ring-transparent transition-all duration-200 focus-within:border-fuchsia-800 focus-within:ring-fuchsia-200">
        {showAdditionalSettings && (
          <button
            className="absolute right-0 top-0 h-full w-8 flex items-center justify-center"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClickSettings!();
            }}
          >
            <div className="group-hover:bg-fuchsia-50 p-1 rounded-lg transition-all duration-100">
              <Cog6ToothIcon className="mr-1 w-4 h-4 sm:hidden group-hover:block rounded text-fuchsia-700 hover:text-fuchsia-900 hover:bg-white" />
            </div>
          </button>
        )}
        <div
          className={classNames(
            'pointer-events-none absolute right-0 top-[-2px] transition-all duration-200 group-focus-within:opacity-0',
            readonly && 'invisible',
          )}
        >
          <img
            className="pointer-events-none"
            src={PencilBackgroundSvg}
            alt=""
          />
          <PencilIcon
            className={`pointer-events-none absolute top-0 right-0 box-content w-3 stroke-gray-600 py-1 pr-2 transition-all duration-200 group-hover:stroke-[#83196E]`}
          />
        </div>
        <div
          className={classNames(
            className ?? '',
            'flex h-full w-full items-center',
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
