import {
  ArchiveBoxIcon,
  ArrowDownTrayIcon,
  ArrowUturnLeftIcon,
  BackspaceIcon,
  CalendarIcon,
  ChatBubbleLeftIcon,
  CheckCircleIcon,
  PaperAirplaneIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import api from 'src/api';
import Button from 'src/components/Button';
import { CrepeDisplayReadonly } from 'src/components/CrepeEditor';
import { InlineSpinner } from 'src/components/Loading';
import { SectionHeader } from 'src/dashboard/Opportunity/OpportunityDetailPage';
import { User } from 'src/types';
import { unreachable } from 'src/typeUtils';
import logoSlack from '../../../images/logos/Slack-logo-small-RGB.png';
import { AddComment } from '../../PricingFlow/Activity/AddComment';

export function isSystemActivity(
  activity: Activity,
): activity is SystemActivity {
  return 'type' in activity && activity.type === 'system';
}
export interface CommentThread {
  id: string;
  createdAt: string;
  createdByUser: User;
  resolvedAt: string | null;
  Comment: Comment[];
  parentTag?: string;
  slackLink: string | null;
  parentNotificationThreadId?: string;
}

export interface Comment {
  id: string;
  createdAt: string;
  updatedAt: string;
  createdByUser: User;
  comment: string;
}

export type ActivityIconType =
  | 'send'
  | 'plus'
  | 'archive-box'
  | 'arrow-uturn-left'
  | 'backspace'
  | 'x-circle'
  | 'check-circle'
  | 'calendar'
  | 'pencil-square'
  | 'arrow-down-tray';

export interface SystemActivity {
  type: 'system';
  text: React.ReactNode;
  user: User | undefined;
  timestamp: string;
  parentTag?: string;
  icon: ActivityIconType;
}

export type Activity = SystemActivity | CommentThread;

export interface ActivitySectionProps {
  parentType: 'pricingFlowGroup' | 'pricingFlow';
  parentId: string;
  systemActivities: Activity[];
  user: User;
}

export function ActivitySection({
  parentType,
  parentId,
  systemActivities,
  user,
}: ActivitySectionProps) {
  const [activities, setActivities] = useState<Activity[] | null>(null);
  const [showSystemActivity, setShowSystemActivity] = useState(false);

  useEffect(() => {
    loadActivities();
  }, [parentId, systemActivities]);

  async function loadActivities() {
    const commentThreads = await api.get(
      `comments/threads/${parentType}/${parentId}`,
      { refreshFromSlack: true },
    );

    // Merge and sort activities by timestamp/createdAt
    const allActivities = [...systemActivities, ...commentThreads.data].sort(
      (a, b) => {
        const dateA = isSystemActivity(a) ? a.timestamp : a.createdAt;
        const dateB = isSystemActivity(b) ? b.timestamp : b.createdAt;
        return new Date(dateB).getTime() - new Date(dateA).getTime();
      },
    );

    setActivities(allActivities);
  }
  console.log('activities: ', activities);

  async function addComment(comment: string) {
    if (isNil(activities)) {
      return;
    }

    // Create a new comment thread
    const result = await api.post('comments', {
      [`${parentType}Id`]: parentId,
      comment,
    });

    const newThread = {
      ...result.data.thread,
      Comment: [result.data.initialComment],
      createdByUser: user,
    };

    setActivities([newThread, ...activities]);
  }

  async function addReply(threadId: string, comment: string) {
    if (isNil(activities)) {
      return;
    }

    const reply = await api.post(`comments/${threadId}/replies`, {
      comment,
    });

    setActivities(
      activities.map((activity) => {
        if ('type' in activity || activity.id !== threadId) {
          return activity;
        }
        return {
          ...activity,
          Comment: [
            ...activity.Comment,
            { ...reply.data, createdByUser: user },
          ],
        };
      }),
    );
  }

  async function resolveThread(threadId: string) {
    if (isNil(activities)) {
      return;
    }

    await api.post(`comments/${threadId}/resolve`, {});

    setActivities(
      activities.map((activity) => {
        if ('type' in activity || activity.id !== threadId) {
          return activity;
        }
        return {
          ...activity,
          resolvedAt: new Date().toISOString(),
        };
      }),
    );
  }

  const shownActivities = activities?.filter((a) =>
    showSystemActivity ? true : !isSystemActivity(a),
  );

  return (
    <div className="divide-y divide-gray-200 rounded-none sm:rounded-lg bg-white border border-slate-200 w-full">
      <div className="px-4 py-2 min-h-12 flex justify-between items-center font-medium">
        <SectionHeader title="Activity" />
        <Button
          color="noBg"
          size="smDynamic"
          label={
            showSystemActivity ? 'Hide system activity' : 'Show system activity'
          }
          onClick={() => setShowSystemActivity(!showSystemActivity)}
        />
      </div>

      <div className="flex flex-col">
        {' '}
        {/* Fixed height container */}
        <div className="py-2">
          {shownActivities === null && <InlineSpinner />}
          {!isNil(shownActivities) && shownActivities.length === 0 && (
            <div className="px-4 py-5 flex-grow space-y-5 justify-center flex">
              <div className="text-sm text-gray-500">
                {showSystemActivity
                  ? 'No activity to display'
                  : 'No comments to display'}
              </div>
            </div>
          )}
          {!isNil(shownActivities) &&
            shownActivities.map((activity, i) => {
              if (isSystemActivity(activity)) {
                return (
                  <SystemActivityComponent
                    key={`${activity.timestamp}-${i}`}
                    activity={activity}
                  />
                );
              }
              return (
                <CommentThreadComponent
                  key={activity.id}
                  thread={activity}
                  onReply={(comment) => addReply(activity.id, comment)}
                  onResolve={() => resolveThread(activity.id)}
                />
              );
            })}
        </div>
        <div className="border-t border-gray-200 bg-white rounded-b-lg">
          {' '}
          {/* Sticky footer */}
          <AddComment addComment={addComment} />
        </div>
      </div>
    </div>
  );
}

export function ParentTagBadge({ parentTag }: { parentTag?: string }) {
  if (!parentTag) return null;

  return (
    <span className="inline-flex items-center rounded bg-white px-2 py-0.5 text-xs font-medium text-gray-900 mr-1 md:mr-2 ring-1 ring-inset ring-gray-200">
      {parentTag}
    </span>
  );
}

function getIcon(icon: ActivityIconType) {
  switch (icon) {
    case 'send':
      return <PaperAirplaneIcon className="w-4 h-4 text-slate-500" />;
    case 'plus':
      return <PlusCircleIcon className="w-4 h-4 text-slate-500" />;
    case 'archive-box':
      return <ArchiveBoxIcon className="w-4 h-4 text-slate-500" />;
    case 'arrow-uturn-left':
      return <ArrowUturnLeftIcon className="w-4 h-4 text-slate-500" />;
    case 'backspace':
      return <BackspaceIcon className="w-4 h-4 text-slate-500" />;
    case 'calendar':
      return <CalendarIcon className="w-4 h-4 text-slate-500" />;
    case 'check-circle':
      return <CheckCircleIcon className="w-4 h-4 text-slate-500" />;
    case 'x-circle':
      return <XCircleIcon className="w-4 h-4 text-slate-500" />;
    case 'pencil-square':
      return <PencilSquareIcon className="w-4 h-4 text-slate-500" />;
    case 'arrow-down-tray':
      return <ArrowDownTrayIcon className="w-4 h-4 text-slate-500" />;
    default:
      unreachable(icon);
  }
}
function SystemActivityComponent({ activity }: { activity: SystemActivity }) {
  return (
    <div className="px-4 py-2 relative">
      <div className="flex items-start">
        {/* Icon container */}
        <div className="relative flex-shrink-0">{getIcon(activity.icon)}</div>

        {/* Content with left margin */}
        <div className="ml-2">
          {activity.parentTag && (
            <div className="mb-2">
              <ParentTagBadge parentTag={activity.parentTag} />
            </div>
          )}

          <div className="text-sm text-gray-900 mb-4">
            <span className="font-medium">
              {activity.user?.name ?? 'Unknown User'}
            </span>{' '}
            {activity.text}{' '}
            <span className="text-gray-500 italic text-xs">
              {dayjs(activity.timestamp).fromNow()}
            </span>
          </div>
        </div>
      </div>

      {/* Vertical line - adjusted to align with icon edge */}
      <div className="absolute left-[22px] top-7 bottom-0 w-[2px] bg-slate-200" />
    </div>
  );
}

function CommentThreadComponent({
  thread,
  onReply,
  onResolve,
}: {
  thread: CommentThread;
  onReply: (comment: string) => Promise<void>;
  onResolve: () => Promise<void>;
}) {
  const [isReplying, setIsReplying] = useState(false);
  const [isExpanded, setIsExpanded] = useState(!thread.resolvedAt);
  const [slackChannelName, setSlackChannelName] = useState<string | null>(null);
  const mainComment = thread.Comment[0];
  const replies = thread.Comment.slice(1);
  const threadIsSyncedFromSlack = !isNil(thread.parentNotificationThreadId);

  useEffect(() => {
    api.get(`comments/slackChannelName/${thread.id}`).then((res) => {
      const maybeSlackChannelName = res.data;
      if (!isNil(maybeSlackChannelName)) {
        setSlackChannelName(maybeSlackChannelName);
      }
    });
  }, [thread]);

  if (thread.resolvedAt && !isExpanded) {
    return (
      <div
        className="px-4 py-2 relative flex items-start cursor-pointer group hover:bg-gray-50"
        onClick={() => setIsExpanded(true)}
      >
        {/* Icon container */}
        <div className="relative flex-shrink-0">
          <ChatBubbleLeftIcon className="w-4 h-4 text-slate-500" />
        </div>

        <div className="ml-2 text-sm text-gray-500">
          {thread.parentTag && (
            <div className="mb-2">
              <ParentTagBadge parentTag={thread.parentTag} />
            </div>
          )}
          <div className="flex items-center gap-1.5">
            <span>
              Resolved comment from {mainComment.createdByUser.name} (
              <span className="text-gray-500 italic text-xs">
                {dayjs(thread.resolvedAt).fromNow()})
              </span>
            </span>
          </div>
        </div>

        {/* Vertical line for the entire thread */}
        <div className="absolute left-[22px] top-7 bottom-0 w-[2px] bg-slate-200" />
      </div>
    );
  }

  const slackLogo = (
    <img className="h-3 ml-3 mr-1 inline-block" src={logoSlack} alt="Slack" />
  );
  const slackSourceDesc =
    threadIsSyncedFromSlack &&
    (isNil(thread.slackLink) || isNil(slackChannelName) ? (
      <span className="flex items-center ml-1">in Slack {slackLogo}</span>
    ) : (
      <span className="flex items-center ml-1">
        in
        <a
          href={thread.slackLink}
          target="_blank"
          className="ml-1 text-fuchsia-900 hover:text-fuchsia-950 hover:underline flex items-center"
          rel="noreferrer"
        >
          #{slackChannelName}
          {slackLogo}
        </a>{' '}
      </span>
    ));

  return (
    <div className="px-4 py-2 flex-grow relative">
      {/* Main comment */}
      <div className="flex items-start">
        {/* Icon container */}
        <div className="relative flex-shrink-0">
          <ChatBubbleLeftIcon className="w-4 h-4 text-slate-500" />
        </div>

        {/* Main comment content */}
        <div className="ml-2 flex-grow">
          {thread.parentTag && (
            <div className="mb-2">
              <ParentTagBadge parentTag={thread.parentTag} />
            </div>
          )}
          <div className="bg-white rounded-lg border border-slate-200 p-2">
            <div className="flex justify-between items-start gap-2">
              <div className="text-sm text-gray-900 flex items-center">
                <span className="font-medium">
                  {mainComment.createdByUser.name}
                </span>
                {slackSourceDesc}
                <span className="text-gray-500 ml-1 italic text-xs">
                  {dayjs(mainComment.createdAt).fromNow()}
                </span>
                {}
              </div>
              {/* Buttons */}
              <div className="flex items-center gap-2">
                {thread.resolvedAt && (
                  <Button
                    color="ghost"
                    size="xs"
                    onClick={() => setIsExpanded(false)}
                  >
                    Collapse
                  </Button>
                )}
                {!thread.resolvedAt && !threadIsSyncedFromSlack && (
                  <Button color="ghost" size="xs" onClick={onResolve}>
                    Resolve
                  </Button>
                )}
              </div>
            </div>
            <CrepeDisplayReadonly
              value={mainComment.comment}
              className="text-sm text-gray-900 mt-1.5"
            />
          </div>
          {threadIsSyncedFromSlack && (
            <div className="italic text-gray-500 text-xs mt-1">
              Message visibility is limited to channel participants
            </div>
          )}
        </div>
      </div>

      {/* Vertical line for the entire thread */}
      <div className="absolute left-[22px] top-7 bottom-0 w-[2px] bg-slate-200" />

      {/* Nested replies */}
      {replies.length > 0 && (
        <div className="ml-6 mt-2 space-y-2">
          {replies.map((reply) => (
            <div key={reply.id} className="relative flex flex-col items-start">
              {/* Vertical line connector */}
              <div className="h-4 w-0.5 bg-slate-200 ml-3 mb-2" />

              <div className="flex-grow bg-white rounded-lg border border-slate-200 p-2">
                <div className="text-sm text-gray-900">
                  <span className="font-medium">
                    {reply.createdByUser.name}
                  </span>{' '}
                  <span className="text-gray-500 italic text-xs">
                    {dayjs(reply.createdAt).fromNow()}
                  </span>
                </div>
                <CrepeDisplayReadonly
                  value={reply.comment}
                  className="text-sm text-gray-900 mt-1.5"
                />
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Reply UI */}
      {!thread.resolvedAt && (
        <div className="ml-9 mt-2 border-l-2 border-slate-200">
          {!isReplying && (
            <Button color="ghost" size="xs" onClick={() => setIsReplying(true)}>
              Reply
            </Button>
          )}
          {isReplying && (
            <AddComment
              addComment={async (comment) => {
                await onReply(comment);
                setIsReplying(false);
              }}
              onCancel={() => setIsReplying(false)}
              threadId={thread.id}
              threadIsSyncedFromSlack={threadIsSyncedFromSlack}
            />
          )}
        </div>
      )}
    </div>
  );
}
