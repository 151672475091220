import React, { useEffect, useState } from 'react';

type ClickAndDragContextProps = {
  handleMouseDown: (idx: number) => void;
  handleMouseOver: (idx: number) => void;
  handleMouseUp: (idx: number) => void;
  isCellSelected: (idx: number) => boolean;
};

const ClickAndDragContext = React.createContext<
  ClickAndDragContextProps | undefined
>(undefined);

type ClickAndDragProviderProps = {
  children: React.ReactNode;
  onMouseUp: (selectedCells: SelectedCells) => void;
  // if true, you can only extend the selection downwards from the initial cell
  unidirectional: boolean;
};

interface SelectedCells {
  start: number;
  end: number;
}
export const ClickAndDragProvider: React.FC<ClickAndDragProviderProps> = ({
  children,
  onMouseUp,
  unidirectional,
}) => {
  const [selectedCells, setSelectedCells] = useState<SelectedCells>({
    start: -1,
    end: -1,
  });
  const [isDragging, setIsDragging] = useState<boolean>(false);
  useEffect(() => {
    if (isDragging) {
      document.body.style.userSelect = 'none';
    } else {
      document.body.style.userSelect = '';
    }
    return () => {
      document.body.style.userSelect = '';
    };
  }, [isDragging]);

  function handleMouseDown(idx: number) {
    console.log('handlemousedown ', { start: idx, end: idx });
    setIsDragging(true);
    setSelectedCells({ start: idx, end: idx });
  }
  function handleMouseOver(idx: number) {
    if (selectedCells.start === -1) return;
    if (unidirectional) {
      if (idx >= selectedCells.start) {
        setSelectedCells({ start: selectedCells.start, end: idx });
      }
    } else {
      if (idx < selectedCells.start) {
        console.log('handlemouseover ', { start: idx, end: selectedCells.end });
        setSelectedCells({ start: idx, end: selectedCells.end });
      } else if (idx > selectedCells.end) {
        console.log('handlemouseover ', {
          start: selectedCells.start,
          end: idx,
        });
        setSelectedCells({ start: selectedCells.start, end: idx });
      }
    }
  }
  function handleMouseUp(idx: number) {
    setIsDragging(false);
    if (selectedCells.start === -1) return;
    if (selectedCells.start === idx) {
      console.log('handlemouseup without doing anything', {
        start: -1,
        end: -1,
      });
      setSelectedCells({ start: -1, end: -1 });
    } else {
      console.log('handlemouseup ', { start: selectedCells.start, end: idx });
      onMouseUp({ start: selectedCells.start, end: idx });
      setSelectedCells({ start: -1, end: -1 });
    }
  }
  function isCellSelected(idx: number) {
    return selectedCells.start <= idx && idx <= selectedCells.end;
  }

  return (
    <ClickAndDragContext.Provider
      value={{
        handleMouseDown,
        handleMouseOver,
        handleMouseUp,
        isCellSelected,
      }}
    >
      {children}
    </ClickAndDragContext.Provider>
  );
};

export const useClickAndDrag = (): ClickAndDragContextProps => {
  const context = React.useContext(ClickAndDragContext);
  if (!context) {
    throw new Error(
      'useClickAndDrag must be used within a ClickAndDragContext.Provider',
    );
  }
  return context;
};
