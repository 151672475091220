import { SlashIcon } from '@heroicons/react/24/outline';
import { Fragment, ReactNode } from 'react';

type BreadcrumbStep = {
  label: React.ReactNode | string;
  onClick?: () => void;
  hoverComponent?: ReactNode;
};

type HeaderBreadcrumbsProps = {
  steps: BreadcrumbStep[];
};

export default function HeaderBreadcrumbs(props: HeaderBreadcrumbsProps) {
  const { steps } = props;

  return (
    <div className="sticky top-0 z-40 bg-white py-2 sm:py-4 px-2 sm:px-4 border-b border-slate-200">
      <div>
        {/* Breadcrumb Navigation */}
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex items-center flex-wrap">
            {steps.map((step, index) => (
              <Fragment key={index}>
                {index !== 0 && (
                  <li key={step.label + 'slash'} className="text-slate-300">
                    <SlashIcon className="w-4 sm:w-6 w-4 sm:h-6" />
                  </li>
                )}
                <li key={index}>
                  <div className="flex items-center">
                    <div className="relative group">
                      {step.onClick && index !== steps.length - 1 ? (
                        <button
                          type="button"
                          onClick={step.onClick}
                          className="text-xs sm:text-sm font-medium text-neutral-700 
                            hover:text-neutral-700 hover:bg-pink-50 
                            rounded px-2 py-1
                            transition-colors duration-150 text-left"
                        >
                          {step.label}
                        </button>
                      ) : (
                        <div
                          aria-current={
                            index === steps.length - 1 ? 'page' : undefined
                          }
                          className={`
                            text-xs sm:text-sm font-medium px-2 py-1 rounded
                            ${
                              index === steps.length - 1
                                ? 'text-fuchsia-800 font-semibold bg-pink-50'
                                : 'text-pink-500'
                            }
                          `}
                        >
                          {step.label}
                        </div>
                      )}
                      {step.hoverComponent && (
                        <div className="absolute hidden group-hover:block mt-1 shadow-lg rounded-md">
                          {step.hoverComponent}
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              </Fragment>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  );
}
