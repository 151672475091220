import { isNil } from 'lodash';
import { useRef, useState } from 'react';
import { FormattedNumberField } from 'src/components/Fields';
import useContextMenu from 'src/components/useContextMenu';
import { classNames } from 'src/dashboard/App';
import { getHandleKeyDownForEnterNextRowHandling } from 'src/utils';
import { getBlendedPriceForVolume } from '../../Hamster/hamster_utils';
import EditableIndicator from '../../Penguin/Components/EditableIndicator';
import { usePricingFlowContext } from '../../PricingFlow';
import {
  Count,
  CurrencyValue,
  CurrencyValueFlat,
  CurrencyValueTiered,
  CurrencyValueType,
  Minimum,
  QuotePrice,
  Tier,
} from '../../types_common/price';
import {
  AlpacaSupportedCurrency,
  ALPACA_CURRENCY_SYMBOLS,
} from '../alpaca_types';
import { formatCurrencyValue } from '../alpaca_utils';
import {
  AlpacaQuotePriceEditableTieredDetails,
  TierConfig,
} from './AlpacaQuotePriceEditableTieredDetails';
import AlpacaWarningPopup from './AlpacaWarningPopup';

export type AlpacaQuotePriceLimit = {
  flat: number | null;
  percent: number | null;
};

export const QuotePriceEditable = (props: {
  quotePrice: QuotePrice;
  updateQuotePrice: (newQuotePrice: QuotePrice | null) => void;
  validPriceTypes: CurrencyValueType[];
  // Generally the component will display currency based on the
  // quotePrice.currency. However if the quotePrice doesn't currently have a
  // currency then if you want to switch to a type that requires a currency, we
  // will use this quoteCurrency
  quoteCurrency: AlpacaSupportedCurrency;
  stickerPrice: CurrencyValue | CurrencyValueTiered | null;
  cost: CurrencyValue | null;
  productName: string;
  // limit the values that the user can enter
  limit?: AlpacaQuotePriceLimit | null;
  tierable?: boolean;
  validTierMinimumTypes: Minimum['type'][]; // Valid minimum types for tiered pricing
  tierConfig?: Partial<TierConfig>;
  numDecimals?: number;
  showChangePriceTypeMenu?: boolean;
  productVolume?: number;
}) => {
  const {
    quotePrice,
    updateQuotePrice,
    validPriceTypes,
    quoteCurrency,
    stickerPrice,
    cost,
    limit,
    productName,
    validTierMinimumTypes,
    tierConfig,
    productVolume,
  } = props;
  const { editMode } = usePricingFlowContext();
  const numDecimals = props.numDecimals ?? 2;

  const inputRef = useRef<HTMLInputElement>(null);

  const tierable = props.tierable ?? true;

  const showChangePriceTypeMenu =
    props.showChangePriceTypeMenu ?? (tierable || validPriceTypes.length > 1);

  const [isPercentExceeding100, setIsPercentExceeding100] =
    useState<boolean>(false);

  const { clicked, setClicked } = useContextMenu();

  const handleChangeQuotePriceType = (quotePriceType: QuotePrice['type']) => {
    const defaultToListPriceOnSwitchToTiers =
      (tierConfig?.defaultToListPriceOnSwitch ?? false) &&
      stickerPrice?.type === 'tiered';
    const zeroMinimum: Minimum = validTierMinimumTypes.includes(
      CurrencyValueType.FLAT,
    )
      ? {
          type: CurrencyValueType.FLAT,
          value: 0,
          currency: quoteCurrency,
        }
      : { type: 'count', value: 0 };
    // Handle menu item click here
    switch (quotePrice.type) {
      case CurrencyValueType.FLAT:
        switch (quotePriceType) {
          case CurrencyValueType.PERCENT:
            updateQuotePrice({
              ...quotePrice,
              type: CurrencyValueType.PERCENT,
            });
            break;
          case CurrencyValueType.FLAT_AND_PERCENT:
            updateQuotePrice({
              ...quotePrice,
              type: quotePriceType,
              flat: quotePrice.value,
              percent: 0,
            });
            break;
          case 'tiered':
            if (defaultToListPriceOnSwitchToTiers) {
              updateQuotePrice(stickerPrice);
            } else {
              updateQuotePrice({
                type: quotePriceType,
                minimumType: zeroMinimum.type,
                tiers: [
                  {
                    currencyValue: {
                      type: CurrencyValueType.FLAT,
                      value: quotePrice.value,
                      currency: quotePrice.currency,
                    },
                    minimum: zeroMinimum,
                  },
                ],
              });
            }
            break;
        }
        break;
      case CurrencyValueType.PERCENT:
        switch (quotePriceType) {
          case CurrencyValueType.FLAT:
            updateQuotePrice({
              ...quotePrice,
              type: quotePriceType,
              currency: quoteCurrency,
            });
            break;
          case CurrencyValueType.FLAT_AND_PERCENT:
            updateQuotePrice({
              ...quotePrice,
              type: quotePriceType,
              flat: 0,
              percent: quotePrice.value,
              currency: quoteCurrency,
            });
            break;
          case 'tiered':
            if (defaultToListPriceOnSwitchToTiers) {
              updateQuotePrice(stickerPrice);
            } else {
              updateQuotePrice({
                type: quotePriceType,
                minimumType: zeroMinimum.type,
                tiers: [
                  {
                    currencyValue: {
                      type: CurrencyValueType.PERCENT,
                      value: quotePrice.value,
                    },
                    minimum: zeroMinimum,
                  },
                ],
              });
            }
            break;
        }
        break;
      case CurrencyValueType.FLAT_AND_PERCENT:
        switch (quotePriceType) {
          case CurrencyValueType.FLAT:
            updateQuotePrice({
              ...quotePrice,
              type: quotePriceType,
              value: quotePrice.flat,
            });
            break;
          case CurrencyValueType.PERCENT:
            updateQuotePrice({
              ...quotePrice,
              type: quotePriceType,
              value: quotePrice.percent,
            });
            break;
          case 'tiered':
            if (defaultToListPriceOnSwitchToTiers) {
              updateQuotePrice(stickerPrice);
            } else {
              updateQuotePrice({
                type: quotePriceType,
                minimumType: zeroMinimum.type,
                tiers: [
                  {
                    currencyValue: {
                      type: CurrencyValueType.FLAT_AND_PERCENT,
                      flat: quotePrice.flat,
                      percent: quotePrice.percent,
                      currency: quotePrice.currency,
                    },
                    minimum: zeroMinimum,
                  },
                ],
              });
            }
            break;
        }
        break;
      case 'tiered':
        // If the quote price is tiered, you can only remove tiers first, before switching quotePrice.type
        updateQuotePrice(quotePrice.tiers[0].currencyValue);
        break;
      default:
        throw new Error(`Invalid quote price type: ${quotePrice}`);
    }
  };

  /**
   * Left click menu if tiered, for editing tiers
   */
  const [showTieredDetails, setShowTieredDetails] = useState(false);

  switch (quotePrice.type) {
    case CurrencyValueType.FLAT:
      return (
        <>
          <div
            onContextMenu={(e) => {
              e.preventDefault();
              setClicked(true);
            }}
            className="h-full"
          >
            <EditableIndicator
              onClickSettings={
                showChangePriceTypeMenu ? () => setClicked(true) : undefined
              }
              onClickSettingsReadOnly={null}
              readonly={!editMode}
            >
              <FormattedNumberField
                type="text"
                value={quotePrice.value}
                numberDecimals={numDecimals}
                className={classNames(
                  'w-0 grow border-none bg-transparent px-0 text-xs sm:text-sm text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent pl-2 sm:pl-4 py-2 sm:py-4',
                  editMode && 'cursor-pointer',
                )}
                validateIncomingValue={(n: number) => {
                  if (limit?.flat) {
                    return limit.flat >= n;
                  }
                  return true;
                }}
                updateValue={(value: number) => {
                  updateQuotePrice({
                    ...quotePrice,
                    value,
                  });
                }}
                prefix={ALPACA_CURRENCY_SYMBOLS[quotePrice.currency]}
                ref={inputRef}
                data-quote-price-editable
                onKeyDown={getHandleKeyDownForEnterNextRowHandling(
                  inputRef,
                  'data-quote-price-editable',
                )}
                disabled={!editMode}
              />
            </EditableIndicator>
          </div>
          {showChangePriceTypeMenu && (
            <ChangeQuotePriceTypeMenu
              handleChangeQuotePriceType={handleChangeQuotePriceType}
              shown={clicked}
              validPriceTypes={validPriceTypes}
              quotePrice={quotePrice}
              tierable={tierable}
              setShowTieredDetails={setShowTieredDetails}
            />
          )}
        </>
      );
    case CurrencyValueType.PERCENT:
      return (
        <>
          <div
            onContextMenu={(e) => {
              e.preventDefault();
              setClicked(true);
            }}
            className="h-full relative"
          >
            <AlpacaWarningPopup
              message={'Percentage value exceeds 100%'}
              show={isPercentExceeding100}
              className="absolute p-2 z-50 -top-8 min-w-52 bg-gray-700 text-white text-xs rounded"
              onHide={() => {
                setIsPercentExceeding100(false);
              }}
            />
            <EditableIndicator
              onClickSettings={
                showChangePriceTypeMenu ? () => setClicked(true) : undefined
              }
              onClickSettingsReadOnly={null}
              readonly={!editMode}
            >
              <FormattedNumberField
                type="text"
                value={quotePrice.value}
                numberDecimals={numDecimals}
                className={classNames(
                  'w-0 grow border-none bg-transparent px-0 text-xs sm:text-sm text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent pl-2 sm:pl-4 py-2 sm:py-4',
                  editMode && 'cursor-pointer',
                )}
                validateIncomingValue={(n: number) => {
                  if (limit?.percent) {
                    return limit.percent >= n;
                  }
                  return true;
                }}
                updateValue={(value: number) => {
                  if (quotePrice.value !== value) {
                    // only check if exceeding on update
                    setIsPercentExceeding100(value > 100);
                  }
                  updateQuotePrice({
                    ...quotePrice,
                    value: value,
                  });
                }}
                suffix="%"
                ref={inputRef}
                data-quote-price-editable
                onKeyDown={getHandleKeyDownForEnterNextRowHandling(
                  inputRef,
                  'data-quote-price-editable',
                )}
                disabled={!editMode}
              />
            </EditableIndicator>
          </div>
          {showChangePriceTypeMenu && (
            <ChangeQuotePriceTypeMenu
              handleChangeQuotePriceType={handleChangeQuotePriceType}
              shown={clicked}
              validPriceTypes={validPriceTypes}
              quotePrice={quotePrice}
              tierable={tierable}
              setShowTieredDetails={setShowTieredDetails}
            />
          )}
        </>
      );
    case CurrencyValueType.FLAT_AND_PERCENT:
      return (
        <>
          <div
            onContextMenu={(e) => {
              e.preventDefault();
              setClicked(true);
            }}
            className="relative h-full"
          >
            <AlpacaWarningPopup
              message={'Percentage value exceeds 100%'}
              show={isPercentExceeding100}
              className="absolute p-2 -top-8 min-w-52 z-50 bg-gray-700 text-white text-xs rounded"
              onHide={() => {
                setIsPercentExceeding100(false);
              }}
            />
            <EditableIndicator
              onClickSettings={
                showChangePriceTypeMenu ? () => setClicked(true) : undefined
              }
              onClickSettingsReadOnly={null}
              readonly={!editMode}
            >
              <FormattedNumberField
                type="text"
                value={quotePrice.flat}
                numberDecimals={numDecimals}
                // @TODO(fay) this grow-1.5 is a hack, it looks nice when the fixed part of the price is xx.xx or less
                className={classNames(
                  'w-0 grow-[1.5] border-none bg-transparent px-0 text-sm text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent pl-4 py-4',
                  editMode && 'cursor-pointer',
                )}
                validateIncomingValue={(n: number) => {
                  if (limit?.flat) {
                    return limit.flat >= n;
                  }
                  return true;
                }}
                updateValue={(value: number) => {
                  updateQuotePrice({
                    ...quotePrice,
                    flat: value,
                  });
                }}
                prefix={ALPACA_CURRENCY_SYMBOLS[quotePrice.currency]}
                ref={inputRef}
                data-quote-price-editable
                onKeyDown={getHandleKeyDownForEnterNextRowHandling(
                  inputRef,
                  'data-quote-price-editable',
                )}
                disabled={!editMode}
              />
              <FormattedNumberField
                type="text"
                value={quotePrice.percent}
                numberDecimals={numDecimals}
                className="w-0 grow-[2] cursor-pointer border-none bg-transparent px-0 text-sm text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent py-4"
                validateIncomingValue={(n: number) => {
                  if (limit?.percent) {
                    return limit.percent >= n;
                  }
                  return true;
                }}
                updateValue={(value: number) => {
                  if (quotePrice.percent !== value) {
                    // only check if exceeding on update
                    setIsPercentExceeding100(value > 100);
                  }
                  updateQuotePrice({
                    ...quotePrice,
                    percent: value,
                  });
                }}
                prefix="+ "
                suffix="%"
                ref={inputRef}
                data-quote-price-editable
                onKeyDown={getHandleKeyDownForEnterNextRowHandling(
                  inputRef,
                  'data-quote-price-editable',
                )}
                disabled={!editMode}
              />
            </EditableIndicator>
          </div>
          {showChangePriceTypeMenu && (
            <ChangeQuotePriceTypeMenu
              handleChangeQuotePriceType={handleChangeQuotePriceType}
              shown={clicked}
              validPriceTypes={validPriceTypes}
              quotePrice={quotePrice}
              tierable={tierable}
              setShowTieredDetails={setShowTieredDetails}
            />
          )}
        </>
      );
    case 'tiered':
      const tieredDisplay =
        tierConfig?.showBlendedPrice === true &&
        !isNil(productVolume) &&
        quotePrice.tiers[0]?.minimum.type === 'count' &&
        quotePrice.tiers[0]?.currencyValue.type === CurrencyValueType.FLAT ? (
          <div className="flex flex-col justify-start items-start mt-[-12px] cursor-pointer">
            <div
              className="w-full text-gray-400"
              style={{ fontSize: '10px', lineHeight: '12px' }}
            >
              Tiered
            </div>
            <div className="text-gray-900 text-xs sm:text-sm">
              {formatCurrencyValue(
                getBlendedPriceForVolume(
                  productVolume,
                  quotePrice.tiers as Tier<CurrencyValueFlat, Count>[],
                ),
              )}{' '}
            </div>
          </div>
        ) : (
          'Tiered'
        );
      return (
        <>
          <div
            onContextMenu={(e) => {
              e.preventDefault();
              setClicked(true);
            }}
            onClick={() => setShowTieredDetails(true)}
            className="h-full relative"
          >
            <EditableIndicator
              className=" pl-2 sm:pl-4 py-2 sm:py-4 h-full text-sm"
              onClickSettingsReadOnly={() => setShowTieredDetails(true)}
              onClickSettings={() => setShowTieredDetails(true)}
              readonly={!editMode}
            >
              {tieredDisplay}
            </EditableIndicator>
          </div>
          <ChangeQuotePriceTypeMenu
            handleChangeQuotePriceType={handleChangeQuotePriceType}
            shown={clicked}
            validPriceTypes={validPriceTypes}
            quotePrice={quotePrice}
            tierable={tierable}
            setShowTieredDetails={setShowTieredDetails}
          />
          <AlpacaQuotePriceEditableTieredDetails
            show={showTieredDetails}
            quotePrice={quotePrice}
            updateQuotePrice={updateQuotePrice}
            onClose={() => setShowTieredDetails(false)}
            stickerPrice={stickerPrice}
            cost={cost}
            limit={limit}
            productName={productName}
            quoteCurrency={quoteCurrency}
            validPriceTypes={validPriceTypes}
            validTierMinimumTypes={validTierMinimumTypes}
            tierConfig={tierConfig}
            handleChangeQuotePriceType={handleChangeQuotePriceType}
          />
        </>
      );
  }
};

function ChangeQuotePriceTypeMenu(props: {
  handleChangeQuotePriceType: (quotePriceType: QuotePrice['type']) => void;
  shown: boolean;
  validPriceTypes?: CurrencyValueType[];
  quotePrice: QuotePrice;
  tierable?: boolean;
  setShowTieredDetails: (show: boolean) => void;
}) {
  const {
    handleChangeQuotePriceType,
    shown,
    validPriceTypes,
    quotePrice,
    tierable,
    setShowTieredDetails,
  } = props;
  const { editMode } = usePricingFlowContext();
  if (!editMode) {
    return null;
  }

  const CurrencyValueTypeNameMapping = {
    [CurrencyValueType.FLAT]: 'Flat',
    [CurrencyValueType.PERCENT]: 'Percent',
    [CurrencyValueType.FLAT_AND_PERCENT]: 'Flat and Percent',
  };
  const TIERED_LIST = [
    {
      name: 'Remove tiers',
      onClick: () => handleChangeQuotePriceType('tiered'),
    },
  ];
  const validPriceTypeOptions = (validPriceTypes ?? [])
    .filter((type) => type !== quotePrice.type)
    .map((quotePriceType) => ({
      name: CurrencyValueTypeNameMapping[quotePriceType],
      onClick: () => handleChangeQuotePriceType(quotePriceType),
    }));
  let VALID_PRICE_TYPE_LIST: { name: string; onClick: () => void }[] = [
    ...validPriceTypeOptions,
  ];
  if (tierable) {
    VALID_PRICE_TYPE_LIST.push({
      name: 'Add tiers',
      onClick: () => {
        handleChangeQuotePriceType('tiered');
        setShowTieredDetails(true);
      },
    });
  }
  const menuItems =
    quotePrice.type === 'tiered' ? TIERED_LIST : VALID_PRICE_TYPE_LIST;

  return (
    <div className="relative">
      {shown && (
        <div
          className="absolute z-[400] flex flex-col gap-1 bg-white border border-gray-200 shadow-lg rounded-md transition ease-out duration-100"
          style={{ top: 2, left: 0 }}
        >
          {menuItems.length === 0 && (
            <div className="text-gray-500 font-semibold block pl-4 pr-6 py-2 text-sm text-left">
              Tiers not supported
              {/* Actually there are no options to switch to, but I think it's kinda confusing to say no options (options for what? they might ask) so this is easier */}
            </div>
          )}
          {validPriceTypeOptions.length > 0 && quotePrice.type !== 'tiered' && (
            <div className="text-gray-500 font-semibold block pl-4 pr-6 py-2 text-sm text-left">
              Change price type
            </div>
          )}
          {menuItems.map((item) => (
            <button
              key={item.name}
              className="hover:bg-gray-100 hover:text-gray-900 text-gray block pl-4 pr-6 py-2 text-sm text-left"
              onClick={item.onClick}
              disabled={!editMode}
            >
              {item.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
