import { datadogRum } from '@datadog/browser-rum';
import { createContext, useContext, useEffect, useState } from 'react';
import api from 'src/api';
import { User } from '../types';

const UserContext = createContext<User | undefined>(undefined);

export function UserContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [user, setUser] = useState<User | undefined>(undefined);

  const fetchUser = async () => {
    try {
      const response = await api.get('user');
      if (response.data) {
        setUser(response.data);
      }
    } catch (error) {
      datadogRum.addError(error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export function useUserContext() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }
  return context;
}
