import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/outline';
import { isNil } from 'lodash';
import { useState } from 'react';
import Button from 'src/components/Button';
import { ProductSelectField } from 'src/components/Fields';
import { useModal } from 'src/components/Modal';
import { doesSearchQueryMatch } from 'src/utils';
import { usePricingFlowContext } from '../PricingFlow';
import {
  HamsterPricingFlow,
  HamsterProduct,
  HamsterProductPrice,
  HamsterSubcategoryName,
  HAMSTER_SUBCATEGORY_NAME,
} from './hamster_types';

// ##HamsterCreateProduct
// Keep these in sync! This creates a product when selected from the product
// select modal on the client
function createHamsterProductFromPricingSheet(
  productInfo: HamsterProductPrice,
) {
  const pricingInfo = productInfo.currentPricingCurve.pricingInformation;
  return {
    id: productInfo.id,
    volume: productInfo.fixedVolume ?? pricingInfo.defaultVolume ?? 0,
    name: productInfo.name,
    rampedVolumeIncremental: [],
    quotePrice: pricingInfo.listPrice,
  };
}

const SearchInput = ({ onChange }: { onChange: (val: string) => void }) => {
  return (
    <div className="flex h-10 w-full flex-row items-center rounded-lg border border-gray-300 p-2 shadow-sm focus-within:border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900">
      <MagnifyingGlassIcon
        className="mr-2 h-4 w-4 text-gray-500"
        aria-hidden="true"
      />
      <input
        className="text-md -ml-3 border-none bg-transparent text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent"
        placeholder={'Search for a product'}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </div>
  );
};

export function HamsterProductSelectionButton(props: { editMode: boolean }) {
  const { editMode } = props;
  const { showModal, hideModal } = useModal();
  const { pricingFlow, updateFlow } =
    usePricingFlowContext<HamsterPricingFlow>();
  return (
    <Button
      color="white"
      onClick={() => {
        showModal({
          title: 'Add Products',
          children: (
            <HamsterProductSelectionModal
              closeModal={hideModal}
              pricingFlow={pricingFlow}
              updateFlow={updateFlow}
            />
          ),
          newStyle: true,
        });
      }}
      disabled={!editMode}
    >
      <PlusIcon className="-ml-0.5 mr-1 h-4 w-4" aria-hidden="true" />
      Add / Edit Products
    </Button>
  );
}
export default function HamsterProductSelectionModal(props: {
  closeModal: () => void;
  pricingFlow: HamsterPricingFlow;
  updateFlow: (flow: HamsterPricingFlow, save: boolean) => void;
}) {
  const { pricingFlow, updateFlow } = props;
  const { products } = pricingFlow;
  const { closeModal } = props;
  const [searchQuery, setSearchQuery] = useState('');
  const [tempSelectedProducts, setTempSelectedProducts] =
    useState<HamsterProduct[]>(products);

  return (
    <div className="flex flex-col h-full">
      {/* Search header - fixed at top */}
      <div className="flex flex-col items-center justify-end px-6 sm:flex-row py-2 border-b border-slate-100">
        <SearchInput onChange={setSearchQuery} />
      </div>

      {/* Scrollable content area */}
      <div className="flex-1 overflow-y-auto">
        <div className="sm:px-6 pb-4">
          <ProductGrid
            searchQuery={searchQuery}
            allSkus={Object.values(pricingFlow.pricingSheetData.productInfo)
              .filter((p) => !p?.name.includes('Credit'))
              .filter((pp) => !isNil(pp))}
            products={tempSelectedProducts}
            setProducts={setTempSelectedProducts}
            pricingFlow={pricingFlow}
          />
        </div>
      </div>

      {/* Footer - fixed at bottom */}
      <div className="shrink-0 border-t border-100">
        <div className="flex flex-col sm:flex-row justify-between p-4 gap-2 sm:gap-4 sm:flex-row-reverse">
          <Button
            color="primary"
            onClick={() => {
              updateFlow(
                { ...pricingFlow, products: tempSelectedProducts },
                false,
              );
              closeModal();
            }}
            className="flex-1"
            label={`Add ${
              tempSelectedProducts && tempSelectedProducts.length > 0
                ? `(${tempSelectedProducts.length})`
                : ''
            }`}
          />
          <Button
            color="white"
            onClick={closeModal}
            className="flex-1"
            label="Cancel"
          />
        </div>
      </div>
    </div>
  );
}

function ProductGrid({
  searchQuery,
  allSkus,
  products,
  setProducts,
  pricingFlow,
}: {
  allSkus: HamsterProductPrice[];
  searchQuery: string;
  products: any[];
  setProducts: (products: HamsterProduct[]) => void;
  pricingFlow: HamsterPricingFlow;
}) {
  const filteredProductInfos = allSkus.filter(
    (
      productPrice: HamsterProductPrice | undefined,
    ): productPrice is HamsterProductPrice => {
      return productPrice
        ? doesSearchQueryMatch(searchQuery, productPrice.name)
        : false;
    },
  );

  if (filteredProductInfos.length === 0) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <p className="text-lg font-medium text-gray-900">No products found</p>
        <p className="mt-2 text-sm text-gray-600">
          Try searching with different keywords
        </p>
      </div>
    );
  }

  function stringToHtmlId(str: string) {
    return str
      .toLowerCase() // Convert to lowercase
      .trim() // Remove leading/trailing spaces
      .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, ''); // Remove leading/trailing hyphens
  }

  const subSectionHeader = (title: HamsterSubcategoryName) => {
    const selectorId = stringToHtmlId(title);
    return (
      <header
        className="w-full bg-slate-100 mt-4 rounded flex flex-row"
        key={`${title}_section_header`}
      >
        {(() => {
          return (
            <label
              key={`${selectorId}_label`}
              htmlFor={selectorId}
              className="flex items-center"
            >
              <span className="text-gray-500 pl-3 py-1.5 text-xs font-medium tracking-wide">
                {title.toUpperCase()}
              </span>
            </label>
          );
        })()}
      </header>
    );
  };

  return HAMSTER_SUBCATEGORY_NAME.map((subcategoryName) => {
    const skusForSubcategory = filteredProductInfos.filter(
      (pi): pi is HamsterProductPrice => {
        return (
          pi?.subcategoryMemberships.some(
            (membership) => membership.subcategory.name === subcategoryName,
          ) ?? false
        );
      },
    );
    if (skusForSubcategory.length === 0) {
      return null;
    }
    return (
      <>
        {subSectionHeader(subcategoryName)}
        <div
          className="mt-4 grid auto-rows-fr grid-cols-2 gap-2 sm:grid-cols-4 px-4 sm:px-0"
          key={`${subcategoryName}_products`}
        >
          {skusForSubcategory
            .sort((a, b) => {
              return a.displayOrder - b.displayOrder;
            })
            .map((productInfo: HamsterProductPrice) => {
              const selected =
                products.find((product) => product.id === productInfo.id) !==
                undefined;
              return (
                <ProductSelectField
                  className="h-full"
                  key={`${productInfo.id}`}
                  name={productInfo.name}
                  id={`${productInfo.id}`}
                  onChange={(id: string) => {
                    // if the product was previously selected and we're changing
                    // its state, remove it from the set of selected products
                    if (selected) {
                      setProducts(
                        products.filter((product) => product.id !== id),
                      );
                    } else {
                      setProducts([
                        // remove any products in the same exclusive group
                        ...products.filter((p) => {
                          return isNil(productInfo.exclusiveGroup)
                            ? true
                            : pricingFlow.pricingSheetData.productInfo[p.id]
                                ?.exclusiveGroup !== productInfo.exclusiveGroup;
                        }),
                        // add the product
                        createHamsterProductFromPricingSheet(productInfo),
                      ]);
                    }
                  }}
                  checked={selected}
                />
              );
            })}
        </div>
      </>
    );
  });
}
