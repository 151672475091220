import { User } from '../../../../types';

import { datadogRum } from '@datadog/browser-rum';
import {
  ArrowUturnLeftIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { isNil, isString, round } from 'lodash';
import { RadioBoxFields } from 'src/components/Fields';
import BottomBar from '../../Penguin/Components/BottomBar';
import { usePricingFlowContext } from '../../PricingFlow';
import {
  CurrencyValuePercent,
  CurrencyValueType,
  ZERO_PERCENT,
} from '../../types_common/price';
import {
  AlpacaPricingFlow,
  AlpacaProductPrice,
  AlpacaTFxBucket,
  AlpacaTFxBucketProduct,
  AlpacaTFxPairProduct,
  AlpacaTFxPricingInformation,
  AlpacaTFxProductPrice,
  AlpacaTFxSingleBuyCurrencyProduct,
  AlpacaTreasuryFxProduct,
  isAlpacaTFXProduct,
  isAlpacaTFXProductPrice,
} from '../alpaca_types';
import { getAverageCurrencyValue, roundQuotePrice } from '../alpaca_utils';
import { default as AlpacaAddBucketButton } from '../Components/AlpacaAddBucketButton';
import AlpacaAddCurrencyPairButton from '../Components/AlpacaAddCurrencyPairButton';
import AlpacaFxTable from '../Components/AlpacaFxTable';
import AlpacaHeader from '../Components/AlpacaHeader';
import AlpacaHelpButton from '../Components/AlpacaHelpButton';
import AlpacaOpportunityInfoButton from '../Components/AlpacaOpportunityInfoButton';

type SetSubstep = (substep: 1 | 2) => void;
export type TreasuryFxTemplate = 'fixed' | 'tiered' | 'none';

export default function TreasuryFX(props: {
  user: User;
  nextStep: () => void;
  previousStep: () => void;
}) {
  const { nextStep, previousStep } = props;
  const { updateFlow, pricingFlow, editMode, setStage } =
    usePricingFlowContext<AlpacaPricingFlow>();

  const rawProducts = pricingFlow.products ?? [];
  const products = rawProducts.filter(isAlpacaTFXProduct);

  const treasurySubstep = pricingFlow.additionalData.treasuryStep ?? 1;
  const setSubstep = (substep: 1 | 2) => {
    if (!editMode) {
      setStage({
        stage: null,
        customStage: null,
        otherAdditionalData: { treasuryStep: substep },
      });
      return;
    }
    // If we're going "back to templates" from step 2, set to the "none"
    // template so that we don't override their work
    const newTfxTemplate =
      treasurySubstep === 2 && substep === 1
        ? 'none'
        : pricingFlow.additionalData.treasuryFXPricingTemplate;
    updateFlow(
      {
        ...pricingFlow,
        additionalData: {
          ...pricingFlow.additionalData,
          treasuryStep: substep,
          treasuryFXPricingTemplate: newTfxTemplate,
        },
      },
      true,
    );
  };

  return (
    <>
      {treasurySubstep === 1 ? (
        <Subtep1SelectTemplate
          products={products}
          setSubstep={setSubstep}
          previousStep={previousStep}
        />
      ) : null}

      {treasurySubstep === 2 ? (
        <Substep2Price
          nextStep={nextStep}
          setSubstep={setSubstep}
          previousStep={previousStep}
        />
      ) : null}
    </>
  );
}
const getAvgStickerPrice = (
  pricingFlow: AlpacaPricingFlow,
  currencies: AlpacaProductPrice[],
) => {
  const quoteCurrency = pricingFlow.additionalData.quoteCurrency;
  return (
    (getAverageCurrencyValue(
      currencies
        .map((c) => {
          const pricingInfo = pricingFlow.currentPricingCurves[c.id];
          return pricingInfo.pricingInformation.listPrice;
        })
        .filter((c): c is CurrencyValuePercent => !isNil(c)),
      quoteCurrency,
      pricingFlow,
    ) as CurrencyValuePercent) ?? ZERO_PERCENT
  );
};

// #TreasuryFxTemplatePriceSync
function getProductsForTemplate(
  template: TreasuryFxTemplate,
  pricingFlow: AlpacaPricingFlow,
): AlpacaTFxBucketProduct[] {
  const quoteCurrency = pricingFlow.additionalData.quoteCurrency;
  const allProductPrices = Object.values(
    pricingFlow.pricingSheetData.countryPricingSheets.us.productInfo,
  );
  const majorCurrencyCurrencies = allProductPrices.filter(
    (pp) =>
      pp.skuGroup === 'Treasury FX' && pp.skuSubgroup === 'Major Currencies',
  );
  const exotic1Currencies = allProductPrices.filter(
    (pp) => pp.skuGroup === 'Treasury FX' && pp.skuSubgroup === 'Exotic Tier 1',
  );

  switch (template) {
    case 'fixed': {
      const newProducts: AlpacaTFxBucketProduct[] = [
        {
          id: 'Major Currencies',
          name: 'Major Currencies',
          volume: 0,
          categoryName: 'Treasury FX',
          quotePrice: { type: CurrencyValueType.PERCENT, value: 0.5 },
          subCategory: 'bucket',
          bucket: 'Major Currencies',
          currencyIds: majorCurrencyCurrencies.map((c) => c.id),
        },
        {
          id: 'Exotics Tier 1',
          name: 'Exotics Tier 1',
          volume: 0,
          categoryName: 'Treasury FX',
          quotePrice: roundQuotePrice(
            getAvgStickerPrice(pricingFlow, exotic1Currencies),
          ),
          subCategory: 'bucket',
          bucket: 'Exotic Tier 1',
          currencyIds: exotic1Currencies.map((c) => c.id),
        },
      ];
      return newProducts;
    }
    case 'tiered': {
      const newProducts: AlpacaTFxBucketProduct[] = [
        {
          id: 'Major Currencies',
          name: 'Major Currencies',
          volume: 0,
          categoryName: 'Treasury FX',
          quotePrice: {
            type: 'tiered',
            minimumType: CurrencyValueType.FLAT,
            tiers: [
              {
                minimum: {
                  type: CurrencyValueType.FLAT,
                  value: 0,
                  currency: quoteCurrency,
                },
                currencyValue: { type: CurrencyValueType.PERCENT, value: 0.5 },
              },
            ],
          },
          subCategory: 'bucket',
          bucket: 'Major Currencies',
          currencyIds: majorCurrencyCurrencies.map((c) => c.id),
        },
        {
          id: 'Exotics Tier 1',
          name: 'Exotics Tier 1',
          volume: 0,
          categoryName: 'Treasury FX',
          quotePrice: {
            type: 'tiered',
            minimumType: CurrencyValueType.FLAT,
            tiers: [
              {
                minimum: {
                  type: CurrencyValueType.FLAT,
                  value: 0,
                  currency: quoteCurrency,
                },
                currencyValue: roundQuotePrice(
                  getAvgStickerPrice(pricingFlow, exotic1Currencies),
                ),
              },
            ],
          },
          subCategory: 'bucket',
          bucket: 'Exotic Tier 1',
          currencyIds: exotic1Currencies.map((c) => c.id),
        },
      ];
      return newProducts;
    }
    case 'none':
      datadogRum.addError(
        `You should not call this function! Check that the logic at #DoNotUpdateProductsForNoneTemplate is working correctly`,
      );
      return [];
    default:
      const typecheck: never = template;
      datadogRum.addError(`invalid template ${typecheck}`);
      return typecheck;
  }
}

// ##TreasuryFxTemplatePriceSync
// We compute these prices twice - once to show on the template, and once in
// the logic we actually use to populate the pricing flow data. Keep these in
// sync!
const TemplateSelector = () => {
  const { updateFlow, pricingFlow, editMode } =
    usePricingFlowContext<AlpacaPricingFlow>();

  const allProductPrices = Object.values(
    pricingFlow.pricingSheetData.countryPricingSheets.us.productInfo,
  );
  const majorCurrencyCurrencies = allProductPrices.filter(
    (pp) =>
      pp.skuGroup === 'Treasury FX' && pp.skuSubgroup === 'Major Currencies',
  );
  const exotic1Currencies = allProductPrices.filter(
    (pp) => pp.skuGroup === 'Treasury FX' && pp.skuSubgroup === 'Exotic Tier 1',
  );
  const renderTitle = (title: string) => {
    return (
      <span className="text-md block font-medium text-slate-900">{title}</span>
    );
  };
  return (
    <div className="p-6 mt-2 text-md text-slate-900 w-full max-w-screen-lg">
      Start with a pricing template or build a quote from scratch.
      <div className="flex flex-row gap-4 mt-4">
        {/** column 1 */}
        <div className="flex flex-col gap-4 grow">
          <RadioBoxFields<TreasuryFxTemplate>
            name={'treasury_fx_template'}
            checked={
              pricingFlow.additionalData.treasuryFXPricingTemplate ?? 'fixed'
            }
            onSelectOption={(template: TreasuryFxTemplate) => {
              updateFlow(
                {
                  ...pricingFlow,
                  additionalData: {
                    ...pricingFlow.additionalData,
                    treasuryFXPricingTemplate: template,
                  },
                },
                false,
              );
            }}
            radioBoxConfigs={[
              {
                value: 'fixed',
                title: renderTitle('Fixed Majors, Fixed Exotics'),
                description: (
                  <div className="w-full flex-col">
                    <div className="flex justify-between">
                      <span className="text-slate-500 text-sm">G10</span>
                      <span className="text-slate-900 font-medium text-sm">
                        {`0.5%`}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-slate-500 text-sm">Exotics</span>
                      <span className="text-slate-900 font-medium text-sm">
                        {`${round(
                          getAvgStickerPrice(pricingFlow, exotic1Currencies)
                            .value,
                          1,
                        )}%`}
                      </span>
                    </div>
                  </div>
                ),
                disabled: !editMode,
              },
              {
                value: 'tiered',
                title: renderTitle('Tiered Majors, Tiered Exotics'),

                description: (
                  <div className="w-full flex-col">
                    <div className="flex justify-between">
                      <span className="text-slate-500 text-sm">G10</span>
                      <span className="text-slate-900 font-medium text-sm">
                        {`0.5%`}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-slate-500 text-sm">Exotics</span>
                      <span className="text-slate-900 font-medium text-sm">
                        {`${round(
                          getAvgStickerPrice(pricingFlow, exotic1Currencies)
                            .value,
                          1,
                        )}%`}
                      </span>
                    </div>
                  </div>
                ),
                disabled: !editMode,
              },
              {
                value: 'none',
                title: renderTitle(`I don't want to use a template`),
                description: null,
                disabled: !editMode,
              },
            ]}
          />
        </div>
        {/** column 2 */}
        <div className="text-md text-slate-900 rounded-xl bg-slate-50 p-4 flex flex-row gap-4 align-start max-w-[480px] items-start h-min">
          <InformationCircleIcon className="h-8 w-14 text-slate-500" />
          <div className="flex flex-col gap-2">
            <span className="text-slate-900 font-medium">Customization</span>
            <span className="text-slate-600">
              All these templates are <b>fully customizable</b>. They provide a
              quick starting point, and you can easily adjust every price to fit
              your needs.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

function Subtep1SelectTemplate(props: {
  setSubstep: SetSubstep;
  products: AlpacaTreasuryFxProduct[];
  previousStep: () => void;
}) {
  const { setSubstep, previousStep } = props;
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<AlpacaPricingFlow>();

  return (
    <div id="step_1" className="relative flex flex-col">
      <AlpacaHeader
        title="TreasuryFX"
        description="Select a pricing template or build a quote from scratch"
        rightChildren={[
          <AlpacaOpportunityInfoButton />,
          <AlpacaHelpButton url="https://airwallex.atlassian.net/wiki/spaces/SBAH/pages/2583331862/Sales+Play+FX+Conversions+Forwards" />,
        ]}
      />

      <div className="w-full">
        <TemplateSelector />

        <div className="h-24" />
        <BottomBar
          primaryButtonProps={{
            label: editMode ? 'Choose template' : 'Next',
            onClick: async () => {
              if (!editMode) {
                setSubstep(2);
                return;
              }
              const selectedTemplate =
                pricingFlow.additionalData.treasuryFXPricingTemplate ?? 'fixed';
              // ##DoNotUpdateProductsForNoneTemplate
              const oldProducts = pricingFlow.products ?? [];
              const newProducts =
                selectedTemplate === 'none'
                  ? oldProducts
                  : [
                      ...oldProducts.filter((p) => !isAlpacaTFXProduct(p)),
                      ...getProductsForTemplate(selectedTemplate, pricingFlow),
                    ];
              updateFlow(
                {
                  ...pricingFlow,
                  products: newProducts,
                  additionalData: {
                    ...pricingFlow.additionalData,
                    treasuryFXPricingTemplate: selectedTemplate,
                    treasuryStep: 2,
                  },
                },
                false,
              );
            },
          }}
          secondaryButtonProps={{
            label: 'Back',
            onClick: async () => previousStep(),
          }}
        />
      </div>
    </div>
  );
}

function Substep2Price(props: {
  nextStep: () => void;
  setSubstep: SetSubstep;
  previousStep: () => void;
}) {
  const { nextStep, setSubstep, previousStep } = props;
  const { pricingFlow, updateFlow } =
    usePricingFlowContext<AlpacaPricingFlow>();
  const tfxProductPrices = Object.values(
    pricingFlow.pricingSheetData.countryPricingSheets.us.productInfo,
  ).filter(isAlpacaTFXProductPrice);
  const products = pricingFlow.products ?? [];

  return (
    <>
      <div id="step_2" className="">
        {/* Header */}
        <AlpacaHeader
          title="Treasury FX"
          rightChildren={[
            <AlpacaOpportunityInfoButton />,
            <AlpacaHelpButton url="https://airwallex.atlassian.net/wiki/spaces/SBAH/pages/2583331862/Sales+Play+FX+Conversions+Forwards" />,
            <button
              type="button"
              className="inline-flex items-center rounded-lg border border-fuchsia-100 px-3.5 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-fuchsia-50 text-fuchsia-900 hover:bg-fuchsia-100"
              onClick={() => setSubstep(1)}
            >
              <ArrowUturnLeftIcon
                className="-ml-0.5 mr-2 h-5 w-5"
                aria-hidden="true"
              />
              Back to templates
            </button>,
          ]}
        />

        <div className="px-8 py-4 flex flex-col gap-2">
          <AlpacaFxTable
            title={'Buckets'}
            button={AlpacaAddBucketButton({
              currencies: tfxProductPrices,
              products: products.filter(
                (
                  p,
                ): p is
                  | AlpacaTFxBucketProduct
                  | AlpacaTFxSingleBuyCurrencyProduct => {
                  return (
                    isAlpacaTFXProduct(p) &&
                    (p.subCategory === 'bucket' ||
                      p.subCategory === 'single_buy')
                  );
                },
              ),
              onSelectBucket: (
                bucketOrCurrency: AlpacaTFxBucket | AlpacaTFxProductPrice,
              ) => {
                if (isString(bucketOrCurrency)) {
                  const bucket = bucketOrCurrency as AlpacaTFxBucket;
                  // #TfxNoSingleBuyCurrenciesInBuckets
                  const existingSingleBuyCurrencies = products
                    .filter(
                      (product) =>
                        isAlpacaTFXProduct(product) &&
                        product.subCategory === 'single_buy',
                    )
                    .map((currency) => currency.id);
                  const componentCurrencies = tfxProductPrices.filter(
                    (productPrice) =>
                      productPrice.skuSubgroup === bucket &&
                      !existingSingleBuyCurrencies.includes(productPrice.id),
                  );
                  const newProduct: AlpacaTFxBucketProduct = {
                    id: bucket,
                    name: bucket,
                    quotePrice: getAverageCurrencyValue(
                      componentCurrencies.map((currency) => {
                        const pricingInfo = pricingFlow.currentPricingCurves[
                          currency.id
                        ].pricingInformation as AlpacaTFxPricingInformation;
                        return pricingInfo.listPrice;
                      }),
                      pricingFlow.additionalData.quoteCurrency,
                      pricingFlow,
                    ),
                    volume: 0,
                    bucket,
                    currencyIds: componentCurrencies.map((c) => c.id),
                    subCategory: 'bucket',
                    categoryName: 'Treasury FX',
                  };
                  updateFlow(
                    {
                      ...pricingFlow,
                      products: [...products, newProduct],
                    },
                    false,
                  );
                } else {
                  const currency = bucketOrCurrency as AlpacaTFxProductPrice;
                  const pricingInfo =
                    pricingFlow.currentPricingCurves[currency.id]
                      .pricingInformation;
                  const newProduct: AlpacaTFxSingleBuyCurrencyProduct = {
                    id: currency.id,
                    name: currency.name,
                    quotePrice: pricingInfo.listPrice,
                    volume: 0,
                    currencyId: currency.id,
                    subCategory: 'single_buy',
                    categoryName: 'Treasury FX',
                  };
                  // ##TfxNoSingleBuyCurrenciesInBuckets
                  // We should always allow reps to add a single buy currency.
                  // However, when they do, we want to remove that currency from
                  // any buckets it's in, and prevent it from being added back
                  // into the bucket
                  const oldProducts = products.map((p) => {
                    if (
                      isAlpacaTFXProduct(p) &&
                      p.subCategory === 'bucket' &&
                      p.currencyIds.includes(currency.id)
                    ) {
                      return {
                        ...p,
                        currencyIds: p.currencyIds.filter(
                          (currencyId) => currencyId !== currency.id,
                        ),
                      };
                    }
                    return p;
                  });
                  updateFlow(
                    {
                      ...pricingFlow,
                      products: [...oldProducts, newProduct],
                    },
                    false,
                  );
                }
              },
            })}
            subCategories={['bucket', 'single_buy']}
          />

          <AlpacaFxTable
            title={'Currency pairs'}
            button={AlpacaAddCurrencyPairButton({
              currencies: tfxProductPrices,
              products: products.filter((p): p is AlpacaTFxPairProduct => {
                return isAlpacaTFXProduct(p) && p.subCategory === 'pair';
              }),
              onSelectCurrencyPair: (sellCurrency, buyCurrency) => {
                const name = `${sellCurrency.name} -> ${buyCurrency.name}`;
                const pricingInfo =
                  pricingFlow.currentPricingCurves[buyCurrency.id]
                    .pricingInformation;
                const newProduct: AlpacaTFxPairProduct = {
                  id: name,
                  name,
                  quotePrice: pricingInfo.listPrice,
                  volume: 0,
                  sellCurrencyId: sellCurrency.id,
                  buyCurrencyId: buyCurrency.id,
                  subCategory: 'pair',
                  categoryName: 'Treasury FX',
                };
                updateFlow(
                  {
                    ...pricingFlow,
                    products: [...products, newProduct],
                  },
                  false,
                );
              },
            })}
            subCategories={['pair']}
          />
        </div>

        <div className="h-28" />
        <BottomBar
          primaryButtonProps={{
            label: 'Next',
            onClick: async () => nextStep(),
          }}
          secondaryButtonProps={{
            label: 'Back',
            onClick: async () => previousStep(),
          }}
        />
      </div>
    </>
  );
}
