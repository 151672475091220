import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from 'src/api';
import Button from 'src/components/Button';
import { InlineSpinner } from 'src/components/Loading';
import { useToast } from 'src/components/Toast';
import { User } from 'src/types';
import { navigateToPricingFlow } from './PricingFlow';
import { PricingFlowOrSnapshotForNavigation } from './QuoteOptionsSection';
import { isPricingFlowValidForSubmission } from './SubmitForApprovalButton';

interface HamsterUpdateSfdcButtonProps {
  user: User;
  pricingFlow: PricingFlowOrSnapshotForNavigation;
}

export function PushToSalesforceButton(props: HamsterUpdateSfdcButtonProps) {
  const { pricingFlow, user } = props;
  const [sfdcUpdateState, setSfdcUpdateState] = useState<
    'not_started' | 'loading' | 'done'
  >('not_started');
  const { showToast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  if (!user.permissions.includes('edit_pricing_flow')) {
    showToast({
      title: 'Error',
      subtitle: 'You do not have permission to push to Salesforce.',
      type: 'error',
    });
    return null;
  }
  return (
    <Button
      color="white"
      onClick={async (e) => {
        e.stopPropagation();
        const pricingFlowWithOpportunity = (
          await api.get(
            `pricingFlow?pricingFlowId=${pricingFlow.originalPricingFlowId}`,
          )
        ).data.pricingFlowData;
        const { isValid, error } = isPricingFlowValidForSubmission(
          pricingFlowWithOpportunity,
        );
        if (!isValid) {
          showToast({
            title: 'Error',
            subtitle: error,
            type: 'error',
          });
          navigateToPricingFlow({
            navigate,
            pricingFlowId: pricingFlowWithOpportunity.id,
            sfdcOpportunityId:
              pricingFlowWithOpportunity.opportunity.sfdcOpportunityId,
            skipReloadIfSameUrl: true,
            location,
          });
          return;
        }
        setSfdcUpdateState('loading');
        await api.post(
          `quotes?pricingFlowId=${pricingFlowWithOpportunity.id}`,
          {},
        );
        setSfdcUpdateState('done');
        const sfdcUrl = `${pricingFlowWithOpportunity.opportunity.sfdcInstanceUrl}/lightning/r/Opportunity/${pricingFlowWithOpportunity.opportunity.sfdcOpportunityId}/view`;
        window.open(sfdcUrl, '_blank');
      }}
      disabled={sfdcUpdateState !== 'not_started'}
    >
      {sfdcUpdateState === 'loading' ? (
        <InlineSpinner />
      ) : sfdcUpdateState === 'done' ? (
        '✅ SFDC updated!'
      ) : (
        'Push to Salesforce'
      )}
    </Button>
  );
}
