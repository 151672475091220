import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import { useToast } from 'src/components/Toast';
import backgroundLines from 'src/images/background-lines.svg';
import fadedCircleBg from 'src/images/faded-circle-bg.svg';
import logoDealopsTarget from 'src/images/logos/dealops-target.svg';
import { User } from '../../types';
import { classNames } from '../App';
import { DealOverviewForm } from './DealOverviewForm';
import OpportunityOverviewBar from './OpportunityOverviewBar';
import { ProductsForm } from './ProductsForm';

import {
  DealopsProductPrices,
  PricingFlowCommon,
  PricingFlowStage,
  PricingFlowType,
  ProductCommon,
} from './types';

export default function Step1(props: {
  pricingFlow: PricingFlowCommon;
  updateFlow: (flow: PricingFlowCommon, showLoading?: boolean) => void;
  user: User;
  setStage: (stage: PricingFlowCommon['stage']) => void;
}) {
  const { pricingFlow, updateFlow, user } = props;
  const { showToast } = useToast();

  const [products, setProducts] = useState<ProductCommon[]>(
    (pricingFlow?.products as ProductCommon[]) ?? [],
  );

  const submit = () => {
    const unifiedProducts = products;
    if (unifiedProducts.some((p) => p.volume == null)) {
      showToast({
        title: 'Please fill out volume for each product',
        subtitle: 'Please enter a volume greater than 0',
        type: 'error',
      });
      return;
    }

    updateFlow({
      ...pricingFlow,
      products: products,
      manualQuote: {},
      stage: PricingFlowStage.CALCULATE_PRICE,
    });
  };

  let productSection = null;
  if (pricingFlow.type === PricingFlowType.PENGUIN) {
    const pricingSheetData =
      pricingFlow.pricingSheetData as DealopsProductPrices;

    // this is a penguin specific product form
    productSection = (
      <ProductsForm
        products={products}
        productPrices={pricingSheetData}
        setProducts={setProducts}
      />
    );
  }
  const unifiedProducts = products;

  return (
    <div
      id="step_0"
      className="relative flex flex-col items-center justify-center bg-repeat-x"
      style={{ backgroundImage: `url(${backgroundLines})` }}
    >
      {/* Dealops target logo */}
      <div className="mt-12 h-24 w-24">
        <img
          className="absolute h-24 w-24"
          src={fadedCircleBg}
          alt="faded circle"
        />
        <div className="absolute ml-5 mt-5 flex h-14 w-14 items-center justify-center rounded-full border border-gray-200 bg-white shadow">
          <img className="h-7 w-7" src={logoDealopsTarget} alt="Dealops" />
        </div>
      </div>

      <h1 className="mx-auto max-w-7xl px-4 pt-6 text-center text-2xl font-semibold sm:px-6 lg:px-8">
        Hi {user.name?.split(' ')[0]}, let’s work on pricing for{' '}
        {pricingFlow.opportunity.sfdcOpportunityName ?? 'this opportunity'}.
      </h1>
      <p className="text-l mx-auto max-w-7xl px-4 pt-2 text-center text-gray-700 sm:px-6 lg:px-8">
        Review the info we’ve pulled from Salesforce for this opportunity and
        fill out any additional info.
      </p>

      <a
        href={
          pricingFlow.opportunity.sfdcInstanceUrl +
          '/lightning/r/Opportunity/' +
          pricingFlow.opportunity.sfdcOpportunityId +
          '/view'
        }
        className="mt-6 flex flex-row items-center gap-1 text-sm font-semibold text-fuchsia-900"
      >
        <ArrowTopRightOnSquareIcon
          className="inline-block h-5 w-5"
          aria-hidden="true"
        />
        Open in Salesforce
      </a>

      <div className="mx-auto mt-8 block">
        <OpportunityOverviewBar
          opportunityData={pricingFlow.opportunity.opportunityData}
          type={pricingFlow.type}
        />
      </div>

      <div className="w-full max-w-7xl md:w-9/12">
        {/** Add products */}
        {productSection}

        {/** Advanced settings */}
        <DealOverviewForm
          pricingFlow={pricingFlow}
          updateFlow={updateFlow}
          type={pricingFlow.type}
        />

        <div className="mb-28 mt-8 flex w-full flex-row items-center justify-between">
          <button
            onClick={submit}
            className={classNames(
              !unifiedProducts ||
                (unifiedProducts && unifiedProducts.length === 0)
                ? 'bg-fuchsia-600'
                : 'bg-fuchsia-900 hover:bg-fuchsia-800',
              'col-span-full mb-32 mt-8 inline-flex w-full items-center justify-center rounded-lg py-2 font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900',
            )}
            disabled={!unifiedProducts || unifiedProducts.length === 0}
          >
            Generate pricing
          </button>
        </div>
      </div>
    </div>
  );
}
