import { datadogRum } from '@datadog/browser-rum';
import { isNil } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import Button from 'src/components/Button';
import { Organization, User } from 'src/types';
import { getNameForPricingFlow } from 'src/utils';
import { classNames } from '../App';
import { Opportunity } from '../Opportunity/types';
import { PricingFlowPage } from '../PricingFlow/PricingFlow';
import { PricingFlow } from '../PricingFlow/types';

export default function MultipleQuoteSlideout(props: {
  opportunity: Opportunity;
  pricingFlows: PricingFlow[];
  currentPricingFlow: string | null;
  user: User;
  organization: Organization;
}) {
  const { opportunity, pricingFlows, user, organization } = props;
  const [currentPricingFlowId, setCurrentPricingFlowId] = useState<
    string | null
  >(props.currentPricingFlow);
  if (isNil(currentPricingFlowId)) {
    return null;
  }
  const currentPricingFlow = pricingFlows.find(
    (pf) => pf.id === currentPricingFlowId,
  );
  if (!currentPricingFlow) {
    datadogRum.addError(
      'Unreachable, currentPricingFlowId not found in pricingFlows',
    );
    return;
  }

  return (
    <div className="w-full">
      <div className="flex flex-col xl:flex-row w-full">
        <Tabs
          currentPricingFlow={currentPricingFlowId}
          pricingFlows={pricingFlows}
          opportunity={opportunity}
          setCurrentPricingFlowId={setCurrentPricingFlowId}
          className=""
        />
        <div className="flex flex-col lg:flex-row flex-1 overflow-hidden">
          <div className="w-full flex-shrink-0 border-r border-gray-200">
            <div className="w-full lg:w-[111.11%] transform lg:scale-90 lg:origin-top-left">
              <PricingFlowPage
                pricingFlowOrSnapshotId={currentPricingFlowId}
                modelType={'pricingFlow'}
                user={user}
                organization={organization}
                viewMode="group"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Tabs(props: {
  currentPricingFlow: string;
  pricingFlows: PricingFlow[];
  opportunity: Opportunity;
  setCurrentPricingFlowId: (pricingFlowId: string) => void;
  className?: string;
}) {
  const {
    currentPricingFlow,
    pricingFlows,
    opportunity,
    setCurrentPricingFlowId,
    className,
  } = props;
  const scrollContainerRef = useRef(null);
  useEffect(() => {
    requestAnimationFrame(() => {
      const container = scrollContainerRef.current;
      // @ts-ignore
      const activeButton = container?.querySelector(
        `button[data-pricing-flow="${currentPricingFlow}"]`,
      );

      if (activeButton) {
        activeButton.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    });
  }, [currentPricingFlow]);
  return (
    <div
      ref={scrollContainerRef}
      className="sticky top-0 bg-white z-40 xl:border-r border-gray-200 mr-12 xl:mr-0 overflow-hidden"
    >
      <div
        className={classNames(
          'z-40 bg-white flex flex-row xl:flex-col',
          'space-x-2 xl:space-x-0 xl:space-y-2 p-2',
          'xl:w-[140px]',
          'overflow-x-auto',
          className,
        )}
      >
        {pricingFlows.map((pricingFlow) => (
          <Button
            key={pricingFlow.id}
            data-pricing-flow={pricingFlow.id}
            color={currentPricingFlow === pricingFlow.id ? 'primary' : 'white'}
            label={getNameForPricingFlow(pricingFlow, opportunity.pricingFlows)}
            onClick={() => setCurrentPricingFlowId(pricingFlow.id)}
            textAlign="left"
          />
        ))}
      </div>
      {/* Fade-out overlay */}
      <div className="xl:hidden absolute z-40 -right-2 top-0 h-full w-6 bg-gradient-to-r from-transparent to-white pointer-events-none" />
    </div>
  );
}
