import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import api from 'src/api';
import { FullscreenSpinner } from 'src/components/Loading';
import { navigateToPricingFlow } from './PricingFlow';

/**
 * Our old links are of the form /app/pricingflow?opportunity=:sfdcOppId
 * Our new links are of the form /app/opportunity/:sfdcOppId/pricingflow/:pricingFlowId
 * If someone navigates to /app/pricingflow, send them to the new route
 */
export default function PricingFlowNavigator() {
  const [searchParams] = useSearchParams();
  const sfdcOpportunityId = searchParams.get('opportunity');
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.toLowerCase();
  useEffect(() => {
    let isMounted = true;
    if (sfdcOpportunityId && pathname.startsWith('/app/pricingflow')) {
      (async () => {
        try {
          const { doesOpportunityExistInDealops, opportunityData } = (
            await api.get('opportunity', { sfdcOpportunityId })
          ).data;
          if (!isMounted) return;

          if (!doesOpportunityExistInDealops) {
            // create an opportunity, create a pricing flow, then navigate to it
            const newOpportunity = (
              await api.post('opportunity', {
                sfdcOpportunityId,
              })
            ).data.opportunityData;
            const newPricingFlow = (
              await api.post('pricingflow', {
                sfdcOpportunityId,
              })
            ).data;
            navigateToPricingFlow({
              navigate,
              sfdcOpportunityId: newOpportunity.sfdcOpportunityId,
              pricingFlowId: newPricingFlow.id,
            });
          } else if (opportunityData.pricingFlows.length === 1) {
            navigateToPricingFlow({
              navigate,
              sfdcOpportunityId: opportunityData.sfdcOpportunityId,
              pricingFlowId: opportunityData.pricingFlows[0].id,
            });
          } else {
            navigate(`/app/opportunity/${sfdcOpportunityId}`);
          }
        } catch (error) {
          datadogRum.addError(error, { sfdcOpportunityId });
          navigate(`/app/opportunity`);
        }
      })();
    }
    return () => {
      isMounted = false;
    };
  }, [sfdcOpportunityId]);
  if (!sfdcOpportunityId && pathname.startsWith('/app/pricingflow')) {
    return <Navigate to={'/app/opportunity'} />;
  }
  return <FullscreenSpinner />;
}
