import React from 'react';
import { classNames } from 'src/dashboard/App';

export type Location = 'LEFT' | 'RIGHT' | 'TOP' | 'BOTTOM';

export default function Tooltip(props: {
  as: string;
  className?: string;
  text: string | React.ReactNode;
  location: Location;
  children: React.ReactNode;
  disableTooltip?: boolean;
  [key: string]: any;
}) {
  const {
    location,
    children,
    className,
    text,
    as,
    disableTooltip,
    ...extraProps
  } = props;
  return React.createElement(
    as,
    {
      ...extraProps,
      className: classNames(className ?? '', 'has-tooltip relative '),
    },
    <>
      {children}
      {!disableTooltip && (
        <div
          className={classNames(
            location === 'LEFT' && 'justify-start',
            location === 'RIGHT' && 'justify-end',
            location === 'TOP' && 'justify-center',
            location === 'BOTTOM' && 'justify-center',
            ' pointer-events-none absolute top-0 z-[100] flex h-full w-full items-center',
          )}
        >
          <span
            className={classNames(
              location === 'LEFT' && '-ml-2 -translate-x-full',
              location === 'RIGHT' && '-mr-2 translate-x-full',
              location === 'TOP' && '-mt-8 -translate-y-full',
              location === 'BOTTOM' && '-mb-8 translate-y-full',
              'tooltip pointer-events-none absolute z-50 whitespace-pre rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white shadow-sm dark:bg-gray-700',
            )}
          >
            {typeof text === 'string' ? addNewLines(text) : text}
          </span>
        </div>
      )}
    </>,
  );
}
/**
 * Ideally we are not doing this hacky insert newlines thing and instead we are actually looking at the
 * component's width and height and x/y position and then deciding where to place the tooltip. But for now, we'll do this
 */
function addNewLines(text: string): string {
  // Split the text by ', '
  const elements = text.split(', ');

  // Use reduce to build the new string with newlines
  return elements.reduce((result, element, index) => {
    // Add the current element
    result += element;

    // If it's not the last element, add a separator
    if (index < elements.length - 1) {
      // Add a newline every 8 elements, otherwise add ', '
      result += (index + 1) % 8 === 0 ? ',\n' : ', ';
    }

    return result;
  }, '');
}

interface TooltipProps {
  as?: React.ElementType;
  location?: 'TOP' | 'BOTTOM' | 'LEFT' | 'RIGHT';
  text: React.ReactNode;
  children: React.ReactNode;
}
