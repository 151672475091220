import logo20Sales from '../assets/investors/20sales.svg';
import logoGeneralCatalyst from '../assets/investors/GeneralCatalyst.svg';
import logoNewNormal from '../assets/investors/NewNormal.svg';
import rectangle from '../assets/rectangle.svg';

const VerticalLines = () => (
  <div className="flex flex-row justify-between mx-auto max-w-[1175px] px-[6px] sm:px-[86px] lg:px-1.5 invisible lg:visible">
    <div className="relative flex justify-center z-10">
      <hr className="absolute left-1/2 transform -translate-x-1/2 -translate-y-40 border-l border-pink-200 h-[200px]" />
    </div>
    <div className="relative flex justify-center z-10">
      <hr className="absolute left-1/2 transform -translate-x-1/2 -translate-y-40 border-l border-pink-200 h-[200px]" />
    </div>
  </div>
);

const HorizontalLine = () => (
  <>
    <div className="w-full invisible lg:visible">
      <hr className="border-t border-pink-200 w-full max-w-[1175px] mx-auto translate-y-11" />
    </div>
    <div className="flex flex-row justify-between max-w-[1175px] translate-y-[38px] mx-auto">
      <img src={rectangle} className="invisible lg:visible" alt="" />
      <img src={rectangle} className="invisible lg:visible" alt="" />
    </div>
  </>
);

export const Investors = () => (
  <div className="bg-white">
    <div className="mx-auto px-3 sm:px-6 lg:px-8">
      <div className={`text-center rounded-3xl sm:px-12 py-12`}>
        <VerticalLines />
        <HorizontalLine />
        <section className="relative max-w-4xl bg-white lg:w-2/3 mx-auto z-20">
          <h3 className="font-display text-lg font-semibold tracking-tight text-[#5B0F4D]">
            Backed by world-class investors, sales executives, and operators
          </h3>
          <ul className="bg-white mt-6 flex items-center justify-center gap-x-8 sm:flex-col sm:gap-x-0 sm:gap-y-10 xl:flex-row xl:gap-x-12 xl:gap-y-0">
            {[
              [
                {
                  name: 'General Catalyst',
                  logo: logoGeneralCatalyst,
                  width: 260,
                },
                { name: 'New Normal', logo: logoNewNormal, width: 100 },
                { name: '20Sales', logo: logo20Sales, width: 120 },
              ],
            ].map((group, groupIndex) => (
              <li key={groupIndex}>
                <ul className="flex flex-col items-center gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0">
                  {group.map((company) => (
                    <li key={company.name} className="flex">
                      <img
                        src={company.logo}
                        alt={company.name}
                        width={company.width}
                      />
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </section>
      </div>
    </div>
  </div>
);
