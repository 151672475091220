import { WinkingComputer } from '../../Icons';

interface QuoteTableBodyEmptyStateProps {
  colSpan: number;
}
export function QuoteTableBodyEmptyState(props: QuoteTableBodyEmptyStateProps) {
  return (
    <tr className="border-b border-gray-200">
      <td
        colSpan={props.colSpan}
        className="h-20 whitespace-nowrap bg-white px-6 py-4 text-center text-sm font-medium text-gray-600"
      >
        <div className="py-2 flex flex-col items-center justify-center">
          <WinkingComputer />

          <div className="mt-2">Get started by adding products</div>
        </div>
      </td>
    </tr>
  );
}
