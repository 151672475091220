import { isNil } from 'lodash';
import api from 'src/api';
import Button from 'src/components/Button';
import {
  ApprovalStatusForPricingFlow,
  StepSnapshotWithStatus,
} from './PricingFlowList';
import { PricingFlowOrSnapshotForNavigation } from './QuoteOptionsSection';

function formatList(names: string[]): string {
  if (names.length === 1) return names[0];
  if (names.length === 2) return `${names[0]} or ${names[1]}`;
  return `${names.slice(0, -1).join(', ')}, or ${names[names.length - 1]}`;
}

export default function SuperApproveModal(props: {
  pricingFlow: PricingFlowOrSnapshotForNavigation;
  hideModal: () => void;
  nextPendingStep: StepSnapshotWithStatus;
  approvalStatus: ApprovalStatusForPricingFlow;
}) {
  const { pricingFlow, hideModal, nextPendingStep, approvalStatus } = props;
  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="p-4 font-medium text-sm text-slate-600 space-y-2">
        <p>
          Are you sure you want to bypass the approval needed by{' '}
          <strong>{nextPendingStep.name}</strong>?
        </p>
        <p>
          This quote will no longer require approval from{' '}
          <strong>
            {formatList(
              nextPendingStep.usersWithStatus
                .map((u) => u.name)
                .filter((name) => !isNil(name)),
            )}
          </strong>
          .
        </p>
        <p>
          If you later change your mind, you can recall this bypass the same way
          you would recall any other approval.
        </p>
      </div>
      <div className="shrink-0 border-t border-100">
        <div className="flex flex-col justify-between p-4 gap-2 sm:gap-4 sm:flex-row-reverse">
          <Button
            color="green"
            onClick={async (e) => {
              e.stopPropagation();
              await api.post(
                `approvals/requests/${approvalStatus.currentApprovalRequest?.id}/actions`,
                {
                  action: 'APPROVE',
                  superApprovalStepSnapshotId: nextPendingStep.id,
                },
              );
              window.location.reload();
            }}
            label={'Bypass Approval'}
            className="flex-1"
          />
          <Button
            color="white"
            onClick={hideModal}
            label="Cancel"
            className="flex-1"
          />
        </div>
      </div>
    </div>
  );
}
