import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'src/components/Button';
import { useModal } from 'src/components/Modal';
import { User } from 'src/types';
import { getNameForPricingFlow } from 'src/utils';
import { classNames } from '../App';
import { ParentTagBadge } from '../PricingFlow/Activity/ActivitySection';
import { CreateAndNameQuoteButton } from '../PricingFlow/CreateQuoteButton';
import { PricingFlowOrSnapshotForNavigation } from '../PricingFlow/QuoteOptionsSection';
import { OpportunityCommon, PricingFlowCommon } from '../PricingFlow/types';

type Tab = 'from_this_opp' | 'any_other_opp';

interface CloneQuoteModalProps {
  opportunity: OpportunityCommon;
  otherPricingFlows: PricingFlowCommon[];
  user: User;
}
export default function CloneQuoteModal(props: CloneQuoteModalProps) {
  const { opportunity, otherPricingFlows, user } = props;
  const oppHasPricingFlows = opportunity.pricingFlows.length > 0;
  const [tab, setTab] = useState<Tab>(
    oppHasPricingFlows ? 'from_this_opp' : 'any_other_opp',
  );
  const { hideModal } = useModal();
  const flowsToShow: {
    opportunity: OpportunityCommon;
    flow: PricingFlowOrSnapshotForNavigation;
  }[] =
    tab === 'from_this_opp'
      ? opportunity.pricingFlows.map((flow) => {
          return { opportunity, flow };
        })
      : otherPricingFlows.map((flow) => {
          return { opportunity: flow.opportunity, flow };
        });
  return (
    <div className="h-[500px] flex-1 flex flex-col overflow-hidden">
      {/* tabs */}
      <div className="w-full flex">
        <button
          className={classNames(
            'font-semibold text-sm py-2 px-4',
            tab === 'from_this_opp'
              ? 'text-fuchsia-900  border-b-2 border-fuchsia-900'
              : 'text-slate-500',
          )}
          onClick={() => setTab('from_this_opp')}
          disabled={tab === 'from_this_opp'}
        >
          From current opportunity
        </button>
        <button
          className={classNames(
            'font-semibold text-sm py-2 px-4',
            tab === 'any_other_opp'
              ? 'text-fuchsia-900 border-b-2 border-fuchsia-900'
              : 'text-slate-500',
          )}
          onClick={() => setTab('any_other_opp')}
          disabled={tab === 'any_other_opp'}
        >
          Other opportunities
        </button>
      </div>

      {/* Scrollable Options Section */}
      <div className="px-4 pb-4 flex flex-col overflow-hidden flex-1">
        <div className="overflow-y-auto flex-1">
          <div className="space-y-2 pt-4">
            {flowsToShow.length === 0 && (
              <div className="text-center text-gray-500 mt-4">
                No quote options available
              </div>
            )}
            {flowsToShow.length > 0 &&
              flowsToShow.map(({ flow, opportunity }) => {
                const title = getNameForPricingFlow(
                  flow,
                  opportunity.pricingFlows,
                );
                return (
                  <div
                    key={flow.id}
                    className="flex items-center justify-between p-2 border border-gray-200 rounded-lg"
                  >
                    {/* left */}
                    {tab === 'from_this_opp' ? (
                      <div className="flex items-center h-full gap-x-2">
                        <span className="text-sm text-gray-900">
                          {getNameForPricingFlow(
                            flow,
                            opportunity.pricingFlows,
                          )}
                        </span>
                      </div>
                    ) : (
                      <div className="flex items-center h-full gap-x-2">
                        <ParentTagBadge
                          parentTag={
                            opportunity.sfdcOpportunityName ?? undefined
                          }
                        />

                        <span className="text-sm text-gray-900">{title}</span>
                      </div>
                    )}

                    {/* right */}
                    <div className="flex items-center h-full gap-x-3">
                      <Link
                        to={`/app/opportunity/${opportunity.sfdcOpportunityId}/pricingflow/${flow.id}`}
                        target="_blank"
                      >
                        <ArrowTopRightOnSquareIcon className="h-4 w-4 text-fuchsia-900" />
                      </Link>
                      <CreateAndNameQuoteButton
                        buttonProps={{ label: 'Clone', color: 'white' }}
                        opportunity={opportunity}
                        defaultName={`${getNameForPricingFlow(
                          flow,
                          opportunity.pricingFlows,
                        )} (clone)`}
                        flowToCloneId={flow.id}
                        user={user}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="mt-4 shrink-0 border-t border-100">
        <div className="flex flex-col sm:flex-row justify-between p-4 gap-2 sm:gap-4 sm:flex-row-reverse">
          <Button
            color="white"
            onClick={() => hideModal()}
            className="flex-1"
            label="Cancel"
          />
        </div>
      </div>
    </div>
  );
}
