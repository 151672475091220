import { datadogRum } from '@datadog/browser-rum';
import {
  CheckIcon,
  ChevronRightIcon,
  ClockIcon,
  EllipsisVerticalIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { produce } from 'immer';
import _, { isNil } from 'lodash';
import { MouseEvent, ReactNode, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import api from 'src/api';
import { useAnalyticsContext } from 'src/components/AnalyticsContext';
import Badge, { BadgeColor } from 'src/components/Badge';
import Button from 'src/components/Button';
import { Checkbox } from 'src/components/Checkbox__Shadcn';
import {
  CrepeDisplayReadonly,
  CrepeEditorWithAutosave,
} from 'src/components/CrepeEditor';
import { ModalProps, useModal } from 'src/components/Modal';
import OverflowMenu, { MenuItem } from 'src/components/OverflowMenu';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/components/Popover';
import Tooltip from 'src/components/Tooltip';
import { Role, User } from 'src/types';
import { unreachable } from 'src/typeUtils';
import { classNames } from '../App';
import {
  doesQuoteContextContainContent,
  getQuoteContextTemplate,
  OpportunityDetailPageConfig,
} from '../Opportunity/OpportunityDetailPage';
import DeletePricingFlowModal from './DeletePricingFlowModal';
import NudgeButton from './NudgeButton';
import { navigateToPricingFlow, PricingFlowModelType } from './PricingFlow';
import { PushToSalesforceButton } from './PushToSalesforceButton';
import { PricingFlowOrSnapshotForNavigation } from './QuoteOptionsSection';
import SubmitForApprovalButton from './SubmitForApprovalButton';
import SuperApproveModal from './SuperApproveModal';

function userCanManagePricingFlow(
  pricingFlow: PricingFlowOrSnapshotForNavigation,
  user: User,
) {
  if (!user.permissions.includes('edit_pricing_flow')) {
    return false;
  }
  if (user.role === Role.ADMIN) {
    return true;
  }
  return (
    pricingFlow.ownerUserId === user.id ||
    pricingFlow.createdByUserId === user.id
  );
}
function userCanManageApprovalRequest(
  approvalStatus: ApprovalStatusForPricingFlow | undefined,
  pricingFlow: PricingFlowOrSnapshotForNavigation,
  user: User,
) {
  if (!user.permissions.includes('edit_pricing_flow')) {
    return false;
  }
  if (user.role === Role.ADMIN) {
    return true;
  }
  return (
    approvalStatus?.submitter === user.id ||
    userCanManagePricingFlow(pricingFlow, user)
  );
}

export async function loadPricingFlowApprovalStatuses(
  pricingFlows: PricingFlowOrSnapshotForNavigation[],
  modelType: PricingFlowModelType,
): Promise<Record<string, ApprovalStatusForPricingFlow>> {
  return Object.fromEntries(
    await Promise.all(
      pricingFlows.map(async (pricingFlow) => {
        const response = await (async () => {
          switch (modelType) {
            case 'pricingFlowSnapshot':
              return await api.get(
                'approvals/flows/status?pricingFlowSnapshotId=' +
                  pricingFlow.id,
              );
            case 'pricingFlow':
            default:
              return await api.get(
                'approvals/flows/status?pricingFlowId=' + pricingFlow.id,
              );
          }
        })();
        const data = await response.data;
        return [pricingFlow.id, data];
      }),
    ),
  );
}

export function doesApprovalStatusRequireAnyApprovals(
  approvalStatus: ApprovalStatusForPricingFlow,
) {
  return approvalStatus.approvalFlowWithStatuses.stepsWithStatus.length > 0;
}

// #StepRequiresPackaging
type StepStatus =
  | 'APPROVED'
  | 'REJECTED'
  | 'PENDING' // This is the enum that's returned from the StepSnapshot's conditionForApprovalJavascript
  | 'REQUIRES_PACKAGING' // This is a special status that is used when a step is pending and requires packaging
  | 'OBSERVER'; // users in this step are notified but do not require approvals
export interface StepSnapshotWithStatus {
  id: string; // snapshot ID
  name: string;
  status: StepStatus;
  usersWithStatus: UserWithStatus[];
  color: string;
  approvalRules: {
    id: string;
    humanReadable: string;
  }[];
  requiresPackaging: boolean;
  superApprovedBy: User | null;
}
export interface UserWithStatus {
  id: string;
  name: string | null;
  status: UserApprovalStatus;
  requestedAt: Date | null;
}
type UserApprovalStatus = 'APPROVED' | 'REJECTED' | 'PENDING';
type ApprovalFlowStatus =
  | 'APPROVED'
  | 'REJECTED'
  | 'PENDING'
  | 'NO_APPROVAL_NEEDED'
  | 'REQUIRES_PACKAGING';
interface ApprovalFlowWithStatuses {
  status: ApprovalFlowStatus;
  stepsWithStatus: StepSnapshotWithStatus[];
}
export type ApprovalStatusForPricingFlow = {
  currentApprovalRequest: {
    id: string;
    createdAt: string;
    recalledAt?: Date;
  } | null;
  approvalFlowWithStatuses: ApprovalFlowWithStatuses;
  submitter?: string;
};

export default function PricingFlowList(props: {
  pricingFlows: PricingFlowOrSnapshotForNavigation[];
  modelType: PricingFlowModelType;
  onClick?: (pricingFlow: PricingFlowOrSnapshotForNavigation) => void;
  user: User;
  displayApprovalFlowForDraft?: boolean;
  hasApprovals?: boolean;
  hideName?: boolean;
  listItemClassName?: string;
  contextEditableMode: 'edit-only' | 'no-edit' | 'default';
  pageConfig: OpportunityDetailPageConfig;
  isApprovalModalGroupView?: boolean;
  // Note: setPricingFlowContextMd is a means of passing the markdown from the
  // quote context crepe back up to the parent. If this is non-nil, autosaving
  // will be turned off for the markdown editor, and the parent must handle
  // writing context updates back to the database!
  pricingFlowContextMds?: Record<string, string | null>;
  setPricingFlowContextMds?: React.Dispatch<
    React.SetStateAction<Record<string, string | null>>
  >;
  showOverflowMenu: boolean;
  sfdcOpportunityId: string | undefined;
  // if the context is toggleable, by default it will be hidden behind a button
  // if it is empty. For example, when a single quote is submitted for
  // approval, we don't need reps to fill in both quote and opp context.
  isContextToggleable: boolean;
}) {
  const {
    pricingFlows,
    modelType,
    onClick,
    user,
    displayApprovalFlowForDraft,
    hasApprovals,
    hideName,
    listItemClassName,
    contextEditableMode,
    pageConfig,
    isApprovalModalGroupView,
    pricingFlowContextMds,
    setPricingFlowContextMds,
    showOverflowMenu,
    sfdcOpportunityId,
    isContextToggleable,
  } = props;
  if (isNil(pricingFlowContextMds) !== isNil(setPricingFlowContextMds)) {
    datadogRum.addError(
      new Error(
        `You must either pass both or neither of pricingFlowContextMds and
        setPricingFlowContextMds to PricingFlowList`,
      ),
    );
  }
  const [approvalStatuses, setApprovalStatuses] = useState<{
    [pricingFlowId: string]: ApprovalStatusForPricingFlow;
  }>({});
  const requiresAnyApprovals = Object.values(approvalStatuses).some(
    (approvalStatus) => doesApprovalStatusRequireAnyApprovals(approvalStatus),
  );
  const [isToggleableContextShown, setIsToggleableContextShown] = useState<
    Record<string, boolean>
  >(
    pricingFlows.reduce(
      (acc, pf) => {
        acc[pf.id] = doesQuoteContextContainContent({
          pricingFlow: pf,
          context: pf.context,
          requiresAnyApprovals,
        });
        return acc;
      },
      {} as Record<string, boolean>,
    ),
  );
  const defaultValueForDisplay = 'No quote description';
  const templateForEditing = getQuoteContextTemplate({
    pricingFlow: _.first(pricingFlows),
    requiresAnyApprovals,
  });
  const createAnalyticsEvent = useAnalyticsContext();
  const { showModal, hideModal } = useModal();
  const navigate = useNavigate();

  useEffect(() => {
    hasApprovals && refreshPricingFlow();
  }, [pricingFlows, hasApprovals, modelType]);
  // console.log(approvalStatuses);
  function refreshPricingFlow() {
    loadPricingFlowApprovalStatuses(pricingFlows, modelType).then((data) => {
      setApprovalStatuses(data);
    });
  }

  const Wrapper = onClick ? 'button' : 'div';

  return (
    <>
      {pricingFlows?.map((pricingFlow) => {
        const overflowMenuMenuItems = getOverflowMenuMenuItems({
          pricingFlow,
          user,
          approvalStatus: approvalStatuses[pricingFlow.id],
          showModal,
          hideModal,
          navigate,
          sfdcOpportunityId: sfdcOpportunityId,
        });
        return (
          <Wrapper
            className={classNames(
              'flex flex-col gap-2 w-full bg-white px-3 py-2 rounded-xl text-left',
              listItemClassName,
              onClick
                ? 'hover:bg-neutral-100 cursor-pointer border border-slate-200 sm:border-none'
                : '',
            )}
            key={pricingFlow.id}
            onClick={onClick ? (e) => onClick(pricingFlow) : undefined}
          >
            <div className="flex flex-col md:flex-row w-full justify-between">
              {/* left side */}
              <div className="flex flex-col gap-1">
                {/* Name */}
                {!hideName && (
                  <div className="flex flex-col md:flex-row md:space-x-2 items-left md:items-center">
                    <div className="font-medium text-slate-950 text-sm">
                      {pricingFlow.name ?? 'Quote option'}
                    </div>
                    <div className="text-xs text-slate-600 italic">
                      Updated {dayjs(pricingFlow.updatedAt).fromNow()}
                    </div>
                  </div>
                )}

                {/* Approvals */}
                {hasApprovals && (
                  <ApprovalStatus
                    approvalStatus={approvalStatuses[pricingFlow.id]}
                    displayApprovalFlowForDraft={displayApprovalFlowForDraft}
                  />
                )}
              </div>

              {/* right side - desktop buttons */}
              <div className="hidden md:flex flex-col mt-2 md:mt-0 md:flex-row items-start gap-x-2">
                {!isApprovalModalGroupView && hasApprovals && (
                  <PrimaryApprovalActionButtons
                    pricingFlow={pricingFlow}
                    modelType={modelType}
                    approvalStatus={approvalStatuses[pricingFlow.id]}
                    user={user}
                    pageConfig={pageConfig}
                    refreshPricingFlow={refreshPricingFlow}
                    navigate={navigate}
                    opportunityId={sfdcOpportunityId}
                  />
                )}
                {showOverflowMenu && (
                  <OverflowMenu
                    menuItems={overflowMenuMenuItems}
                    placement="bottom-end"
                  >
                    <Button color="white" onClick={() => {}}>
                      <div>{'\u200B'}</div>
                      <EllipsisVerticalIcon className="h-4 w-4 -mx-2" />
                      <div>{'\u200B'}</div>
                    </Button>
                  </OverflowMenu>
                )}
                {onClick && (
                  <ChevronRightIcon className="hidden md:block w-4 h-4 mt-2 mx-1" />
                )}
              </div>
            </div>
            {/* Quote Description */}
            <div className="w-full flex-col space-y-3">
              {isContextToggleable && (
                <div className="flex items-center space-x-2 mx-1">
                  <Checkbox
                    className="border-gray-300 data-[state=checked]:bg-fuchsia-900 text-fuchsia-900 focus:ring-fuchsia-900 focus:border-fuchsia-900 rounded-sm"
                    id={`toggle-context-${pricingFlow.id}`}
                    checked={isToggleableContextShown[pricingFlow.id]}
                    onCheckedChange={(checked) => {
                      if (checked !== 'indeterminate') {
                        setIsToggleableContextShown(
                          produce(isToggleableContextShown, (draft) => {
                            draft[pricingFlow.id] = checked;
                          }),
                        );
                      }
                      if (!checked) {
                        // clear out the context
                        if (setPricingFlowContextMds && pricingFlowContextMds) {
                          setPricingFlowContextMds(
                            produce(pricingFlowContextMds, (draft) => {
                              if (draft) {
                                draft[pricingFlow.id] = '';
                              }
                            }),
                          );
                        }
                      }
                    }}
                  />
                  <label
                    htmlFor={`toggle-context-${pricingFlow.id}`}
                    className="text-sm text-gray-700 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    Add context about this quote
                  </label>
                </div>
              )}
              {(!isContextToggleable ||
                isToggleableContextShown[pricingFlow.id]) && (
                <div
                  className={classNames(
                    contextEditableMode === 'default' && '-mx-3 -mb-2',
                  )}
                >
                  {contextEditableMode === 'no-edit' ? (
                    <CrepeDisplayReadonly
                      value={pricingFlow.context}
                      placeholder={defaultValueForDisplay}
                    />
                  ) : (
                    <CrepeEditorWithAutosave
                      initialValue={
                        pricingFlow.context ??
                        pricingFlowContextMds?.[pricingFlow.id] ??
                        null
                      }
                      placeholder={defaultValueForDisplay}
                      templateForEditing={templateForEditing}
                      setMarkdown={
                        isNil(setPricingFlowContextMds) ||
                        isNil(pricingFlowContextMds)
                          ? undefined
                          : (newMarkdown: string | null) => {
                              setPricingFlowContextMds((prevMarkdowns) =>
                                produce(
                                  prevMarkdowns,
                                  (draftPricingFlowContextMds) => {
                                    draftPricingFlowContextMds[pricingFlow.id] =
                                      newMarkdown;
                                  },
                                ),
                              );
                            }
                      }
                      onMarkdownUpdated={
                        !isNil(setPricingFlowContextMds)
                          ? null
                          : async (markdown) => {
                              api.post(
                                'pricingFlows/' +
                                  pricingFlow.originalPricingFlowId +
                                  '/update-context',
                                { context: markdown },
                              );
                            }
                      }
                      editableMode={contextEditableMode}
                      onBlur={async () => {
                        createAnalyticsEvent({
                          name: 'pricing_flow__edited_context',
                          eventData: { pricing_flow_id: pricingFlow.id },
                        });
                        await api.post(
                          `pricingFlows/${pricingFlow.id}/snapshot`,
                          {},
                        );
                      }}
                    />
                  )}
                </div>
              )}
            </div>
            {/* Mobile buttons */}
            <div className="flex md:hidden w-full flex-col mt-2 md:mt-0 md:flex-row items-start gap-x-2">
              {!isApprovalModalGroupView && hasApprovals && (
                <PrimaryApprovalActionButtons
                  pricingFlow={pricingFlow}
                  modelType={modelType}
                  approvalStatus={approvalStatuses[pricingFlow.id]}
                  user={user}
                  pageConfig={pageConfig}
                  refreshPricingFlow={refreshPricingFlow}
                  navigate={navigate}
                  opportunityId={sfdcOpportunityId}
                />
              )}
            </div>
            {onClick ? (
              <div className="flex md:hidden w-full gap-2">
                <Button
                  color="white"
                  label="Details"
                  onClick={(e) => onClick(pricingFlow)}
                  className="flex-1"
                />
                {showOverflowMenu && (
                  <OverflowMenu
                    menuItems={overflowMenuMenuItems}
                    placement="bottom-end"
                  >
                    <Button color="white" className="w-full" onClick={() => {}}>
                      <div>{'\u200B'}</div>
                      <EllipsisVerticalIcon className="h-4 w-4 -mx-2" />
                      <div>{'\u200B'}</div>
                    </Button>
                  </OverflowMenu>
                )}
              </div>
            ) : (
              showOverflowMenu && (
                <div className="flex md:hidden w-full">
                  <OverflowMenu
                    menuItems={overflowMenuMenuItems}
                    placement="bottom-end"
                    fullWidth={true}
                  >
                    <Button color="white" className="w-full" onClick={() => {}}>
                      More actions
                    </Button>
                  </OverflowMenu>
                </div>
              )
            )}
          </Wrapper>
        );
      })}
    </>
  );
}

const hexToHSL = (hex: string) => {
  // Convert hex to RGB first
  let r = parseInt(hex.slice(1, 3), 16) / 255;
  let g = parseInt(hex.slice(3, 5), 16) / 255;
  let b = parseInt(hex.slice(5, 7), 16) / 255;

  // Find greatest and smallest channel values
  let cmin = Math.min(r, g, b);
  let cmax = Math.max(r, g, b);
  let delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  // Calculate hue
  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);
  if (h < 0) h += 360;

  // Calculate lightness
  l = (cmax + cmin) / 2;

  // Calculate saturation
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  return {
    h,
    s: Math.round(s * 100),
    l: Math.round(l * 100),
  };
};

export const getLightVariant = (hex: string) => {
  const { h } = hexToHSL(hex);
  // Create a very light, slightly saturated tint
  return `hsl(${h}, 85%, 96%)`;
};

function ApprovalFlow(props: {
  approvalStatus: ApprovalStatusForPricingFlow | undefined;
}) {
  const { approvalStatus } = props;
  const [expandedStepId, setExpandedStepId] = useState<string | null>(null);
  const expandedStep =
    approvalStatus?.approvalFlowWithStatuses.stepsWithStatus.find(
      (s) => s.id === expandedStepId,
    );

  const getStepColor = (
    status: StepStatus,
    fallbackColor: StepSnapshotWithStatus['color'],
  ) => {
    if (status === 'APPROVED') return '#166534';
    if (status === 'REJECTED') return '#D92D2B';
    return fallbackColor;
  };
  const getIcon = (status: StepStatus) => {
    const iconSizeClassname = 'w-3.5 h-3.5 mr-[2px]';
    switch (status) {
      case 'OBSERVER':
        datadogRum.addError(`Should not be displaying OBSERVER steps`);
        return <CheckIcon className={iconSizeClassname} />;
      case 'APPROVED':
        return <CheckIcon className={iconSizeClassname} />;
      case 'PENDING':
        return <ClockIcon className={iconSizeClassname} />;
      case 'REQUIRES_PACKAGING':
        return <ExclamationTriangleIcon className={iconSizeClassname} />;
      case 'REJECTED':
        return <XMarkIcon className={iconSizeClassname} />;
      default:
        unreachable(status);
    }
  };
  const getStepStatus = (status: StepStatus) => {
    switch (status) {
      case 'OBSERVER':
        datadogRum.addError('Should not be displaying OBSERVER steps');
        return 'Notified';
      case 'APPROVED':
        return 'Approved';
      case 'REJECTED':
        return 'Rejected';
      case 'PENDING':
        return 'Required';
      case 'REQUIRES_PACKAGING':
        return 'Requires packaging';
      default:
        unreachable(status);
    }
  };
  const getUserBadge = (
    userStatus: UserApprovalStatus,
    stepStatus: StepStatus,
  ) => {
    switch (userStatus) {
      case 'APPROVED':
        return <Badge color="green">Approved</Badge>;
      // case 'SMART_APPROVED':
      //   return <Badge color="green">Smart approved</Badge>;
      case 'REJECTED':
        return <Badge color="red">Rejected</Badge>;
      case 'PENDING':
        if (stepStatus === 'APPROVED' || stepStatus === 'OBSERVER') {
          // If the step is approved, the user is not required
          return null;
        }
        return <Badge color="yellow">Required</Badge>;
      default:
        unreachable(userStatus);
    }
  };

  if (isNil(approvalStatus)) return null;
  if (approvalStatus.approvalFlowWithStatuses.stepsWithStatus.length === 0) {
    return (
      <div className="flex flex-col gap-4 w-full max-w-2xl">
        <div className="flex flex-row flex-wrap">
          <Badge
            color="none"
            className="bg-none border border-transparent content-center"
            style={{
              color: getStepColor('APPROVED', '#166534'),
            }}
          >
            {getIcon('APPROVED')}
            No approval needed
          </Badge>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 w-full max-w-2xl">
      <div className="flex flex-row flex-wrap">
        {approvalStatus.approvalFlowWithStatuses.stepsWithStatus.map((step) => {
          if (step.status === 'OBSERVER') {
            return null;
          }
          return (
            <div key={step.id} className="flex flex-col">
              <Tooltip
                as="span"
                className=""
                location="TOP"
                text={getStepStatus(step.status)}
              >
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setExpandedStepId(
                      expandedStepId === step.id ? null : step.id,
                    );
                  }}
                >
                  <Badge
                    color="none"
                    className="hover:opacity-80 bg-none hover:bg-white border border-transparent hover:border-gray-200 content-center"
                    style={{
                      color: getStepColor(step.status, step.color),
                      opacity: isNil(expandedStepId)
                        ? 1
                        : expandedStepId === step.id
                          ? 1
                          : 0.6,
                    }}
                  >
                    {getIcon(step.status)}
                    {step.name}
                  </Badge>
                </button>
              </Tooltip>
            </div>
          );
        })}
      </div>
      {expandedStepId && (
        <div className="flex flex-col gap-1">
          {expandedStep?.status === 'REQUIRES_PACKAGING' && (
            // Information about the step that requires packaging
            <div className="flex items-center gap-3 p-2 bg-white rounded-lg border border-slate-200">
              <span className="text-sm font-medium">
                This step requires packaging. <br />
                You will need to add this quote to a group before it can be
                approved.
              </span>
            </div>
          )}
          {expandedStep?.usersWithStatus.map((user) => (
            <div
              key={user.name}
              className="flex items-center gap-8 p-2 bg-white rounded-lg border border-slate-200 flex-row justify-between"
            >
              <div className="flex flex-col gap-2 items-left">
                <span className="text-sm font-medium">{user.name}</span>
                {!isNil(user.requestedAt) && (
                  <>
                    <span className="text-xs text-slate-500">
                      Requested {dayjs(user.requestedAt).fromNow()}
                    </span>
                    <NudgeButton
                      userToNotify={user}
                      currentApprovalRequest={
                        approvalStatus.currentApprovalRequest
                      }
                    />
                  </>
                )}
              </div>

              {getUserBadge(user.status, expandedStep.status)}
            </div>
          ))}
          {!isNil(expandedStep?.superApprovedBy) && (
            <div
              key={expandedStep.superApprovedBy.name}
              className="flex items-center gap-8 p-2 bg-white rounded-lg border border-slate-200 flex-row justify-between"
            >
              <div className="flex flex-col gap-2 items-left">
                <span className="text-sm font-medium">
                  {expandedStep.superApprovedBy.name}
                </span>
              </div>

              <Badge color="green" className="ring-2 ring-yellow-200">
                Admin Override
              </Badge>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
function ApprovalStatus(props: {
  approvalStatus: ApprovalStatusForPricingFlow | undefined;
  displayApprovalFlowForDraft?: boolean;
}) {
  const { approvalStatus, displayApprovalFlowForDraft } = props;

  const [isApprovalFlowExpanded, setIsApprovalFlowExpanded] = useState(
    displayApprovalFlowForDraft,
  );
  const [approvalFlowDetails, setApprovalFlowDetails] = useState<{
    color: BadgeColor;
    text: string;
    displayDetails: boolean;
  } | null>(null);

  useEffect(() => {
    const statusDisplay = getStatusDisplay(approvalStatus);
    setIsApprovalFlowExpanded(
      statusDisplay.displayDetails || displayApprovalFlowForDraft,
    );
    setApprovalFlowDetails(statusDisplay);
  }, [approvalStatus]);

  if (isNil(approvalFlowDetails)) return null;

  function getStatusDisplay(
    approvalStatus: ApprovalStatusForPricingFlow | undefined,
  ): {
    color: BadgeColor;
    text: string;
    displayDetails: boolean;
  } {
    if (isNil(approvalStatus))
      return { color: 'gray', text: 'Draft', displayDetails: false };
    if (approvalStatus.currentApprovalRequest) {
      if (!isNil(approvalStatus.currentApprovalRequest.recalledAt)) {
        return { color: 'red', text: 'Recalled', displayDetails: true };
      }
      switch (approvalStatus.approvalFlowWithStatuses.status) {
        case 'APPROVED':
          return { color: 'green', text: 'Approved', displayDetails: true };
        case 'REJECTED':
          return { color: 'red', text: 'Rejected', displayDetails: true };
        case 'PENDING':
          return {
            color: 'orange',
            text: 'Pending approval',
            displayDetails: true,
          };
        case 'NO_APPROVAL_NEEDED':
          return {
            color: 'green',
            text: 'No approval needed',
            displayDetails: false,
          };
        case 'REQUIRES_PACKAGING':
          return {
            color: 'orange',
            text: 'Requires packaging',
            displayDetails: true,
          };
        default:
          unreachable(approvalStatus.approvalFlowWithStatuses.status);
      }
    } else {
      switch (approvalStatus.approvalFlowWithStatuses.status) {
        case 'APPROVED':
          return {
            color: 'green',
            text: 'Smart approved',
            displayDetails: true,
          };
        case 'PENDING':
        case 'REQUIRES_PACKAGING':
          return { color: 'gray', text: 'Draft', displayDetails: false };
        case 'NO_APPROVAL_NEEDED':
          return {
            color: 'green',
            text: 'No approval needed',
            displayDetails: false,
          };
        case 'REJECTED':
          datadogRum.addError(
            `step status was REJECTED even though there is no current approval request?`,
          );
          return { color: 'gray', text: 'Draft', displayDetails: false };
        default:
          unreachable(approvalStatus.approvalFlowWithStatuses.status);
      }
    }
  }
  return (
    <div className="flex flex-col lg:flex-row gap-1">
      <div className="text-nowrap mr-1">
        <Badge color={approvalFlowDetails.color}>
          {approvalFlowDetails.text}
        </Badge>
      </div>
      {isApprovalFlowExpanded && (
        <span className="text-slate-200 hidden lg:block">|</span>
      )}
      {isApprovalFlowExpanded && (
        <ApprovalFlow approvalStatus={approvalStatus} />
      )}
    </div>
  );
}

function PrimaryApprovalActionButtons(props: {
  pricingFlow: PricingFlowOrSnapshotForNavigation;
  modelType: PricingFlowModelType;
  approvalStatus: ApprovalStatusForPricingFlow | undefined;
  user: User;
  pageConfig: OpportunityDetailPageConfig;
  refreshPricingFlow: () => void;
  navigate: NavigateFunction;
  opportunityId: string | undefined;
}) {
  const {
    pricingFlow,
    modelType,
    approvalStatus,
    user,
    pageConfig,
    refreshPricingFlow,
    navigate,
    opportunityId,
  } = props;

  const { showModal, hideModal } = useModal();
  const [rejectionComment, setRejectionComment] = useState<string | null>('');

  if (isNil(approvalStatus)) return null;
  const { currentApprovalRequest } = approvalStatus;

  const isApprover =
    approvalStatus?.approvalFlowWithStatuses.stepsWithStatus.some(
      (s) =>
        s.status !== 'OBSERVER' &&
        s.usersWithStatus.some((u) => u.id === user.id),
    );

  const userApprovalStatus = isApprover
    ? approvalStatus.approvalFlowWithStatuses.stepsWithStatus
        .find((s) => s.usersWithStatus.some((u) => u.id === user.id))
        ?.usersWithStatus.find((u) => u.id === user.id)?.status
    : undefined;

  const isEditor = user.permissions.includes('edit_pricing_flow');

  const noApprovalsNeeded =
    approvalStatus.approvalFlowWithStatuses.stepsWithStatus.length === 0;

  // Possible buttons for approvers
  const recallApprovalButton = isNil(currentApprovalRequest) ? null : (
    <Button
      key="recall-approval"
      color="white"
      onClick={async (e) => {
        e.stopPropagation();
        await api.put(
          `approvals/requests/${currentApprovalRequest.id}/actions/recall`,
          {},
        );
        console.log('refreshing pricing flow');
        refreshPricingFlow();
      }}
      label="Recall approval"
    />
  );
  const recallRejectionButton = isNil(currentApprovalRequest) ? null : (
    <Button
      key="recall-rejection"
      color="white"
      onClick={async (e) => {
        e.stopPropagation();
        await api.put(
          `approvals/requests/${currentApprovalRequest.id}/actions/recall`,
          {},
        );
        console.log('refreshing pricing flow');
        refreshPricingFlow();
      }}
      label="Recall rejection"
    />
  );

  const approvalButtons = isNil(currentApprovalRequest) ? null : (
    <div
      key="approval-buttons"
      className="flex items-center gap-x-2 w-full md:w-auto"
    >
      <Popover>
        <PopoverTrigger
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="flex-1 md:flex-none"
        >
          <Button
            color="red"
            onClick={() => {}}
            label="Reject"
            className="w-full"
          />
        </PopoverTrigger>
        <PopoverContent
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="sm:w-96 md:w-96"
          // collisionPadding={30}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="flex flex-col gap-y-2"
          >
            <CrepeEditorWithAutosave
              placeholder="Add an optional explanation..."
              editableMode="edit-only"
              onMarkdownUpdated={null}
              initialValue={null}
              onBlur={() => {}}
              setMarkdown={setRejectionComment}
              templateForEditing={null}
            />
            <div className="flex justify-end">
              <Button
                color="red"
                label="Reject"
                onClick={async (e) => {
                  e.stopPropagation();
                  const action = (
                    await api.post(
                      `approvals/requests/${currentApprovalRequest.id}/actions`,
                      { action: 'REJECT' },
                    )
                  ).data;
                  if (
                    !isNil(rejectionComment) &&
                    rejectionComment.trim() !== ''
                  ) {
                    await api.post('comments', {
                      pricingFlowId: pricingFlow.id,
                      comment: rejectionComment.trim(),
                      parentActionId: action.id,
                    });
                  }
                  console.log('refreshing pricing flow');
                  refreshPricingFlow();
                }}
              />
            </div>
          </div>
        </PopoverContent>
      </Popover>
      <Button
        color="green"
        onClick={async (e) => {
          e.stopPropagation();
          await api.post(
            `approvals/requests/${currentApprovalRequest.id}/actions`,
            { action: 'APPROVE' },
          );
          console.log('refreshing pricing flow');
          refreshPricingFlow();
        }}
        label="Approve"
        className="flex-1 md:flex-none"
      />
    </div>
  );

  // Possible buttons for submitters/editors/admin
  const submitForApprovalButton = (
    <SubmitForApprovalButton
      buttonProps={{ color: 'white', label: 'Submit for approval' }}
      pricingFlow={pricingFlow}
      user={user}
      pageConfig={pageConfig}
      key="submit-for-approval"
    />
  );

  const addToGroupButton = (
    <SubmitForApprovalButton
      buttonProps={{ color: 'white', label: 'Add to group' }}
      pricingFlow={pricingFlow}
      user={user}
      pageConfig={pageConfig}
      key="add-to-group"
    />
  );

  const pushToSalesforceButton = (
    <PushToSalesforceButton pricingFlow={pricingFlow} user={user} />
  );

  const recallRequestButton = isNil(currentApprovalRequest) ? null : (
    <Button
      key="recall-request"
      color="white"
      onClick={async (e) => {
        e.stopPropagation();
        if (noApprovalsNeeded) {
          await api.put(
            `approvals/requests/${currentApprovalRequest.id}/recall`,
            {},
          );
          hideModal();
          // Send to the pricing flow page
          if (opportunityId === undefined) {
            datadogRum.addError(
              `opportunityId is undefined, failed to redirect after unlocking quote ${pricingFlow.id}`,
            );
          } else {
            navigateToPricingFlow({
              navigate,
              sfdcOpportunityId: opportunityId,
              pricingFlowId: pricingFlow.id,
            });
          }
        } else {
          showModal({
            newStyle: true,
            title: 'Recall approval request',
            children: (
              <div className="flex-1 flex flex-col overflow-hidden">
                <span className="p-4 font-medium text-sm text-slate-600 mb-4">
                  Are you sure you want to recall this approval request?
                </span>
                <div className="mt-4 shrink-0 border-t border-100">
                  <div className="flex flex-col sm:flex-row justify-between p-4 gap-2 sm:gap-4 sm:flex-row-reverse">
                    <Button
                      color="red"
                      onClick={async (e) => {
                        e.stopPropagation();
                        await api.put(
                          `approvals/requests/${currentApprovalRequest.id}/recall`,
                          {},
                        );
                        hideModal();
                        // Send to the pricing flow page
                        if (opportunityId === undefined) {
                          datadogRum.addError(
                            `opportunityId is undefined, failed to redirect after unlocking quote ${pricingFlow.id}`,
                          );
                        } else {
                          navigateToPricingFlow({
                            navigate,
                            sfdcOpportunityId: opportunityId,
                            pricingFlowId: pricingFlow.id,
                          });
                        }
                      }}
                      label={'Recall request'}
                      className="flex-1"
                    />
                    <Button
                      color="white"
                      onClick={hideModal}
                      label="Cancel"
                      className="flex-1"
                    />
                  </div>
                </div>
              </div>
            ),
          });
        }
      }}
      label={'Recall request'}
    />
  );

  if (modelType === 'pricingFlowSnapshot') {
    return null;
  }

  const actions: (ReactNode | null)[] = [];

  // Add actions for approvers
  if (isApprover && userApprovalStatus) {
    switch (userApprovalStatus) {
      case 'APPROVED':
        actions.push(recallApprovalButton);
        break;
      // case 'SMART_APPROVED':
      //   actions.push(
      //     <Button
      //       color="white"
      //       onClick={() => {
      //         navigate(`pricingflow/${pricingFlow.id}`, { relative: 'path' });
      //       }}
      //       label="Recall smart approval"
      //     />
      //   );
      //   break;
      case 'REJECTED':
        actions.push(recallRejectionButton);
        break;
      case 'PENDING':
        actions.push(approvalButtons);
        break;
      default:
        unreachable(userApprovalStatus);
    }
  }

  // Add actions for editors (submitters or admins)
  if (isEditor) {
    if (
      isNil(currentApprovalRequest) &&
      userCanManagePricingFlow(pricingFlow, user)
    ) {
      // There is no current request out
      actions.push(submitForApprovalButton);
    } else if (
      userCanManageApprovalRequest(approvalStatus, pricingFlow, user)
    ) {
      const approvalRequestStatus =
        approvalStatus.approvalFlowWithStatuses.status;
      // There is a current request out
      if (
        approvalRequestStatus === 'PENDING' ||
        approvalRequestStatus === 'REJECTED'
      ) {
        actions.push(recallRequestButton);
      }
      if (approvalRequestStatus === 'REQUIRES_PACKAGING') {
        actions.push(addToGroupButton);
      }
      if (
        approvalRequestStatus === 'APPROVED' ||
        approvalRequestStatus === 'NO_APPROVAL_NEEDED'
      ) {
        actions.push(pushToSalesforceButton);
      }
    }
  }

  return (
    <div className="flex w-full md:w-auto items-left md:items-center flex-col md:flex-row gap-y-2 md:gap-y-0 md:gap-x-2">
      {actions.filter((a) => !isNil(a))}
    </div>
  );
}

function getOverflowMenuMenuItems(params: {
  pricingFlow: PricingFlowOrSnapshotForNavigation;
  user: User;
  approvalStatus: ApprovalStatusForPricingFlow | undefined;
  showModal: (content: ModalProps) => void;
  hideModal: () => void;
  navigate: NavigateFunction;
  sfdcOpportunityId: string | undefined;
}) {
  const {
    pricingFlow,
    user,
    approvalStatus,
    showModal,
    hideModal,
    navigate,
    sfdcOpportunityId,
  } = params;
  const isEditor = user.permissions.includes('edit_pricing_flow');

  const deleteButton = {
    name: 'Delete',
    icon: 'trash' as const,
    color: 'red' as const,
    onClick: async (e: MouseEvent<HTMLAnchorElement>) => {
      showModal({
        newStyle: true,
        title: `Are you sure you want to delete ${pricingFlow.name ?? 'this quote'}?`,
        children: (
          <DeletePricingFlowModal
            pricingFlow={pricingFlow}
            hideModal={hideModal}
          />
        ),
      });
      e.stopPropagation();
    },
  };
  const recallRequestButton = {
    name: 'Recall request',
    onClick: async (e: MouseEvent<HTMLAnchorElement>) => {
      e.stopPropagation();
      showModal({
        newStyle: true,
        title: 'Recall approval request',
        children: (
          <div className="flex-1 flex flex-col overflow-hidden">
            <span className="p-4 font-medium text-sm text-slate-600 mb-4">
              Are you sure you want to recall this approval request?
            </span>
            <div className="mt-4 shrink-0 border-t border-100">
              <div className="flex flex-col sm:flex-row justify-between p-4 gap-2 sm:gap-4 sm:flex-row-reverse">
                <Button
                  color="red"
                  onClick={async (e) => {
                    e.stopPropagation();
                    await api.put(
                      `approvals/requests/${approvalStatus?.currentApprovalRequest?.id}/recall`,
                      {},
                    );
                    hideModal();
                    // Send to the pricing flow page
                    if (sfdcOpportunityId === undefined) {
                      datadogRum.addError(
                        `opportunityId is undefined, failed to redirect after unlocking quote ${pricingFlow.id}`,
                      );
                    } else {
                      navigateToPricingFlow({
                        navigate,
                        sfdcOpportunityId,
                        pricingFlowId: pricingFlow.id,
                      });
                    }
                  }}
                  label={'Recall request'}
                  className="flex-1"
                />
                <Button
                  color="white"
                  onClick={hideModal}
                  label="Cancel"
                  className="flex-1"
                />
              </div>
            </div>
          </div>
        ),
      });
    },
  };

  const unlockQuoteButton = {
    name: 'Unlock quote',
    onClick: async (e: MouseEvent<HTMLAnchorElement>) => {
      e.stopPropagation();
      await api.put(
        `approvals/requests/${approvalStatus?.currentApprovalRequest?.id}/recall`,
        {},
      );
      // Send to the pricing flow page
      if (sfdcOpportunityId === undefined) {
        datadogRum.addError(
          `opportunityId is undefined, failed to redirect after unlocking quote ${pricingFlow.id}`,
        );
      } else {
        navigateToPricingFlow({
          navigate,
          sfdcOpportunityId,
          pricingFlowId: pricingFlow.id,
        });
      }
    },
  };
  const superApproveButton = (() => {
    if (isNil(approvalStatus?.currentApprovalRequest)) {
      return null;
    }
    const nextPendingStep =
      approvalStatus.approvalFlowWithStatuses.stepsWithStatus.find(
        (s) => s.status === 'PENDING' || s.status === 'REJECTED',
      );
    if (isNil(nextPendingStep)) {
      return null;
    }
    return {
      name: 'Bypass Approval',
      icon: 'warning' as const,
      onClick: async (e: MouseEvent<HTMLAnchorElement>) => {
        showModal({
          newStyle: true,
          title: `Bypass Approval for ${nextPendingStep.name}`,
          children: (
            <SuperApproveModal
              pricingFlow={pricingFlow}
              hideModal={hideModal}
              nextPendingStep={nextPendingStep}
              approvalStatus={approvalStatus}
            />
          ),
        });
        e.stopPropagation();
      },
    };
  })();

  const actions: MenuItem[] = [];
  if (
    isEditor &&
    userCanManageApprovalRequest(approvalStatus, pricingFlow, user)
  ) {
    if (!isNil(approvalStatus?.currentApprovalRequest)) {
      const approvalRequestStatus =
        approvalStatus.approvalFlowWithStatuses.status;
      // There is a current request out
      if (approvalRequestStatus === 'REQUIRES_PACKAGING') {
        actions.push(recallRequestButton);
      }
      if (approvalRequestStatus === 'APPROVED') {
        actions.push(recallRequestButton);
      }
      if (approvalRequestStatus === 'NO_APPROVAL_NEEDED') {
        actions.push(unlockQuoteButton);
      }
      if (
        (approvalRequestStatus === 'PENDING' ||
          approvalRequestStatus === 'REJECTED') &&
        user.role === Role.ADMIN &&
        !isNil(superApproveButton)
      ) {
        actions.push(superApproveButton);
      }
    }
    actions.push(deleteButton); // Delete is always last
  }
  return actions;
}
