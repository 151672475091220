import dayjs, { Dayjs } from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import _, { isNil } from 'lodash';
import { classNames } from 'src/dashboard/App';
import { usePricingFlowContext } from '../PricingFlow';
import { formatNumberAsUSDCV } from './HamsterQuoteTable';
import {
  HamsterOpportunityData,
  HamsterPricingFlow,
  HamsterProductPrice,
} from './hamster_types';
import {
  estimatedMonthlyRevenue,
  numMonthsCoveredByQuote,
} from './hamster_utils';
dayjs.extend(minMax);

type ProductType = 'credit' | 'revenue' | 'all';
function productMatchesFilter(
  productInfo: HamsterProductPrice,
  filter: ProductType,
) {
  if (filter === 'credit' && productInfo.isRebate !== true) {
    return false;
  }
  if (filter === 'revenue' && productInfo.isRebate === true) {
    return false;
  }
  return true;
}

// ##NumProratedMonthsBetween
// Keep these in sync!
export function numProratedMonthsBetween(
  startDate: Dayjs,
  endDateExclusive: Dayjs,
) {
  let totalProportion = 0;
  let currentMonthStart = startDate.startOf('month');
  while (currentMonthStart.isBefore(endDateExclusive)) {
    const currentMonthEndIncl = currentMonthStart.endOf('month');
    const currentMonthEndExcl = currentMonthEndIncl.add(1, 'day');
    const daysInCurrentMonth = currentMonthEndIncl.date();

    let daysCounted: number;
    if (currentMonthStart.isSame(startDate, 'month')) {
      // First month, count days from curDate to end of this month
      daysCounted = dayjs
        .min(currentMonthEndExcl, endDateExclusive)
        .diff(startDate, 'day');
    } else {
      // Subsequent months, count full days in the month
      daysCounted = dayjs
        .min(currentMonthEndExcl, endDateExclusive)
        .diff(currentMonthStart, 'day');
    }

    totalProportion += daysCounted / daysInCurrentMonth;

    // Move to the next month
    currentMonthStart = currentMonthStart.add(1, 'month').startOf('month');
  }

  return totalProportion;
}

export function isExpansionOpp(pricingFlow: HamsterPricingFlow) {
  return (
    (pricingFlow.opportunity.opportunityData as HamsterOpportunityData).Type ===
    'Expansion'
  );
}

export function getStartAndEndDate(pricingFlow: HamsterPricingFlow) {
  const startDate = dayjs(pricingFlow.additionalData.startDate);
  if (isExpansionOpp(pricingFlow)) {
    const endDateFromSfdc = (
      pricingFlow.opportunity.opportunityData as HamsterOpportunityData
    ).Service_End_Date_Formula__c;
    // endDateInclusive means that this date is counted as a billed date.
    // Exclusive means the last date is not billed
    const endDateInclusive =
      dayjs(endDateFromSfdc) ??
      dayjs(startDate)
        .add(pricingFlow.additionalData.subscriptionTerms, 'months')
        .subtract(1, 'day');
    const endDateExclusive = endDateInclusive.add(1, 'day');

    return { startDate, endDateInclusive, endDateExclusive };
  } else {
    const endDateExclusive = dayjs(startDate).add(
      pricingFlow.additionalData.subscriptionTerms,
      'months',
    );
    const endDateInclusive = dayjs(endDateExclusive).subtract(1, 'day');
    return { startDate, endDateInclusive, endDateExclusive };
  }
}

function computeTCV(pricingFlow: HamsterPricingFlow, filterTo: ProductType) {
  return pricingFlow.products.reduce((acc, product) => {
    const productInfo = pricingFlow.pricingSheetData.productInfo[product.id];
    if (isNil(productInfo)) {
      return acc;
    }
    if (!productMatchesFilter(productInfo, filterTo)) {
      return acc;
    }
    const months = Array.from(
      { length: numMonthsCoveredByQuote(pricingFlow) },
      (_, i) => i,
    );
    return (
      acc +
      _.sum(
        months.map((monthIdx) => {
          return estimatedMonthlyRevenue({
            product,
            productInfo,
            monthIdx,
            pricingFlow,
          });
        }),
      )
    );
  }, 0);
}

function computeArrAtScale(
  pricingFlow: HamsterPricingFlow,
  filterTo: ProductType,
) {
  return (
    pricingFlow.products.reduce((acc, product) => {
      const productInfo = pricingFlow.pricingSheetData.productInfo[product.id];
      if (isNil(productInfo)) {
        return acc;
      }
      if (!productMatchesFilter(productInfo, filterTo)) {
        return acc;
      }
      return (
        acc +
        estimatedMonthlyRevenue({
          product,
          productInfo,
          monthIdx: 'for_arr_calc',
          pricingFlow,
        })
      );
    }, 0) * 12
  );
}
export default function HamsterAnnualRevenueTable(props: {
  className?: string;
}) {
  const { pricingFlow } = usePricingFlowContext<HamsterPricingFlow>();

  const tcvCredits = computeTCV(pricingFlow, 'credit');
  const tcvRevenue = computeTCV(pricingFlow, 'revenue');

  const arrCredits = computeArrAtScale(pricingFlow, 'credit');
  const arrRevenue = computeArrAtScale(pricingFlow, 'revenue');

  const revenueCellStyle =
    'font-normal text-sm md:text-lg text-gray-700 p-2 md:px-6 md:py-3.5 border-gray-200';
  const oppData = pricingFlow.opportunity.opportunityData;
  const baselineArr =
    'Baseline_ARR__c' in oppData ? oppData.Baseline_ARR__c : 0;
  return (
    <div
      className={classNames(
        'w-full md:w-auto overflow-x-auto rounded-none md:rounded-xl border border-gray-200 bg-white',
        props.className,
      )}
    >
      <table className="w-full md:w-auto border-separate border-spacing-0">
        <thead>
          <tr className="bg-gray-50">
            <th className="rounded-none md:rounded-tl-xl border-b border-gray-200"></th>
            {/* <th className="font-medium text-xs text-left align-bottom whitespace-nowrap p-2 md:px-6 md:py-3.5 text-gray-700 border-b border-gray-200">
              Gross Revenue
            </th> */}
            {/* <th className="font-medium text-xs text-left align-bottom whitespace-nowrap p-2 md:px-6 md:py-3.5 text-gray-700 border-b border-gray-200">
              Credits
            </th> */}
            <th className="font-medium text-xs text-left align-bottom whitespace-nowrap rounded-none md:rounded-tr-xl p-2 md:px-6 md:py-3.5 text-gray-700 border-b border-gray-200">
              Revenue
              {/* If we bring back Credits, rename this to Net Total */}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="font-medium text-xs text-gray-700 p-2 md:px-6 md:py-3.5 border-b border-gray-200">
              TCV
            </td>
            {/* TCV - revenue */}
            {/* <td className={classNames(revenueCellStyle, 'border-b')}>
              {formatNumberAsUSDCV(tcvRevenue, 0)}
            </td> */}
            {/* TCV - credits */}
            {/* <td
              className={classNames(revenueCellStyle, 'border-b text-red-900')}
            >
              {formatNumberAsUSDCV(tcvCredits, 0)}
            </td> */}
            {/* TCV - net */}
            <td className={classNames(revenueCellStyle, 'border-b')}>
              {formatNumberAsUSDCV(tcvRevenue + tcvCredits, 0)}
            </td>
          </tr>
          <tr>
            <td className="font-medium text-xs text-gray-700 p-2 md:px-6 md:py-3.5 border-b border-gray-200">
              ARR
            </td>
            {/* ARR - revenue */}
            {/* <td className={classNames(revenueCellStyle, 'border-b')}>
              {formatNumberAsUSDCV(arrRevenue, 0)}
            </td> */}
            {/* ARR - credits */}
            {/* <td
              className={classNames(
                classNames(revenueCellStyle, 'border-b'),
                'text-red-900',
              )}
            >
              {formatNumberAsUSDCV(arrCredits, 0)}
            </td> */}
            {/* ARR - net */}
            <td className={classNames(revenueCellStyle, 'border-b')}>
              {formatNumberAsUSDCV(arrRevenue + arrCredits, 0)}
            </td>
          </tr>
          <tr>
            <td className="font-medium text-xs text-gray-700 p-2 md:px-6 md:py-3.5 whitespace-nowrap">
              Net New ARR
            </td>
            {/* net new ARR - revenue */}
            {/* <td className={classNames(revenueCellStyle)}></td> */}
            {/* net new ARR - credits */}
            {/* <td className={classNames(revenueCellStyle)}></td> */}
            {/* net new ARR - net */}
            <td className={classNames(revenueCellStyle, 'font-semibold')}>
              {formatNumberAsUSDCV(
                arrRevenue + arrCredits - (baselineArr ?? 0),
                0,
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
