import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import api from 'src/api';
import Button from 'src/components/Button';
import { InlineSpinner } from 'src/components/Loading';
import { useModal } from 'src/components/Modal';
import { classNames } from 'src/dashboard/App';
import {
  growthPlanOptionRequiresFreetextDesc,
  PenguinPricingFlow,
} from '../penguin_types';
import PenguinFinalizeQuoteModal from './PenguinFinalizeQuoteModal';

interface PenguinGrowthPlanFeedbackModalProps {
  pricingFlow: PenguinPricingFlow;
  updateFlow: (flow: PenguinPricingFlow, showLoading: boolean) => void;
}
export default function PenguinGrowthPlanFeedbackModal(
  props: PenguinGrowthPlanFeedbackModalProps,
) {
  const { showModal, hideModal } = useModal();
  const { pricingFlow, updateFlow } = props;
  const [selectedOption, setSelectedOption] = useState(
    pricingFlow.additionalData.growthPlanFeedbackOption,
  );
  const [freetextDesc, setFreetextDesc] = useState(
    pricingFlow.additionalData.growthPlanFeedbackOther,
  );
  const [
    growthPlanFeedbackPicklistValues,
    setGrowthPlanFeedbackPicklistValues,
  ] = useState<{ label: string; value: string }[] | null>(null);
  useEffect(() => {
    api.get('growth_plan_feedback_options').then((res) => {
      setGrowthPlanFeedbackPicklistValues(res.data.picklistValues);
    });
  }, []);
  const isFormFilledOut =
    !isNil(selectedOption) &&
    (!growthPlanOptionRequiresFreetextDesc(selectedOption) ||
      Boolean(freetextDesc));
  const NULL_OPTION_KEY = 'NULL_OPTION_KEY';
  return (
    <div className="mt-4 mx-[-24px] mb-[-24px]">
      <div className="px-6">
        {/* selector */}
        <div className="flex flex-col">
          <label className="text-sm text-slate-700 mb-4 min-h-6">
            Please indicate why a custom plan is required for this small ACV
            deal. Your feedback will be used to improve Growth Plans.
          </label>
          {isNil(growthPlanFeedbackPicklistValues) ? (
            <InlineSpinner />
          ) : (
            <>
              <select
                className="text-md rounded-md outline-1 outline-gray-300 bg-transparent p-2 text-gray-900 shadow-sm outline-none border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900 focus:border-none focus:ring-0 focus:ring-transparent w-full"
                value={selectedOption}
                onChange={(e) => {
                  if (e.target.value === NULL_OPTION_KEY) {
                    setSelectedOption(undefined);
                  } else {
                    setSelectedOption(e.target.value);
                  }
                }}
              >
                <option value={NULL_OPTION_KEY}>--None--</option>
                {growthPlanFeedbackPicklistValues?.map(({ label, value }) => {
                  return <option value={value}>{label}</option>;
                })}
              </select>
              <input
                type="text"
                className={classNames(
                  'mt-4 text-md rounded-md outline-1 outline-gray-300 bg-transparent p-2 text-gray-900 shadow-sm outline-none border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900 focus:border-none focus:ring-0 focus:ring-transparent w-full placeholder:text-gray-400',
                  !growthPlanOptionRequiresFreetextDesc(selectedOption) &&
                    'invisible',
                )}
                placeholder="Explanation..."
                value={freetextDesc}
                onChange={(e) => {
                  setFreetextDesc(e.target.value.slice(0, 200));
                }}
              ></input>
            </>
          )}
        </div>
      </div>
      {/* Buttons */}
      <div className="flex flex-row justify-between pt-4 mt-6 border-t border-100 gap-4">
        <Button
          className="flex-1 text-sm font-semibold ml-6 px-3.5 py-2.5"
          color="white"
          onClick={hideModal}
          label="Close"
        />
        <Button
          className="flex-1 text-sm font-semibold mr-6 px-3.5 py-2.5"
          color="primary"
          disabled={!isFormFilledOut}
          onClick={async () => {
            await updateFlow(
              {
                ...pricingFlow,
                additionalData: {
                  ...pricingFlow.additionalData,
                  growthPlanFeedbackOption: selectedOption,
                  growthPlanFeedbackOther: freetextDesc,
                },
              },
              false,
            );
            console.log('call POST quotes?pricingFlowId={}');
            const eventualResponse = api.post(
              'quotes?pricingFlowId=' + pricingFlow.id,
              { currentPricingCurves: pricingFlow.currentPricingCurves },
            );
            showModal({
              title: 'Make sure to recalculate approvals',
              children: (
                <PenguinFinalizeQuoteModal
                  close={hideModal}
                  eventualResponse={eventualResponse}
                />
              ),
            });
          }}
        >
          Generate quote in SFDC
        </Button>
      </div>
    </div>
  );
}
