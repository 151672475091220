'use client';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import * as React from 'react';
import { cn } from 'src/utils';

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = ({
  children,
  ...props
}: { children: React.ReactNode } & TooltipPrimitive.TooltipProps) => {
  const [open, setOpen] = React.useState(false);
  const isMobile = React.useMemo(() => {
    if (typeof window === 'undefined') return false;
    return /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
  }, []);

  return (
    <TooltipPrimitive.Root open={open} onOpenChange={setOpen} {...props}>
      {children}
    </TooltipPrimitive.Root>
  );
};

const TooltipTrigger = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TooltipPrimitive.Trigger
    ref={ref}
    className={cn(className)}
    {...props}
    onClick={(e) => {
      props.onClick?.(e);
      const isMobile = /iPhone|iPad|iPod|Android/i.test(
        window.navigator.userAgent,
      );
      if (isMobile) {
        e.preventDefault();
        const tooltip = e.currentTarget.parentElement;
        if (tooltip) {
          const currentState = tooltip.getAttribute('data-state');
          tooltip.setAttribute(
            'data-state',
            currentState === 'closed' ? 'open' : 'closed',
          );
        }
      }
    }}
  />
));
TooltipTrigger.displayName = TooltipPrimitive.Trigger.displayName;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Portal>
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      avoidCollisions={true}
      collisionPadding={2}
      className={cn(
        'whitespace-normal break-words z-50 overflow-hidden rounded-md bg-primary px-3 py-1.5 text-xs text-primary-foreground animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        className,
      )}
      {...props}
    />
  </TooltipPrimitive.Portal>
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };
