import '@milkdown/crepe/theme/common/style.css';
import '@milkdown/crepe/theme/frame.css';
import 'src/components/CrepeEditor.css';

import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { EditorStatus } from '@milkdown/core';
import { replaceAll } from '@milkdown/kit/utils';
import { useState } from 'react';
import api from 'src/api';
import { useAnalyticsContext } from 'src/components/AnalyticsContext';
import {
  CrepeDisplayReadonly,
  CrepeEditorWithAutosave,
} from 'src/components/CrepeEditor';
import { Spinner } from 'src/components/Loading';
import { classNames } from 'src/dashboard/App';
import { getOpportunityContextTemplate } from 'src/dashboard/Opportunity/OpportunityDetailPage';
import { Opportunity } from 'src/dashboard/Opportunity/types';
import { User } from 'src/types';

interface OpportunityContextEditorProps {
  opportunity: Opportunity;
  setMarkdown: React.Dispatch<React.SetStateAction<string | null>>;
  user: User;
}
function OpportunityContextEditor(props: OpportunityContextEditorProps) {
  const { opportunity, setMarkdown, user } = props;
  const [isLoading, setIsLoading] = useState(false);
  const createAnalyticsEvent = useAnalyticsContext();

  const crepeClassName = classNames(
    'border border-gray-300 py-2 px-4 m-2 rounded-lg h-full min-h-48',
    isLoading && 'opacity-50 bg-opacity-10 bg-slate-500',
  );

  return (
    <div className="relative h-full flex">
      {isLoading && (
        <div className="absolute w-full h-full flex justify-center items-center opacity-60">
          <Spinner width={40} height={40} />
        </div>
      )}
      {user.permissions.includes('edit_pricing_flow') ? (
        <CrepeEditorWithAutosave
          className={crepeClassName}
          initialValue={
            opportunity.context ??
            getOpportunityContextTemplate({
              opportunity: opportunity as Opportunity,
            })
          }
          placeholder="Add opportunity context"
          templateForEditing={getOpportunityContextTemplate({
            opportunity: opportunity as Opportunity,
          })}
          editableMode="edit-only"
          onMarkdownUpdated={null}
          setMarkdown={setMarkdown}
          onBlur={async () => {
            createAnalyticsEvent({
              name: 'opportunity__edited_context',
              eventData: { opportunity_id: opportunity.id },
            });
            await api.post(`opportunities/${opportunity.id}/snapshot`, {});
          }}
          renderAdditionalActions={(crepe) => {
            return (
              <div>
                <button
                  className={classNames(
                    'border-gray-200 flex items-center justify-center gap-1 whitespace-nowrap rounded-lg border py-1 px-2 font-medium text-sm text-slate-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 absolute top-4 right-8',
                    isLoading && 'bg-gray-100',
                    !isLoading &&
                      ' bg-white hover:bg-gray-200 focus-visible:outline-fuchsia-900',
                  )}
                  onClick={async () => {
                    setIsLoading(true);
                    const markdown = crepe?.getMarkdown().trim();
                    const response = await api.post(
                      'opportunities/proofreadContext/' + opportunity.id,
                      {
                        markdown,
                      },
                    );
                    setIsLoading(false);
                    if (response.status !== 200) {
                      return;
                    } else {
                      const message = response.data;
                      if (crepe?.editor.status === EditorStatus.Created) {
                        crepe?.editor.action(replaceAll(message));
                      }
                    }
                  }}
                  disabled={isLoading}
                >
                  <DocumentMagnifyingGlassIcon className="h-4 w-4" />
                  <span>Proofread</span>
                </button>
              </div>
            );
          }}
        />
      ) : (
        <CrepeDisplayReadonly
          className={crepeClassName}
          value={
            opportunity.context ??
            getOpportunityContextTemplate({
              opportunity: opportunity as Opportunity,
            })
          }
          placeholder="Add opportunity context"
        />
      )}
    </div>
  );
}
export default OpportunityContextEditor;
