import { User } from 'src/types';
import { Branded } from 'src/typeUtils';
import { z } from 'zod';
import {
  DealopsOpportunityData,
  PricingCurve,
  PricingFlowCommon,
  PricingFlowType,
  ProductCommon,
} from '../types';
import {
  Count,
  CurrencyValueFlat,
  CurrencyValueTiered,
} from '../types_common/price';

/**
 *
 * NOTE: YOU CAN ONLY ADD TO THESE TYPES, not remove.
 * And, you must update the types in 2024_10_15__initial_approval_rules.ts
 *
 */

type BillingFrequency =
  | 'annual_upfront'
  | 'semi_annual'
  | 'quarterly'
  | 'other';
type PaymentTerms = 'net_30' | 'net_60' | 'net_90' | 'other';

const HamsterOpportunityDataSchema = z.object({
  // YYYY-MM-DD
  Service_Start_Date__c: z.string().nullable(),
  Service_End_Date_Formula__c: z.string().nullable(),
  Owner: z.string().nullable(),
  Segment: z
    .enum(['Enterprise', 'Mid-Market', 'Self-Serve', 'Co-Sell'])
    .nullable(),
  Type: z.enum(['New to Harvey', 'Renewal', 'Expansion']).nullable(),
  Country: z.string().nullable(),
  Number_of_lawyers: z.number().nullable(),
  ARR: z.number().nullable(),
  TCV: z.number().nullable(),
  Net_New_ACV: z.number().nullable(),
  AccountId: z.string(),
  Baseline_ACV__c: z.number(),
  Baseline_ARR__c: z.number(),
});

export type HamsterOpportunityData = z.infer<
  typeof HamsterOpportunityDataSchema
>;

export const HamsterStepSnapshotNameSchema = z.enum([
  'Head of Mid-Market',
  'VP of Sales',
  'Deal Desk',
  'Deal Ops',
  'Manager',
]);
export type HamsterStepSnapshotName = z.infer<
  typeof HamsterStepSnapshotNameSchema
>;

export type StepId = Branded<string, 'stepId'>;

export type HamsterPricingInformation = {
  listPrice:
    | CurrencyValueFlat
    | CurrencyValueTiered<CurrencyValueFlat, Count>
    | null;
  defaultVolume: number | null;
  approvalThresholds: Record<
    StepId,
    | CurrencyValueFlat
    | CurrencyValueTiered<CurrencyValueFlat, Count>
    // if "true", approval is always required if this product is added
    | true
  >;
};
export type HamsterPricingCurve = PricingCurve & {
  pricingInformation: HamsterPricingInformation;
};
export interface HamsterAdditionalData {
  startDate: string | null; // YYYY-MM-DD
  subscriptionTerms: number;
  billingFrequency: BillingFrequency;
  // if billingFrequency === 'other', the rep can enter a free form description
  // of what they're doing
  billingFrequency_Other: string;
  paymentTerms: PaymentTerms;
  // if paymentTerms === 'other', the rep can enter a free form description
  // of what they're doing
  paymentTerms_Other: string;
  nonCommercialTerms: string;
  priceProtection: boolean;
  priceProtectionCap: number;
}
export type HamsterCV = CurrencyValueFlat;
export type HamsterQuotePrice =
  | HamsterCV
  | CurrencyValueTiered<HamsterCV, Count>;
export interface HamsterProduct extends ProductCommon {
  quotePrice: HamsterQuotePrice | null;
  // Note that:
  // - the volume numbers here are increases to the minimum seat count, not the
  //   raw number of seats provisioned at that month. To get that number, add the
  //   product.volume, which is the base seat count
  // - These are not volume forcasts, these are the actual number of seats
  //   they're selling!
  rampedVolumeIncremental: (Count | null)[];
  customName?: string;
}

export const HAMSTER_CATEGORY_NAME = [
  'New customers',
  'Existing customers',
] as const;
export type HamsterCategoryName = (typeof HAMSTER_CATEGORY_NAME)[number];
export const HAMSTER_SUBCATEGORY_NAME = [
  'Core',
  'Add-ons',
  'Other',
  'License and usage-based revenue',
  'Professional services revenue',
] as const;
export type HamsterSubcategoryName = (typeof HAMSTER_SUBCATEGORY_NAME)[number];
export interface HamsterProductPrice {
  id: string;
  name: string;
  // only one product in an exclusive group can be selected at once
  exclusiveGroup: string | null;
  // number is the fixed volume value, null means the rep can edit it
  fixedVolume: number | null;
  isFreeformProduct: boolean;
  isSelectedByDefault: boolean;
  revenueFrequency: 'one_time' | 'monthly' | 'annual';
  isRebate: boolean;
  currentPricingCurve: HamsterPricingCurve;
  subcategoryMemberships: {
    subcategory: {
      name: HamsterSubcategoryName;
      category: { name: HamsterCategoryName };
    };
  }[];
  displayOrder: number;
  SalesforceProduct__fixedSelf__noParent: string | null;
}
export interface HamsterProductPrices {
  [productId: string]: HamsterProductPrice | undefined;
}
export interface HamsterPricingSheet {
  productInfo: HamsterProductPrices;
}

export type HamsterPricingFlow = Omit<PricingFlowCommon, 'products'> & {
  additionalData: HamsterAdditionalData;
  products: HamsterProduct[];
  pricingSheetData: HamsterPricingSheet;
} & (
    | {
        type: PricingFlowType.HAMSTER;
        opportunity: PricingFlowCommon['opportunity'] & {
          opportunityData: HamsterOpportunityData;
        };
      }
    | {
        type: PricingFlowType.HAMSTER_FOR_DEMO;
        opportunity: PricingFlowCommon['opportunity'] & {
          opportunityData: DealopsOpportunityData;
        };
      }
  );

/**
 * APPROVALS
 */

export type StepSnapshot = {
  id: string;
  stepId: string;
  name: HamsterStepSnapshotName;
  order: number;
  color: string; // hex code
};

type FieldsNeeded = {
  [fieldName: string]: {
    greatestStep: StepSnapshot;
    humanReadables: string[];
  };
};
type ProductsNeeded = {
  [productId: string]: {
    greatestStep: StepSnapshot;
    humanReadables: string[];
  };
};
export type ApprovalsDisplay = {
  fieldsNeeded: FieldsNeeded;
  productsNeeded: ProductsNeeded;
  stepSnapshots: StepSnapshot[];
};

type ApprovalRuleEvaluationResult = {
  id: string;
  required: boolean;
  humanReadable: string;
  relatedProducts: string[];
  relatedFields: string[];
};

interface ApprovalFlowStepWithRuleDetails {
  snapshot: StepSnapshot;
  users: User[]; // result of running getUsers(), or the saved output of that function
  rules: ApprovalRuleEvaluationResult[];
}

export type ApprovalFlowWithRuleDetails = {
  steps: ApprovalFlowStepWithRuleDetails[];
};
