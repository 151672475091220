import api from 'src/api';
import Button from 'src/components/Button';
import { PricingFlowOrSnapshotForNavigation } from './QuoteOptionsSection';

export default function DeletePricingFlowModal(props: {
  pricingFlow: PricingFlowOrSnapshotForNavigation;
  hideModal: () => void;
}) {
  const { pricingFlow, hideModal } = props;
  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="p-4 font-medium text-sm text-slate-600 space-y-2">
        <p>
          Deleting a quote that has been shared with a customer will permanently
          remove important deal history, including key details of the customer’s
          economic journey and negotiation context. This can impact your ability
          to reference past discussions and decisions in the future.
        </p>
        <p>
          If the quote was never shared with a customer, you can safely delete
          it.
        </p>
        <p>
          <strong>This action is permanent and cannot be undone.</strong>
        </p>
      </div>
      <div className="shrink-0 border-t border-100">
        <div className="flex flex-col justify-between p-4 gap-2 sm:gap-4 sm:flex-row-reverse">
          <Button
            color="red"
            onClick={async (e) => {
              e.stopPropagation();
              await api.delete(`pricingFlows/${pricingFlow.id}`, {});
              window.location.reload();
            }}
            label={'Delete'}
            className="flex-1"
          />
          <Button
            color="white"
            onClick={hideModal}
            label="Cancel"
            className="flex-1"
          />
        </div>
      </div>
    </div>
  );
}
