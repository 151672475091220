import { datadogRum } from '@datadog/browser-rum';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { isNil } from 'lodash';
import React, { Fragment, ReactNode, useMemo, useState } from 'react';
import Badge, { BadgeColor } from 'src/components/Badge';
import Button from 'src/components/Button';
import { useToast } from 'src/components/Toast';
import Tooltip from 'src/components/Tooltip';
import { classNames } from 'src/dashboard/App';
import { usePricingFlowContext } from '../../PricingFlow';
import TrashButton from '../../TrashButton';
import {
  Count,
  CurrencyValue,
  CurrencyValueFlat,
  CurrencyValueTiered,
  CurrencyValueType,
  Minimum,
  QuotePrice,
  Tier,
} from '../../types_common/price';
import { AlpacaSupportedCurrency } from '../alpaca_types';
import { formatCurrencyValue, roundQuotePrice } from '../alpaca_utils';
import {
  AlpacaQuotePriceLimit,
  QuotePriceEditable,
} from './AlpacaQuotePriceEditable';
import { VolumeEditable } from './AlpacaVolumeEditable';

interface PriceColumnConfig {
  label: string;
  badgeColor: BadgeColor | null;
  value: (
    tierMin: Count | CurrencyValueFlat,
    tierPrice: CurrencyValue,
  ) => CurrencyValue | null;
}

export type TierConfig = {
  tierName: string;
  // toggle columns on and off
  showMinimum: boolean;
  showMaximum: boolean;
  // TODO instead of booleans for showCost and showStickerPrice, model these as just additionalPrices
  showCost: boolean;
  showStickerPrice: boolean;
  additionalPrices: PriceColumnConfig[];
  showApprovalLevel: boolean;
  showBlendedPrice: boolean;
  approvalLevelColumnName?: string;
  quotePriceColumnName?: string;
  getApprovalBadge?: (
    tierMin: Count | CurrencyValueFlat,
    tierPrice: CurrencyValue,
  ) => React.ReactNode;
  // if the minimum is a type of count, override how it's described in the
  // headers and in the volume editable cells.
  countMinimumHeaderOverride?: string;
  countMinimumSuffixOverride?: string;
  countMaximumHeaderOverride?: string;
  getSummaryData?: (
    liveQuotePrice: CurrencyValueTiered,
  ) => { header: string; value: ReactNode }[];
  // Used when switching from a non-tiered price to a tiered price. When false,
  // switch to a tiered price that's the closest analogue to the previous
  // non-tiered price. When true, if the list price has tiers, overwrite the
  // quote price to the tiered list price.
  defaultToListPriceOnSwitch?: boolean;
};

const DEFAULT_TIER_CONFIG: TierConfig = {
  tierName: 'Tier',
  showMinimum: true,
  showMaximum: false,
  showCost: true,
  showStickerPrice: true,
  showApprovalLevel: false,
  showBlendedPrice: false,
  additionalPrices: [],
};

export function AlpacaQuotePriceEditableTieredDetails(props: {
  show: boolean;
  quotePrice: CurrencyValueTiered;
  updateQuotePrice: (quotePrice: CurrencyValueTiered) => void;
  onClose: () => void;
  stickerPrice: CurrencyValue | CurrencyValueTiered | null;
  cost: CurrencyValue | null;
  productName: string;
  quoteCurrency: AlpacaSupportedCurrency;
  validPriceTypes: CurrencyValueType[];
  validTierMinimumTypes: Minimum['type'][];
  limit?: AlpacaQuotePriceLimit | null;
  tierConfig?: Partial<TierConfig>;
  handleChangeQuotePriceType: (quotePriceType: QuotePrice['type']) => void;
}) {
  const {
    show,
    quotePrice,
    updateQuotePrice,
    onClose,
    stickerPrice,
    cost,
    limit,
    productName,
  } = props;
  if (quotePrice.type !== 'tiered') {
    throw new Error('quotePrice must be tiered');
  }

  const tierConfig: TierConfig = {
    ...DEFAULT_TIER_CONFIG,
    ...props.tierConfig,
  };

  return (
    <NonFullWidthSidebar
      isOpen={show}
      onClose={onClose}
      title={`Edit tiered pricing for ${productName}`}
    >
      <Editor
        quotePrice={quotePrice}
        updateQuotePrice={updateQuotePrice}
        onClose={onClose}
        stickerPrice={stickerPrice}
        cost={cost}
        quoteCurrency={props.quoteCurrency}
        validPriceTypes={props.validPriceTypes}
        validTierMinimumTypes={props.validTierMinimumTypes}
        tierConfig={tierConfig}
        limit={limit}
        handleChangeQuotePriceType={props.handleChangeQuotePriceType}
      />
    </NonFullWidthSidebar>
  );
}

export function NonFullWidthSidebar({
  isOpen,
  onClose,
  children,
  title,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
}) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-0 md:pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen sm:max-w-min">
                  <button
                    type="button"
                    className="absolute z-50 bg-white right-4 top-4 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-fuchsia-500 md:hidden"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close panel</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  <div className="flex h-full flex-col bg-white pt-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="text-lg md:text-xl mr-4 md:mr-0 font-medium leading-6 text-gray-900">
                        {title}
                      </Dialog.Title>
                    </div>
                    <div className="flex-1 overflow-y-auto">{children}</div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function Editor(props: {
  quotePrice: CurrencyValueTiered;
  updateQuotePrice: (quotePrice: CurrencyValueTiered) => void;
  onClose: () => void;
  stickerPrice: CurrencyValue | CurrencyValueTiered | null;
  cost: CurrencyValue | null;
  quoteCurrency: AlpacaSupportedCurrency;
  validPriceTypes: CurrencyValueType[];
  validTierMinimumTypes: Minimum['type'][];
  tierConfig: TierConfig;
  limit?: AlpacaQuotePriceLimit | null;
  handleChangeQuotePriceType: (quotePriceType: QuotePrice['type']) => void;
}) {
  const {
    stickerPrice,
    cost,
    limit,
    validTierMinimumTypes,
    handleChangeQuotePriceType,
  } = props;
  const { showToast } = useToast();
  const { editMode } = usePricingFlowContext();

  const [quotePrice, setQuotePrice] = useState<CurrencyValueTiered>(
    props.quotePrice,
  );

  function onCancel() {
    props.onClose();
  }

  function validateTiers() {
    if (validTierMinimumTypes.length === 0) {
      return true;
    } else {
      for (let i = 0; i < quotePrice.tiers.length; i++) {
        if (
          i !== 0 &&
          quotePrice.tiers[i - 1].minimum.value >=
            quotePrice.tiers[i].minimum.value
        ) {
          showToast({
            title: 'The tier volumes must be increasing',
            subtitle: '',
            type: 'error',
          });
          return false;
        }
      }
    }
    return true;
  }

  function onSave() {
    if (!validateTiers()) {
      return;
    }
    props.updateQuotePrice(quotePrice);
    props.onClose();
  }

  return (
    <div className="flex flex-col h-full">
      {/* Main content area with auto height and scrolling */}
      <div className="flex-1 overflow-y-auto py-4 sm:px-6 sm:py-4">
        <div className="rounded-none sm:rounded-xl border border-gray-200 bg-white overflow-x-auto">
          <table className="h-full ">
            <Header
              minimumType={quotePrice.minimumType}
              tierConfig={props.tierConfig}
            />
            <Table
              stickerPrice={stickerPrice}
              cost={cost}
              tiers={quotePrice.tiers}
              updateTiers={(tiers) => setQuotePrice({ ...quotePrice, tiers })}
              quoteCurrency={props.quoteCurrency}
              validPriceTypes={props.validPriceTypes}
              minimumType={quotePrice.minimumType}
              updateMinimumType={(newMinType) => {
                const newQuotePrice = {
                  ...quotePrice,
                  minimumType: newMinType,
                  tiers: quotePrice.tiers.map((tier) => {
                    const newMinium =
                      newMinType === CurrencyValueType.FLAT
                        ? {
                            type: newMinType,
                            value: tier.minimum.value,
                            currency: props.quoteCurrency,
                          }
                        : {
                            type: newMinType,
                            value: tier.minimum.value,
                          };
                    return {
                      ...tier,
                      minimum: newMinium,
                    };
                  }),
                };
                setQuotePrice(newQuotePrice);
              }}
              validTierMinimumTypes={validTierMinimumTypes}
              tierConfig={props.tierConfig}
              limit={limit}
            />
          </table>
        </div>
        {props.tierConfig.getSummaryData && (
          <SummaryDataTable
            summaryData={props.tierConfig.getSummaryData(quotePrice)}
          />
        )}
      </div>
      {editMode && (
        <Button
          color="noBg-red"
          label="Remove all tiers"
          icon="trash"
          onClick={() => handleChangeQuotePriceType('tiered')}
          className="mb-2"
        />
      )}
      <Savebar onCancel={onCancel} onSave={onSave} />
    </div>
  );
}

const HEADINGS = (props: {
  minimumType: Minimum['type'];
  tierConfig: TierConfig;
}) => {
  return useMemo(() => {
    const {
      tierName,
      showMinimum,
      showMaximum,
      showCost,
      showStickerPrice,
      showApprovalLevel,
      approvalLevelColumnName,
      quotePriceColumnName,
      countMinimumHeaderOverride,
      countMaximumHeaderOverride,
      additionalPrices,
    } = props.tierConfig;

    let headings = [tierName];
    if (showMinimum) {
      headings.push(
        props.minimumType === CurrencyValueType.FLAT
          ? 'Greater than volume'
          : (countMinimumHeaderOverride ?? 'Greater than # txns'),
      );
    }
    if (showMaximum) {
      headings.push(
        props.minimumType === CurrencyValueType.FLAT
          ? 'To volume'
          : (countMaximumHeaderOverride ?? 'To # txns'),
      );
    }
    headings.push(quotePriceColumnName ?? 'Quote price');
    if (showStickerPrice) {
      headings.push('Sticker price');
    }
    if (showCost) {
      headings.push('Cost');
    }
    for (const header of additionalPrices.map((p) => p.label)) {
      headings.push(header);
    }
    if (showApprovalLevel) {
      headings.push(approvalLevelColumnName ?? 'Approval required');
    }
    // for the delete icon
    headings.push('');
    return headings;
  }, [props.minimumType, props.tierConfig]); // Dependencies array
};

type HeaderProps = {
  minimumType: Minimum['type'];
  tierConfig: TierConfig;
};
function Header(props: HeaderProps) {
  const { tierConfig, minimumType } = props;
  return (
    <thead>
      <tr>
        {HEADINGS({ minimumType, tierConfig }).map((heading, i) => (
          <th
            key={heading ?? i}
            scope="col"
            className={classNames(
              i === 0
                ? 'rounded-none sm:rounded-tl-xl p-2 sm:px-6 sm:py-3.5'
                : '',
              i === HEADINGS({ minimumType, tierConfig }).length - 1
                ? 'rounded-none sm:rounded-tr-xl'
                : 'p-2 sm:px-6 sm:py-3.5',
              'sticky top-0 z-10 whitespace-nowrap border-b bg-gray-50 text-left text-xs sm:text-sm font-medium text-gray-700 backdrop-blur backdrop-filter',
            )}
          >
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
}

function Table(props: {
  stickerPrice: CurrencyValue | CurrencyValueTiered | null;
  cost: CurrencyValue | null;
  tiers: Tier[];
  updateTiers: (tiers: Tier[]) => void;
  quoteCurrency: AlpacaSupportedCurrency;
  validPriceTypes: CurrencyValueType[];
  minimumType: Minimum['type'];
  updateMinimumType: (minimumType: Minimum['type']) => void;
  validTierMinimumTypes: Minimum['type'][];
  tierConfig: TierConfig;
  limit?: AlpacaQuotePriceLimit | null;
}) {
  const {
    stickerPrice,
    cost,
    tiers,
    updateTiers,
    minimumType,
    updateMinimumType,
    validTierMinimumTypes,
    limit,
    tierConfig,
  } = props;
  const { showMinimum } = tierConfig;
  const { editMode } = usePricingFlowContext();

  function handleDelete(index: number) {
    return () => {
      updateTiers(tiers.filter((_, i) => i !== index));
    };
  }

  function updateTier(index: number) {
    return (tier: Tier) => {
      updateTiers(tiers.map((t, i) => (i === index ? tier : t)));
    };
  }

  function duplicateLastTier() {
    const lastTier = tiers[tiers.length - 1];
    updateTiers([...tiers, lastTier]);
  }

  const minimumTypeSwitchOptions = validTierMinimumTypes
    .filter((type) => type !== minimumType)
    .map((type) => {
      switch (type) {
        case 'count': {
          return {
            label: 'txn count minimums',
            type,
          };
        }
        case CurrencyValueType.FLAT: {
          return {
            label: 'volume minimums',
            type,
          };
        }
        default: {
          datadogRum.addError(new Error(`Invalid minimum type: ${type}`));
          return null;
        }
      }
    })
    .filter((x) => x != null) as { label: string; type: Minimum['type'] }[];

  return (
    <>
      <tbody>
        {tiers.map((tier, index) => {
          return (
            <TierRow
              key={index}
              tier={tier}
              index={index}
              nextTier={tiers[index + 1]}
              onDelete={handleDelete(index)}
              stickerPrice={stickerPrice}
              cost={cost}
              updateTier={updateTier(index)}
              quoteCurrency={props.quoteCurrency}
              validPriceTypes={props.validPriceTypes}
              validTierMinimumTypes={validTierMinimumTypes}
              tierConfig={tierConfig}
              limit={limit}
            />
          );
        })}
      </tbody>
      <tfoot className={classNames(!editMode && 'hidden')}>
        <tr>
          <td
            colSpan={HEADINGS({ minimumType, tierConfig }).length}
            className="bg-gray-50 p-2 sm:px-6 sm:py-2 rounded-b-xl "
          >
            <div className="flex justify-start">
              <Button
                color="white"
                onClick={duplicateLastTier}
                disabled={!editMode}
                label="Add new tier"
                className="flex-1 sm:flex-none"
              />
              {showMinimum &&
                // @TODO(optional) switch this to a dropdown later
                minimumTypeSwitchOptions.map((option) => {
                  return (
                    <button
                      key={option.type}
                      className="inline-flex items-center rounded-lg border border-gray-300 bg-white px-3.5 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700 disabled:cursor-not-allowed disabled:opacity-50 ml-6"
                      onClick={() => {
                        updateMinimumType(option.type);
                      }}
                      disabled={!editMode}
                    >
                      {`Switch to ${option.label}`}
                    </button>
                  );
                })}
            </div>
          </td>
        </tr>
      </tfoot>
    </>
  );
}

function TierRow(props: {
  tier: Tier;
  nextTier: Tier | undefined;
  index: number;
  onDelete: () => void;
  stickerPrice: CurrencyValue | CurrencyValueTiered | null;
  cost: CurrencyValue | null;
  updateTier: (tier: Tier) => void;
  quoteCurrency: AlpacaSupportedCurrency;
  validPriceTypes: CurrencyValueType[];
  validTierMinimumTypes: Minimum['type'][];
  tierConfig: TierConfig;
  limit?: AlpacaQuotePriceLimit | null;
}) {
  const {
    tier,
    nextTier,
    index,
    stickerPrice,
    cost,
    limit,
    updateTier,
    quoteCurrency,
    tierConfig,
  } = props;
  const { editMode } = usePricingFlowContext();
  const stickerPriceCell = (() => {
    if (!tierConfig.showStickerPrice) {
      return null;
    }
    if (stickerPrice?.type !== 'tiered') {
      return (
        <td className="whitespace-nowrap px-6 py-4 text-xs font-medium">
          {isNil(stickerPrice) ? (
            <Tooltip
              as="button"
              location="TOP"
              text="Coming soon"
              disabled={true}
              onClick={() => {}}
            >
              <Badge color="green">{formatCurrencyValue(stickerPrice)}</Badge>
            </Tooltip>
          ) : (
            <button
              className=""
              title="Set quote price to sticker price"
              onClick={() => {
                updateTier({
                  ...tier,
                  currencyValue: roundQuotePrice(stickerPrice),
                });
              }}
              disabled={!editMode}
            >
              <Badge color="green">{formatCurrencyValue(stickerPrice)}</Badge>
            </button>
          )}
        </td>
      );
    }
    datadogRum.addError(
      new Error(
        `Asked to show sticker price in tier details but this is not implemented for tiered sticker prices yet!`,
      ),
    );
    return null;
  })();
  return (
    <tr>
      <td className="p-2 sm:px-6 sm:py-4 whitespace-nowrap text-xs sm:text-sm font-medium text-gray-900 min-w-[120px]">
        {tierConfig.tierName} {index + 1}
      </td>

      {/* Greater than volume */}
      {tierConfig.showMinimum &&
        (index === 0 ? (
          <td className="overflow-show h-full w-full p-0 align-middle text-xs sm:text-sm text-gray-900 pl-2 sm:pl-4">
            {' '}
            {formatCurrencyValue(tier.minimum)}
          </td>
        ) : (
          <td className="overflow-show h-full w-full p-0 align-top">
            <VolumeEditable
              volume={tier.minimum.value}
              updateVolume={(volume) => {
                updateTier({
                  ...tier,
                  minimum: { ...tier.minimum, value: volume },
                });
              }}
              transactionCount={tier.minimum.value}
              updateTransactionCount={(txnCount) => {
                updateTier({
                  ...tier,
                  minimum: { ...tier.minimum, value: txnCount ?? 0 },
                });
              }}
              quotePrice={stickerPrice ?? cost}
              quoteCurrency={quoteCurrency}
              cost={cost}
              inputTypesOverride={
                tier.minimum.type === 'count' ? 'count' : 'volume'
              }
              customCountSuffix={tierConfig.countMinimumSuffixOverride}
            />
          </td>
        ))}
      {/* Less than volume */}
      {tierConfig.showMaximum && (
        <td className="overflow-show h-full w-full p-0 align-middle text-xs sm:text-sm text-gray-900 pl-2 sm:pl-4">
          {' '}
          {isNil(nextTier)
            ? '∞'
            : formatCurrencyValue({
                ...nextTier.minimum,
                value: nextTier.minimum.value - 1,
              })}
        </td>
      )}

      {/* Quote price */}
      {/* todo: this is a hack for small screens min-w-[120px] sm:min-w-0 */}
      <td className="overflow-show h-full w-full p-0 align-top min-w-[120px] sm:min-w-0">
        <QuotePriceEditable
          quotePrice={tier.currencyValue}
          updateQuotePrice={(quotePrice) =>
            // @ts-ignore @TODO(fay) DOUBLE CHECK THIS!!!
            updateTier({ ...tier, currencyValue: quotePrice ?? stickerPrice })
          }
          // You cannot change the price type for tiered pricing
          validPriceTypes={props.validPriceTypes}
          tierable={false}
          quoteCurrency={props.quoteCurrency} // We don't actually need this value here
          stickerPrice={stickerPrice}
          cost={cost}
          limit={limit}
          productName={'we do not need this value'}
          validTierMinimumTypes={props.validTierMinimumTypes}
        />
      </td>

      {/* Sticker price */}
      {tierConfig.showStickerPrice && stickerPriceCell}

      {/* Cost */}
      {tierConfig.showCost && (
        <td className="whitespace-nowrap px-6 py-4 text-xs font-medium text-orange-700">
          <button
            className=""
            title="Set quote price to cost"
            disabled={isNil(cost) || !editMode}
            onClick={() => {
              if (isNil(cost)) return;
              updateTier({
                ...tier,
                currencyValue: roundQuotePrice(cost),
              });
            }}
          >
            <Badge color="orange">{formatCurrencyValue(cost)}</Badge>
          </button>
        </td>
      )}
      {/* Additional prices */}
      {tierConfig.additionalPrices.length > 0 &&
        tierConfig.additionalPrices.map((columnConfig) => {
          const colValue = columnConfig.value(tier.minimum, tier.currencyValue);
          return (
            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 font-medium">
              <button
                className=""
                title={`Set quote price to ${columnConfig.label}`}
                disabled={isNil(colValue) || !editMode}
                onClick={() => {
                  if (isNil(colValue)) return;
                  updateTier({
                    ...tier,
                    currencyValue: roundQuotePrice(colValue),
                  });
                }}
              >
                {columnConfig.badgeColor ? (
                  <Badge color={columnConfig.badgeColor} className="text-xs">
                    {formatCurrencyValue(colValue)}
                  </Badge>
                ) : (
                  formatCurrencyValue(colValue)
                )}
              </button>
            </td>
          );
        })}
      {/* Approval Level */}
      {tierConfig.showApprovalLevel && (
        <td className="whitespace-nowrap px-6 py-4 text-xs font-medium text-orange-700">
          {tierConfig.getApprovalBadge?.(tier.minimum, tier.currencyValue) ?? (
            <Badge color="gray">N/A</Badge>
          )}
        </td>
      )}

      {/* Delete */}
      <td className={classNames('p-2 sm:px-4', !editMode && 'hidden')}>
        {index === 0 ? null : (
          <TrashButton editMode={editMode} handleDelete={props.onDelete} />
        )}
      </td>
    </tr>
  );
}

function SummaryDataTable({
  summaryData,
}: {
  summaryData: { header: string; value: ReactNode }[];
}) {
  return (
    <div className="mt-2 flex flex-row justify-end w-full">
      <div className="border border-gray-200 rounded-none sm:rounded-xl w-full sm:w-fit">
        <table className="w-full">
          <thead>
            <tr>
              {summaryData.map((d, i) => {
                return (
                  <th
                    className={classNames(
                      'sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 sm:px-6 sm:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter whitespace-nowrap',
                      i === 0 && 'rounded-tl-xl',
                      i === summaryData.length - 1 && 'rounded-tr-xl',
                    )}
                  >
                    {d.header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              {summaryData.map((d) => (
                <td className="whitespace-nowrap p-2 sm:px-6 sm:py-4 text-sm font-medium text-gray-500">
                  {d.value}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
function Savebar({
  onCancel,
  onSave,
}: {
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  onSave: React.MouseEventHandler<HTMLButtonElement>;
}) {
  const { editMode } = usePricingFlowContext();
  return (
    <div className="sticky bottom-0 bg-white border-t border-100">
      <div className="flex flex-row justify-between p-4 gap-4">
        <Button
          onClick={onCancel}
          label={editMode ? 'Cancel' : 'Back'}
          color="white"
          className="flex-1"
        />
        {editMode && (
          <Button onClick={onSave} label="Save" className="flex-1" />
        )}
      </div>
    </div>
  );
}
