import { datadogRum } from '@datadog/browser-rum';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import _, { isNil } from 'lodash';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'src/components/Button';
import { RadioBoxFields } from 'src/components/Fields';
import { useModal } from 'src/components/Modal';
import { classNames } from 'src/dashboard/App';
import { OpportunityDetailPageConfig } from 'src/dashboard/Opportunity/OpportunityDetailPage';
import { PricingFlowGroupDisplay } from 'src/dashboard/PricingFlowGroup/PricingFlowGroup';
import { User } from 'src/types';
import { unreachable } from 'src/typeUtils';
import { getNameForPricingFlow } from 'src/utils';
import { Opportunity, PricingFlowGroup } from '../../Opportunity/types';
import {
  doesApprovalStatusRequireAnyApprovals,
  loadPricingFlowApprovalStatuses,
} from '../PricingFlowList';
import { PricingFlowOrSnapshotForNavigation } from '../QuoteOptionsSection';
import { OpportunityCommon } from '../types';
import AskForApprovalModal, {
  getAskForApprovalModalTitleAndSubtitle,
} from './ApprovalModal';

interface PricingFlowRowProps {
  opportunity: OpportunityCommon;
  pricingFlow: PricingFlowOrSnapshotForNavigation;
  isChecked: boolean;
  setIsChecked: (newIsChecked: boolean) => void;
}
export function PricingFlowRow(props: PricingFlowRowProps) {
  const { isChecked, setIsChecked, pricingFlow, opportunity } = props;
  return (
    <label
      key={pricingFlow.id}
      htmlFor={pricingFlow.id}
      className={classNames(
        'flex justify-between items-center border rounded-lg p-2 mt-2',
        isChecked ? 'border-fuchsia-900' : 'border-gray-200',
      )}
    >
      {/* left side */}
      <div className="flex items-center gap-x-4">
        <input
          className="rounded-sm border-gray-300 text-fuchsia-900 focus:ring-fuchsia-900 focus:border-fuchsia-900 pl-1"
          id={pricingFlow.id}
          type="checkbox"
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />
        <div className="flex flex-col">
          <span className="font-medium text-sm text-gray-950">
            {getNameForPricingFlow(pricingFlow, opportunity.pricingFlows)}
          </span>
          <span className="text-xs text-gray-600 italic">
            Updated {dayjs(pricingFlow.updatedAt).fromNow()}
          </span>
        </div>
      </div>
      {/* right side */}
      <div className="flex items-center pr-2">
        <Link
          to={`/app/opportunity/${opportunity.sfdcOpportunityId}/pricingflow/${pricingFlow.id}`}
          target="_blank"
          relative="path"
          className="text-sm font-semibold text-fuchsia-900"
        >
          <ArrowTopRightOnSquareIcon
            className="inline-block h-5 w-5"
            aria-hidden="true"
          />
        </Link>
      </div>
    </label>
  );
}

function Footer(props: { onNext: () => void }) {
  const { hideModal } = useModal();
  return (
    <div className="mt-4 shrink-0 border-t border-100">
      <div className="flex flex-col sm:flex-row justify-between p-4 gap-2 sm:gap-4 sm:flex-row-reverse">
        <Button
          color="primary"
          onClick={props.onNext}
          className="flex-1"
          label="Next"
        />
        <Button
          color="white"
          onClick={() => hideModal()}
          className="flex-1"
          label="Cancel"
        />
      </div>
    </div>
  );
}

function ModalContentContainer(props: {
  content: React.ReactNode;
  onNext: () => void;
}) {
  const { content, onNext } = props;
  return (
    <div className="min-h-[540px] h-[75vh] flex-1 flex flex-col overflow-hidden">
      {/* Scrollable Options Section */}
      <div className="overflow-y-auto flex-1 overflow-hidden p-4 flex-1">
        <div className="space-y-2">{content}</div>
      </div>

      <Footer onNext={onNext} />
    </div>
  );
}

export function CreateGroupDialog(props: {
  newPricingFlow: PricingFlowOrSnapshotForNavigation;
  opportunity: Opportunity;
  user: User;
  pageConfig: OpportunityDetailPageConfig;
}) {
  const { newPricingFlow, opportunity, user, pageConfig } = props;
  const [selectedPricingFlows, setSelectedPricingFlows] = useState<string[]>([
    newPricingFlow.id,
  ]);
  const { showModal, hideModal } = useModal();
  const content = (
    <>
      {opportunity.pricingFlows.map((p) => {
        return (
          <PricingFlowRow
            key={p.id}
            opportunity={opportunity}
            pricingFlow={p}
            isChecked={
              newPricingFlow.id === p.id || selectedPricingFlows.includes(p.id)
            }
            setIsChecked={(newIsChecked) => {
              if (newPricingFlow.id === p.id) return;
              if (newIsChecked) {
                setSelectedPricingFlows([...selectedPricingFlows, p.id]);
              } else {
                setSelectedPricingFlows(
                  selectedPricingFlows.filter((id) => id !== p.id),
                );
              }
            }}
          />
        );
      })}
    </>
  );
  async function submitSelectedQuotes() {
    const pricingFlowsToSubmit = selectedPricingFlows
      .map((id) => opportunity.pricingFlows.find((p) => p.id === id))
      .filter((p) => p !== undefined);
    const approvalStatuses = await loadPricingFlowApprovalStatuses(
      pricingFlowsToSubmit,
      'pricingFlow',
    );
    const requiresAnyApprovals = Object.values(approvalStatuses).some(
      (approvalStatus) => doesApprovalStatusRequireAnyApprovals(approvalStatus),
    );
    showModal({
      newStyle: true,
      ...getAskForApprovalModalTitleAndSubtitle(requiresAnyApprovals),
      children: (
        <AskForApprovalModal
          hideModal={hideModal}
          pricingFlows={pricingFlowsToSubmit}
          user={user}
          opportunity={opportunity}
          pageConfig={pageConfig}
          requiresAnyApprovals={requiresAnyApprovals}
        />
      ),
    });
  }
  return (
    <ModalContentContainer content={content} onNext={submitSelectedQuotes} />
  );
}
type SubmitForApprovalActionOptions =
  | 'only_this_quote' // submit only this quote, creating a group if necessary
  | 'submit_with_group' // submit this quote with its group, only available if it's in an active group that contains other pricing flows
  | 'add_to_group_and_submit' // add to group and submit, only available if there's an active group that does not contain this pricing flow
  | 'create_new_group'; // submit a custom group of pricing flows for approval, only available if one of the other options does not cover the possible group configurations

function getValidActionOptions({
  activeGroup,
  opportunity,
  pricingFlow,
}: {
  activeGroup: PricingFlowGroup;
  opportunity: Opportunity;
  pricingFlow: PricingFlowOrSnapshotForNavigation;
}): SubmitForApprovalActionOptions[] {
  const validActionOptions: SubmitForApprovalActionOptions[] = [
    // you can always submit the quote on its own
    'only_this_quote',
  ];
  if (
    // there is no active group, and there are multiple pricing flows on the
    // opp we might want to create a group with
    (isNil(activeGroup) && opportunity.pricingFlows.length > 1) ||
    // there is an active group,
    (!isNil(activeGroup) &&
      // and there are other pricing flows outside of the group we might want
      // to create a group with
      (opportunity.pricingFlows.some(
        (oppPf) =>
          oppPf.id !== pricingFlow.id &&
          !activeGroup.pricingFlowOnGroup.some(
            (pfog) => pfog.pricingFlow.id === oppPf.id,
          ),
      ) ||
        // or there are multiple pricing flows in the group we might want to
        // exclude from the approval request
        activeGroup.pricingFlowOnGroup.filter(
          (pfog) => pfog.pricingFlowId !== pricingFlow.id,
        ).length > 1))
  ) {
    validActionOptions.push('create_new_group');
  }
  if (
    !isNil(activeGroup) &&
    // active group contains pricing flow
    activeGroup.pricingFlowOnGroup.some(
      (pfog) => pfog.pricingFlowId === pricingFlow.id,
    ) &&
    // there is more than one pricing flow on the group - otherwise, just use
    // the "only_this_quote", which will create a group if necessary
    activeGroup.pricingFlowOnGroup.length > 1
  ) {
    validActionOptions.push('submit_with_group');
  }
  if (
    !isNil(activeGroup) &&
    // active group does not contain pricing flow
    !activeGroup.pricingFlowOnGroup.some(
      (pfog) => pfog.pricingFlowId === pricingFlow.id,
    )
  ) {
    validActionOptions.push('add_to_group_and_submit');
  }
  return validActionOptions;
}

interface ApprovalOptionsModalProps {
  pricingFlow: PricingFlowOrSnapshotForNavigation;
  opportunity: Opportunity;
  user: User;
  pageConfig: OpportunityDetailPageConfig;
  activeGroups: PricingFlowGroup[];
}
export default function ApprovalOptionsModal(props: ApprovalOptionsModalProps) {
  const { opportunity, pricingFlow, user, pageConfig, activeGroups } = props;
  const activeGroupsWithThisQuote = activeGroups.filter((group) => {
    return group.pricingFlowOnGroup.some(
      (p) => p.pricingFlowId === props.pricingFlow.id,
    );
  });
  const activeGroup = activeGroupsWithThisQuote[0] ?? activeGroups[0];
  const validActionOptions = getValidActionOptions({
    activeGroup,
    opportunity,
    pricingFlow,
  });
  const [selectedAction, setSelectedAction] =
    useState<SubmitForApprovalActionOptions>(_.last(validActionOptions)!);

  const { showModal, hideModal } = useModal();

  if (activeGroups.length === 0) {
    if (opportunity.pricingFlows.length === 1) {
      datadogRum.addError(
        `Showing the approval options modal when there is no group and only 1 pricing flow. We should have bypassed this modal and went straight to the context editing step.`,
      );
    }
    return (
      <CreateGroupDialog
        newPricingFlow={pricingFlow}
        opportunity={opportunity}
        user={user}
        pageConfig={pageConfig}
      />
    );
  }
  if (isNil(activeGroup)) {
    return null;
  }
  const renderTitle = (title: string) => {
    return <span className="text-sm font-medium text-gray-800">{title}</span>;
  };
  const content = (
    <RadioBoxFields<SubmitForApprovalActionOptions>
      checked={selectedAction}
      name={'submit_for_approval_action'}
      onSelectOption={(action) => {
        setSelectedAction(action);
      }}
      radioBoxConfigs={[
        {
          title: renderTitle('Submit this quote only'),
          value: 'only_this_quote' as SubmitForApprovalActionOptions,
          description: null,
          disabled: false,
        },
        {
          title: renderTitle('Submit this quote with its group'),
          value: 'submit_with_group' as SubmitForApprovalActionOptions,
          description: (
            <PricingFlowGroupDisplay
              group={activeGroup}
              user={user}
              shouldShowQuotes={true}
              pageConfig={pageConfig}
              isApprovalModalGroupView={true}
              showOverflowMenu={false}
            />
          ),
          disabled: false,
        },
        {
          title: renderTitle('Submit this quote with an existing group'),
          value: 'add_to_group_and_submit' as SubmitForApprovalActionOptions,
          description: (
            <PricingFlowGroupDisplay
              group={activeGroup}
              user={user}
              shouldShowQuotes={true}
              pageConfig={pageConfig}
              isApprovalModalGroupView={true}
              showOverflowMenu={false}
            />
          ),
          disabled: false,
        },
        {
          title: renderTitle('Submit a different group of quotes'),
          value: 'create_new_group' as SubmitForApprovalActionOptions,
          description: null,
          disabled: false,
        },
      ].filter((config) => validActionOptions.includes(config.value))}
    />
  );
  return (
    <ModalContentContainer
      content={content}
      onNext={async () => {
        if (selectedAction === 'create_new_group') {
          return showModal({
            newStyle: true,
            title: 'Select quotes to include in approval request',
            subtitle:
              'Save time by requesting approval for multiple quotes at once',
            children: (
              <CreateGroupDialog
                newPricingFlow={pricingFlow}
                opportunity={opportunity}
                user={user}
                pageConfig={pageConfig}
              />
            ),
          });
        } else {
          const groupPricingFlows = activeGroup.pricingFlowOnGroup.map(
            (pfog) => pfog.pricingFlow,
          );
          const { pricingFlows, group } = (() => {
            switch (selectedAction) {
              case 'only_this_quote':
                return {
                  pricingFlows: [pricingFlow],
                  group: _.first(activeGroupsWithThisQuote),
                };
              case 'submit_with_group':
              case 'add_to_group_and_submit':
                return {
                  pricingFlows: groupPricingFlows.some(
                    (pf) => pf.id === pricingFlow.id,
                  )
                    ? groupPricingFlows
                    : [pricingFlow, ...groupPricingFlows],
                  group: activeGroup,
                };
              default:
                unreachable(selectedAction);
            }
          })();
          const approvalStatuses = await loadPricingFlowApprovalStatuses(
            [pricingFlow],
            'pricingFlow',
          );
          const requiresAnyApprovals = Object.values(approvalStatuses).some(
            (approvalStatus) =>
              doesApprovalStatusRequireAnyApprovals(approvalStatus),
          );
          return showModal({
            newStyle: true,
            ...getAskForApprovalModalTitleAndSubtitle(requiresAnyApprovals),
            children: (
              <AskForApprovalModal
                hideModal={hideModal}
                pricingFlows={pricingFlows}
                group={group}
                user={user}
                opportunity={opportunity}
                pageConfig={pageConfig}
                requiresAnyApprovals={requiresAnyApprovals}
              />
            ),
          });
        }
      }}
    />
  );
}
