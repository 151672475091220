import { datadogRum } from '@datadog/browser-rum';
import {
  BellIcon,
  ChatBubbleBottomCenterIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import _, { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import api from 'src/api';
import Button from 'src/components/Button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/Dialog';
import { InlineSpinner } from 'src/components/Loading';
import { useToast } from 'src/components/Toast';
import { unreachable } from 'src/typeUtils';
import {
  ApprovalStatusForPricingFlow,
  UserWithStatus,
} from './PricingFlowList';

type ApprovalRequest = ApprovalStatusForPricingFlow['currentApprovalRequest'];

interface NudgeNotification {
  id: string;
  deliveredAt: string;
  type: 'NUDGE' | 'REQUEST_APPROVAL';
}

function NudgeModal(props: {
  currentApprovalRequest: ApprovalRequest;
  userToNotify: UserWithStatus;
  setOpen: (open: boolean) => void;
}) {
  const { currentApprovalRequest, userToNotify, setOpen } = props;
  // status of the request to send a new nudge
  const [status, setStatus] = useState<'idle' | 'loading'>('idle');
  const { showToast } = useToast();
  // null means loading past nudges
  const [notifications, setNotifications] = useState<
    NudgeNotification[] | null
  >(null);

  useEffect(() => {
    (async () => {
      try {
        const { notifications } = (
          await api.get(
            `approvals/requests/${currentApprovalRequest?.id}/nudges/${userToNotify.id}`,
          )
        ).data;
        setNotifications(notifications);
      } catch (error) {
        datadogRum.addError(error);
        setNotifications([]);
      }
    })();
  }, []);

  if (isNil(notifications)) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <InlineSpinner />
      </div>
    );
  }
  if (notifications.length === 0) {
    datadogRum.addError(
      `Unexpectedly found 0 prior notifications for ${currentApprovalRequest?.id} ${userToNotify.id}`,
    );
  }

  const mostRecentNudge = dayjs(_.max(notifications.map((n) => n.deliveredAt)));
  const now = dayjs();
  const hasNudgedRecently = mostRecentNudge.isAfter(now.subtract(6, 'hour'));
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Send a reminder</DialogTitle>
        <DialogDescription>
          Previous notifications sent:
          {notifications.length > 0 && (
            <div className="items-left">
              {/* Notification list */}
              <div className="flex flex-col mt-4 gap-y-1 max-h-80 overflow-y-auto">
                {notifications
                  .sort((a, b) => {
                    return (
                      new Date(a.deliveredAt).getTime() -
                      new Date(b.deliveredAt).getTime()
                    );
                  })
                  .map((nudge) => {
                    const actioned = (() => {
                      switch (nudge.type) {
                        case 'NUDGE':
                          return 'nudged';
                        case 'REQUEST_APPROVAL':
                          return 'asked for approval';
                        default:
                          unreachable(nudge.type);
                      }
                    })();
                    return (
                      <div
                        key={nudge.id}
                        className="flex items-center text-gray-600 gap-x-2 px-2 text-sm"
                      >
                        <ChatBubbleBottomCenterIcon className="h-3 w-3" />
                        {userToNotify.name} was {actioned}{' '}
                        {dayjs(nudge.deliveredAt).fromNow()}
                      </div>
                    );
                  })}
              </div>
              {/* Maybe warning about nudging too often */}
              {hasNudgedRecently && (
                <div className="rounded-md bg-orange-50 p-4 mt-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <InformationCircleIcon
                        className="h-5 w-5 text-orange-700"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                      <p className="text-sm text-orange-800">
                        {userToNotify.name} was notified{' '}
                        {dayjs(mostRecentNudge).fromNow()}. Are you sure you
                        want to send another nudge?
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </DialogDescription>
      </DialogHeader>
      <DialogFooter className="">
        <Button
          color="white"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
          }}
          className="flex-1"
        >
          Cancel
        </Button>
        <Button
          className="flex-1"
          color="primary"
          disabled={status !== 'idle'}
          onClick={async (e) => {
            e.stopPropagation();
            // Handle nudge action
            setStatus('loading');
            try {
              await api.post(
                `approvals/requests/${currentApprovalRequest?.id}/nudge/${userToNotify.id}`,
                {},
              );
              setStatus('idle');
              setOpen(false);
              toast.success('Reminder sent to ' + userToNotify.name);
            } catch (error) {
              datadogRum.addError(error);
              setStatus('idle');
              showToast({
                type: 'error',
                title: `Error sending nudge`,
                subtitle: `Please contact support@dealops.com`,
              });
              return;
            }
          }}
        >
          {status === 'idle' ? (
            'Nudge'
          ) : status === 'loading' ? (
            <InlineSpinner />
          ) : status === 'success' ? (
            'Nudged!'
          ) : (
            'Nudge'
          )}
        </Button>
      </DialogFooter>
    </DialogContent>
  );
}

export default function NudgeButton(props: {
  userToNotify: UserWithStatus;
  currentApprovalRequest: ApprovalRequest;
}) {
  const [open, setOpen] = useState(false);
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <button
            className={
              'flex items-center gap-1 text-xs text-blue-600 hover:text-blue-800'
            }
          >
            <BellIcon className="h-3 w-3" />
            Nudge
          </button>
        </DialogTrigger>
        <NudgeModal
          userToNotify={props.userToNotify}
          currentApprovalRequest={props.currentApprovalRequest}
          setOpen={setOpen}
        />
      </Dialog>
    </div>
  );
}
