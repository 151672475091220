import { Float, FloatProps } from '@headlessui-float/react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import {
  ExclamationTriangleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { Fragment, MouseEventHandler } from 'react';
import { classNames } from 'src/dashboard/App';
import { unreachable } from 'src/typeUtils';

export type MenuItem = {
  name: string;
  icon?: MenuItemIcon;
  color?: MenuItemColor;
  onClick: MouseEventHandler<HTMLAnchorElement>;
};

type MenuItemColor = 'red';
type MenuItemIcon = 'trash' | 'warning';

type OverflowMenuProps = {
  menuItems: Array<MenuItem>;
  children?: React.ReactNode;
  placement?: FloatProps['placement'];
  offset?: FloatProps['offset'];
  fullWidth?: boolean;
};

export default function OverflowMenu({
  menuItems,
  children,
  placement,
  offset,
  fullWidth,
}: OverflowMenuProps) {
  if (menuItems.length === 0) {
    return null;
  }
  const defaultIcon = (
    <div>
      <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-fuchsia-800 focus:ring-offset-2 focus:ring-offset-gray-100">
        <span className="sr-only">Open options</span>
        <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
      </Menu.Button>
    </div>
  );

  return (
    <Menu
      as="div"
      className={classNames(
        'inline-block',
        !fullWidth && 'text-left',
        fullWidth && 'text-center w-full',
      )}
    >
      <Float placement={placement ?? 'right'} offset={offset}>
        {children ? (
          <Menu.Button
            onClick={(e) => e.stopPropagation()}
            className={classNames(fullWidth && 'w-full')}
          >
            {children}
          </Menu.Button>
        ) : (
          defaultIcon
        )}

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {menuItems.map((menuItem, idx) => {
                return (
                  <Menu.Item key={idx}>
                    {({ active }) => {
                      const IconComponent = (() => {
                        switch (menuItem.icon) {
                          case 'trash':
                            return TrashIcon;
                          case 'warning':
                            return ExclamationTriangleIcon;
                          case undefined:
                            return null;
                          default:
                            unreachable(menuItem.icon);
                        }
                      })();
                      const color = (() => {
                        switch (menuItem.color) {
                          case 'red':
                            return 'text-red-600';
                          case undefined:
                            return '';
                          default:
                            unreachable(menuItem.color);
                        }
                      })();
                      return (
                        <a
                          onClick={menuItem.onClick}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            `block px-4 py-2 text-sm ${color} flex items-center gap-x-2`,
                          )}
                        >
                          {IconComponent && (
                            <IconComponent
                              className={`h-4 w-4 flex-shrink-0`}
                            />
                          )}
                          {menuItem.name}
                        </a>
                      );
                    }}
                  </Menu.Item>
                );
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Float>
    </Menu>
  );
}
