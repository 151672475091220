import { isNil } from 'lodash';

interface PricingFlowDeletedProps {
  sfdcOpportunityId: string | undefined;
}
export default function PricingFlowDeleted(props: PricingFlowDeletedProps) {
  const { sfdcOpportunityId } = props;
  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen">
      <div className="text-l sm:text-2xl font-semibold text-center px-4">
        This quote has been deleted.
      </div>
      <a
        href={
          isNil(sfdcOpportunityId)
            ? `/app/opportunity`
            : `/app/opportunity/${sfdcOpportunityId}`
        }
        className="mb-6 sm:mb-8 mt-4 rounded-lg bg-fuchsia-900 px-3 py-2 text-xs sm:text-sm font-medium text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
      >
        Return to opportunity
      </a>
    </div>
  );
}
