import _, { isEqual, isNil, uniqBy } from 'lodash';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import api from 'src/api';
import { FullscreenSpinner } from 'src/components/Loading';
import { useToast } from 'src/components/Toast';
import { Organization, User } from '../../types';
import FlowProgressBar from './FlowProgressBar';
import {
  PenguinAdditionalData,
  PenguinPricingFlow,
} from './Penguin/penguin_types';
import Step1ProductsAndVolume from './Step1ProductsAndVolume';
import Step2Calculator from './Step2Calculator';
import {
  DealopsPricingFlow,
  PricingFlow,
  PricingFlowCommon,
  PricingFlowMutableFields,
  PricingFlowReadonlyFields,
  PricingFlowStage,
  PricingFlowType,
  PRICING_FLOW_MUTABLE_KEYS,
} from './types';

import { datadogRum } from '@datadog/browser-rum';
import {
  Location,
  Navigate,
  NavigateFunction,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useAnalyticsContext } from 'src/components/AnalyticsContext';
import { configForOrg } from '../Opportunity/OpportunityDetailPage';
import AlpacaPricingFlowPage from './Alpaca/AlpacaPricingFlowPage';
import {
  AlpacaAdditionalData,
  AlpacaPricingCurve,
  AlpacaPricingFlow,
  AlpacaProductPrice,
} from './Alpaca/alpaca_types';
import { addAllDerivedAggregationsToPricingFlow } from './Alpaca/alpaca_utils';
import { ApprovalRequest } from './Approvals/types';
import ComplexDemoPricingFlowPage from './ComplexDemo/ComplexDemoPricingFlowPage';
import HamsterPricingFlowPage from './Hamster/HamsterPricingFlowPage';
import PenguinPricingFlowPage from './Penguin/PenguinPricingFlowPage';
import pricingCurveRegistry from './Penguin/pricing_curve_registry';

export function navigateToPricingFlow({
  navigate,
  pricingFlowId,
  sfdcOpportunityId,
  skipReloadIfSameUrl,
  location,
}: {
  navigate: NavigateFunction;
  pricingFlowId: string;
  sfdcOpportunityId: string;
} & (
  | { skipReloadIfSameUrl: true; location: Location }
  | { skipReloadIfSameUrl?: false; location?: never }
)) {
  const targetUrl = `/app/opportunity/${sfdcOpportunityId}/pricingflow/${pricingFlowId}`;
  if (skipReloadIfSameUrl && location.pathname.includes(targetUrl)) {
    return;
  }
  navigate(targetUrl);
  window.scrollTo(0, 0);
}

export type PricingFlowModelType = 'pricingFlow' | 'pricingFlowSnapshot';
const IDLE_TIMEOUT_MS = 3 * 60 * 1000;

/** Returns a copy of a quote with only the specified products **/
function quoteWithProducts(quote: unknown, desiredProducts: string[]) {
  if (!quote || typeof quote !== 'object' || !('products' in quote)) {
    // Missing or broken quote, don't do anything
    return quote;
  }

  return { ...quote, products: _.pick(quote.products, desiredProducts) };
}

/** Returns a flow with the unused products removed from the quotes **/
function withoutUnusedProducts(flow: PricingFlowCommon) {
  const { products, recommendedQuote, manualQuote } = flow;

  if (!products) {
    // The types say this shouldn't happen, but it does at step 1, so let's
    // leave things as they are for now
    return flow;
  }
  const uniqueProducts = uniqBy(products, 'id');
  if (uniqueProducts.length < products.length) {
    datadogRum.addError(
      new Error(
        `found duplicate products to pricing flow ${flow.id}. They will be removed by this fallback, but it may indicate a bug in some other code`,
      ),
      { uniqueProducts, products },
    );
  }

  const remainingProductNames = uniqueProducts.map((p) => p.id ?? p.name);

  return {
    ...flow,
    products: uniqueProducts,
    manualQuote: quoteWithProducts(manualQuote, remainingProductNames),
    recommendedQuote: quoteWithProducts(
      recommendedQuote,
      remainingProductNames,
    ),
  };
}

const PricingFlowContext = createContext<
  | {
      pricingFlow: PricingFlowCommon;
      updateFlow: (
        pricingFlow: PricingFlowCommon,
        showLoading?: boolean,
      ) => void;
      setStage: (params: {
        stage: PricingFlowCommon['stage'] | null;
        customStage: string | null;
        otherAdditionalData?: object;
      }) => void;
      loading: boolean;
      restartInteractionTracking: (cause: InteractionCause) => void;
      editMode: boolean;
      currentApprovalRequest: ApprovalRequest | null;
      modelType: PricingFlowModelType;
    }
  | undefined
>(undefined);

function getPricingCurveForAlpacaProduct(
  productPrice: AlpacaProductPrice,
  pricingFlow: AlpacaPricingFlow,
): AlpacaPricingCurve {
  const curves = productPrice.pricingCurves;
  if (curves?.length > 0) {
    curves.sort((pcA, pcB) => {
      return pcA.priority - pcB.priority;
    });
    // find the relevant pricing curve
    for (const curve of curves) {
      if (pricingCurveRegistry.hasOwnProperty(curve.condition)) {
        // TODO(george) when we implement a real condition, this needs to take
        // in the pricing flow as an argument
        if (pricingCurveRegistry[curve.condition](pricingFlow)) {
          console.log(`activating ${curve.condition}`);
          return curve;
        }
      }
    }
    return curves[curves.length - 1];
  } else {
    datadogRum.addError(
      `Did not find pricing curves for ${productPrice.name} ${productPrice.id}`,
    );
    return {} as any;
  }
}

type CurrentAlpacaPricingCurves = { [productId: string]: AlpacaPricingCurve };
function getCurrentAlpacaPricingCurves(pricingFlow: AlpacaPricingFlow) {
  const productPrices = Object.values(
    pricingFlow.pricingSheetData.countryPricingSheets.us.productInfo,
  );
  return productPrices.reduce((acc, productPrice) => {
    acc[productPrice.id] = getPricingCurveForAlpacaProduct(
      productPrice,
      pricingFlow,
    );
    return acc;
  }, {} as CurrentAlpacaPricingCurves);
}

function getCurrentPricingCurves(pricingFlow: PricingFlowCommon) {
  switch (pricingFlow.type) {
    case PricingFlowType.ALPACA:
      return getCurrentAlpacaPricingCurves(pricingFlow as AlpacaPricingFlow);
    case PricingFlowType.PENGUIN:
    case PricingFlowType.COMPLEX_DEMO:
    case PricingFlowType.HAMSTER:
    case PricingFlowType.HAMSTER_FOR_DEMO:
    default:
      return {};
  }
}

function addDerivedAggregationsToPricingFlow(pricingFlow: PricingFlowCommon) {
  switch (pricingFlow.type) {
    case PricingFlowType.DEALOPS:
    case PricingFlowType.PENGUIN:
    case PricingFlowType.COMPLEX_DEMO:
    case PricingFlowType.HAMSTER:
    case PricingFlowType.HAMSTER_FOR_DEMO:
      return pricingFlow;
    case PricingFlowType.ALPACA:
      return addAllDerivedAggregationsToPricingFlow(
        pricingFlow as AlpacaPricingFlow,
      );
    default:
      datadogRum.addError(`Unexpected pricing flow type ${pricingFlow.type}`);
      return pricingFlow;
  }
}

export function usePricingFlowContext<
  T extends
    | PricingFlowCommon
    | PenguinPricingFlow
    | AlpacaPricingFlow
    | DealopsPricingFlow,
>() {
  const pricingFlowContext = useContext(PricingFlowContext);
  if (pricingFlowContext == null) {
    throw new Error(
      'You should not be using the PricingFlowContext outside of the provider',
    );
  }
  const { pricingFlow } = pricingFlowContext;
  return {
    ...pricingFlowContext,
    pricingFlow: pricingFlow as T,
  };
}

function usePricingFlow({
  pricingFlowOrSnapshotId,
  modelType,
  user,
}: {
  pricingFlowOrSnapshotId: string;
  modelType: PricingFlowModelType;
  user: User;
}) {
  const [loading, setLoading] = useState(true);
  const hasEditPermissions =
    user.permissions.includes('edit_pricing_flow') &&
    modelType === 'pricingFlow'; // snapshots are not editable
  const [editMode, setEditMode] = useState(hasEditPermissions);

  // This is the section of pricingFlow the user can update
  const [pricingFlowMutableFields, setPricingFlowMutableFields] =
    useState<PricingFlowMutableFields | null>(null);

  // This is the section of the pricingFlow the server calculates and isn't writable
  const [pricingFlowReadonlyFields, setPricingFlowReadonlyFields] =
    useState<PricingFlowReadonlyFields | null>(null);

  const [currentApprovalRequest, setCurrentApprovalRequest] =
    useState<ApprovalRequest | null>(null);

  const createAnalyticsEvent = useAnalyticsContext();
  const { showToast } = useToast();
  const [previousInteractionTimeCause, setPreviousInteractionTimeCause] =
    useState<InteractionCause | null>(null);

  const pricingFlow: PricingFlowCommon | null =
    pricingFlowReadonlyFields == null || pricingFlowMutableFields == null
      ? null
      : {
          ...pricingFlowMutableFields,
          ...pricingFlowReadonlyFields,
        };

  const { restartInteractionTracking } = useTrackInteractionTime({
    onInteractionFinished: (timeSpent: number, cause: InteractionCause) => {
      if (!pricingFlow?.id) return;
      const { activeTimeSpent, idleTimeSpent } = (() => {
        if (
          cause === 'external' &&
          (previousInteractionTimeCause === 'external' ||
            isNil(previousInteractionTimeCause))
        ) {
          return { activeTimeSpent: 0, idleTimeSpent: timeSpent };
        }
        // We cap the amount of time spent to reduce impact from situations where
        // e.g. they keep the window open in the background and don't do anything
        const activeTimeSpent = Math.min(timeSpent, IDLE_TIMEOUT_MS);
        const idleTimeSpent = timeSpent - activeTimeSpent;
        return { activeTimeSpent, idleTimeSpent };
      })();
      createAnalyticsEvent({
        name: 'pricing_flow__added_interaction_time',
        eventData: {
          pricing_flow_id: pricingFlow?.id,
          active_time_spent: activeTimeSpent,
          idle_time_spent: idleTimeSpent,
          cause,
          previous_cause: previousInteractionTimeCause,
        },
      });
      // send active time
      for (let attempts = 0, sent = false; !sent && attempts < 5; attempts++) {
        if (attempts > 0) {
          datadogRum.addError(`adding interaction time failed!`, {
            attempts,
            pricingFlowId: pricingFlow.id,
            activeTimeSpent,
            idleTimeSpent,
          });
        }
        // #AddInteractionTimeErrors
        sent = navigator.sendBeacon(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/pricingFlow/${pricingFlow.id}/addInteractionTime?interactionTime=${activeTimeSpent}&idleTime=${idleTimeSpent}`,
        );
      }
      setPreviousInteractionTimeCause(cause);
    },
    isReadyToTrack: Boolean(pricingFlow?.id),
  });

  const setPricingFlow = (incomingPricingFlow: PricingFlowCommon) => {
    console.log('add derived aggregations to pricing flow');
    const pricingFlow =
      addDerivedAggregationsToPricingFlow(incomingPricingFlow);
    const { readonlyFields, mutableFields } = splitPricingFlow(pricingFlow);
    setPricingFlowMutableFields(() => {
      return {
        ...mutableFields,
        currentPricingCurves: getCurrentPricingCurves(pricingFlow),
      };
    });
    setPricingFlowReadonlyFields(() => readonlyFields);
  };

  const splitPricingFlow = (pricingFlow: PricingFlowCommon) => {
    const mutableFields: PricingFlowMutableFields = _.pick(
      pricingFlow,
      PRICING_FLOW_MUTABLE_KEYS,
    );

    const readonlyFields: PricingFlowReadonlyFields = _.omit(
      pricingFlow,
      PRICING_FLOW_MUTABLE_KEYS,
    );

    return {
      mutableFields,
      readonlyFields,
    };
  };
  const navigate = useNavigate();

  useEffect(() => {
    let isCurrent = true;
    const fetchPricingFlow = async () => {
      try {
        const pricingFlowData = await (async () => {
          const existingPricingFlowRes = await (async () => {
            switch (modelType) {
              case 'pricingFlow':
                return await api.get('pricingFlow', {
                  pricingFlowId: pricingFlowOrSnapshotId,
                });
              case 'pricingFlowSnapshot':
                return await api.get(
                  `pricingFlowSnapshots/${pricingFlowOrSnapshotId}`,
                );
            }
          })();
          const { doesPricingFlowExist, pricingFlowData } =
            existingPricingFlowRes.data;
          if (doesPricingFlowExist) {
            console.log('Successfully fetched Pricing Flow: ', pricingFlowData);
            switch (modelType) {
              case 'pricingFlow':
                return {
                  ...pricingFlowData,
                  originalPricingFlowId: pricingFlowData.id,
                };
              case 'pricingFlowSnapshot':
                return {
                  ...pricingFlowData,
                  originalPricingFlowId: pricingFlowData.originalPricingFlow,
                };
            }
          } else {
            navigate('/app/opportunity');
            return null;
          }
        })();
        if (isCurrent && !isNil(pricingFlowData)) {
          setPricingFlow({
            ...pricingFlowData,
            currentPricingCurves: getCurrentPricingCurves(pricingFlowData),
          });
          let finalEditMode = hasEditPermissions;
          if (hasEditPermissions) {
            const pageConfig = configForOrg(pricingFlowData.type);
            if (pageConfig.hasApprovals) {
              // Check if this should actually be editMode=false because an approval request is out
              const approvalExists = await api.get(
                `approvals/flows/status?pricingFlowId=${pricingFlowData.id}`,
              );
              if (
                isCurrent &&
                !isNil(approvalExists.data?.currentApprovalRequest)
              ) {
                finalEditMode = false;
                setCurrentApprovalRequest(
                  approvalExists.data.currentApprovalRequest,
                );
              }
            }
          }
          setEditMode(finalEditMode);
        }
      } catch (getError) {
        datadogRum.addError(getError);
        console.error('unknown error on POST PricingFlow:', getError);
      } finally {
        if (isCurrent) {
          setLoading(false);
        }
      }
    };

    setLoading(true);
    fetchPricingFlow();
    return () => {
      isCurrent = false;
    };
  }, [pricingFlowOrSnapshotId]);

  async function setStage(params: {
    stage: PricingFlowCommon['stage'] | null;
    customStage: string | null;
    otherAdditionalData?: object;
  }) {
    const { stage, customStage, otherAdditionalData } = params;
    if (!editMode) {
      console.log('USING SET STAGE');
      setLoading(true);
      const newStage = stage ?? pricingFlow?.stage;

      const newCustomStage =
        // @ts-ignore
        customStage ?? pricingFlow?.additionalData?.customStage;
      setPricingFlow({
        ...pricingFlow,
        additionalData: {
          ...(pricingFlow?.additionalData as object),
          ...(otherAdditionalData ?? {}), // USE THIS SPARINGLY
          customStage: newCustomStage,
        },
        stage: newStage,
      } as PricingFlowCommon);
      setLoading(false);
      return;
    }
    setLoading(true);
    let pricingFlowCopy = { ...pricingFlow };
    pricingFlowCopy.pricingSheetData = {};

    const response = await api.put('pricingFlow/' + pricingFlow?.id, {
      ...pricingFlowCopy,
      stage,
    });
    setPricingFlow(response.data);
    setLoading(false);
  }

  async function updateFlow(
    newPricingFlowMutableFieldsRaw: PricingFlowMutableFields,
    showLoading: boolean = true,
  ) {
    if (showLoading) {
      setLoading(true);
    }
    const cause: InteractionCause = (() => {
      // if the pricing config was modified, mark the pricing flow as modified
      if (
        !isEqual(
          newPricingFlowMutableFieldsRaw.products,
          pricingFlowMutableFields?.products,
        ) ||
        !isEqual(
          newPricingFlowMutableFieldsRaw.manualQuote,
          pricingFlowMutableFields?.manualQuote,
        )
      ) {
        return 'modifiedPricingFlow';
      }
      switch (pricingFlow?.type) {
        case PricingFlowType.ALPACA: {
          const newAdditionalDataPricingConfig = _.omit(
            newPricingFlowMutableFieldsRaw.additionalData as
              | AlpacaAdditionalData
              | undefined,
            ['treasuryStep', 'customStage'],
          );
          const oldAdditionalDataPricingConfig = _.omit(
            pricingFlow.additionalData as AlpacaAdditionalData | undefined,
            ['treasuryStep', 'customStage'],
          );
          if (
            !isEqual(
              newAdditionalDataPricingConfig,
              oldAdditionalDataPricingConfig,
            )
          ) {
            return 'modifiedPricingFlow';
          }
          return 'viewingPricingFlow';
        }
        case PricingFlowType.PENGUIN: {
          const newAdditionalDataPricingConfig = _.omit(
            newPricingFlowMutableFieldsRaw.additionalData as
              | PenguinAdditionalData
              | undefined,
            ['customStage'],
          );
          const oldAdditionalDataPricingConfig = _.omit(
            pricingFlow.additionalData as PenguinAdditionalData | undefined,
            ['customStage'],
          );
          if (
            !isEqual(
              newAdditionalDataPricingConfig,
              oldAdditionalDataPricingConfig,
            )
          ) {
            return 'modifiedPricingFlow';
          }
          return 'viewingPricingFlow';
        }
        case PricingFlowType.DEALOPS:
        case PricingFlowType.COMPLEX_DEMO:
        case PricingFlowType.HAMSTER:
        case PricingFlowType.HAMSTER_FOR_DEMO:
          if (
            !isEqual(
              pricingFlow.additionalData,
              newPricingFlowMutableFieldsRaw.additionalData,
            )
          ) {
            return 'modifiedPricingFlow';
          }
          return 'viewingPricingFlow';
        default:
          return 'modifiedPricingFlow';
      }
    })();
    restartInteractionTracking(cause);

    const pricingFlowRaw = {
      ...newPricingFlowMutableFieldsRaw,
      ...pricingFlowReadonlyFields,
    } as PricingFlow;

    const newPricingFlowRaw = withoutUnusedProducts(pricingFlowRaw);
    setPricingFlow(newPricingFlowRaw);

    // when sending to server remove pricingSheetData because it's too big
    let pricingFlowCopy = { ...newPricingFlowRaw };
    pricingFlowCopy.pricingSheetData = {};

    try {
      const response = await api.put(
        'pricingFlow/' + newPricingFlowRaw?.id,
        pricingFlowCopy,
      );
      const newPricingFlow: PricingFlow = response.data;

      // we call setPricingFlow twice because in some flow types the put response adds extra
      // information we display to the user.
      const { readonlyFields } = splitPricingFlow(newPricingFlow);
      setPricingFlowReadonlyFields(readonlyFields);
    } catch (err) {
      datadogRum.addError(err);
      console.error(err);
      showToast({
        title: 'Error updating the quote',
        subtitle: 'Please contact support@dealops.com',
        type: 'error',
      });
    }
    if (showLoading) {
      setLoading(false);
    }
  }

  return {
    pricingFlow,
    updateFlow,
    setStage,
    loading,
    restartInteractionTracking,
    editMode,
    currentApprovalRequest,
    modelType,
  };
}

type InteractionCause =
  | 'external' // e.g. closing browser window, navigating to another page
  | 'viewingPricingFlow'
  | 'modifiedPricingFlow';
// hook for tracking how long long the pricingFlow has been interacted with.
function useTrackInteractionTime({
  onInteractionFinished,
  isReadyToTrack,
}: {
  onInteractionFinished: (timeSpent: number, cause: InteractionCause) => void;
  isReadyToTrack: boolean;
}) {
  let lastEventTimestamp = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (!isReadyToTrack) return;
    startTracking();
    window.addEventListener('load', startTracking); // page load
    window.addEventListener('focus', startTracking); // moving towards window
    window.addEventListener('blur', stopTrackingFromExternalCause); // moving away from window
    document.addEventListener('visibilitychange', handleVisibilityChange); // switching tabs
    window.addEventListener('beforeunload', stopTrackingFromExternalCause); // closing tab

    return () => {
      stopTracking('external');
      window.removeEventListener('load', startTracking);
      window.removeEventListener('focus', startTracking);
      window.removeEventListener('blur', stopTrackingFromExternalCause);
      window.removeEventListener('focus', stopTrackingFromExternalCause);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', stopTrackingFromExternalCause);
    };
  }, [isReadyToTrack]);

  function startTracking() {
    lastEventTimestamp.current = Date.now();
  }

  function stopTrackingFromExternalCause() {
    return stopTracking('external');
  }
  function stopTracking(cause: InteractionCause) {
    if (lastEventTimestamp.current !== undefined) {
      onInteractionFinished(Date.now() - lastEventTimestamp.current, cause);
      lastEventTimestamp.current = undefined;
    }
  }

  function handleVisibilityChange() {
    if (document.visibilityState === 'visible') {
      startTracking();
    }

    if (document.visibilityState === 'hidden') {
      stopTracking('external');
    }
  }

  function restartInteractionTracking(cause: InteractionCause) {
    if (lastEventTimestamp.current !== undefined) {
      stopTracking(cause);
      startTracking();
    }
  }

  return { restartInteractionTracking };
}

export function PricingFlowPage(props: {
  pricingFlowOrSnapshotId: string;
  modelType: PricingFlowModelType;
  user: User;
  organization: Organization;
  viewMode?: 'group';
}) {
  const {
    pricingFlow,
    updateFlow,
    setStage,
    loading,
    restartInteractionTracking,
    editMode,
    currentApprovalRequest,
    modelType,
  } = usePricingFlow({
    pricingFlowOrSnapshotId: props.pricingFlowOrSnapshotId,
    modelType: props.modelType,
    user: props.user,
  });
  const createAnalyticsEvent = useAnalyticsContext();
  // We only want to log the `pricing_flow__viewed` event twice: once while the
  // pricing flow is loading, and once after it has finished loading
  const [hasLoggedViewedEvent, setHasLoggedViewedEvent] = useState<{
    preLoad: boolean;
    postLoad: boolean;
  }>({ preLoad: false, postLoad: false });
  useEffect(() => {
    if (loading && !hasLoggedViewedEvent.preLoad) {
      createAnalyticsEvent({
        name: 'pricing_flow__viewed',
        eventData: {
          pricing_flow_id: pricingFlow?.id,
          is_loading: loading,
          edit_mode: editMode,
        },
      });
      setHasLoggedViewedEvent((hasLogged) => {
        return { ...hasLogged, preLoad: true };
      });
    }
    if (!loading && !hasLoggedViewedEvent.postLoad) {
      createAnalyticsEvent({
        name: 'pricing_flow__viewed',
        eventData: {
          pricing_flow_id: pricingFlow?.id,
          is_loading: loading,
          edit_mode: editMode,
        },
      });
      setHasLoggedViewedEvent((hasLogged) => {
        return { ...hasLogged, postLoad: true };
      });
    }
  }, [loading]);

  if (loading || pricingFlow === null) {
    return <FullscreenSpinner />;
  }

  let pricingFlowPage = null;
  switch (pricingFlow.type) {
    case PricingFlowType.ALPACA:
      pricingFlowPage = (
        <AlpacaPricingFlowPage
          organization={props.organization}
          // todo(seb): handle interactionTracking?
          user={props.user}
        />
      );
      break;
    case PricingFlowType.PENGUIN:
      pricingFlowPage = (
        <PenguinPricingFlowPage
          organization={props.organization}
          user={props.user}
        />
      );
      break;
    case PricingFlowType.COMPLEX_DEMO:
      pricingFlowPage = (
        <ComplexDemoPricingFlowPage
          organization={props.organization}
          user={props.user}
        />
      );
      break;
    case PricingFlowType.DEALOPS:
      // These need to be migrated to their own PricingFlowPage components
      pricingFlowPage = (
        <>
          <FlowProgressBar
            stage={pricingFlow?.stage}
            setStage={(stage) => setStage({ stage, customStage: null })}
          />
          {pricingFlow?.stage === PricingFlowStage.ADD_PRODUCTS ? (
            <Step1ProductsAndVolume
              pricingFlow={pricingFlow}
              updateFlow={updateFlow}
              setStage={(stage) => setStage({ stage, customStage: null })}
              user={props.user}
            />
          ) : null}

          {pricingFlow?.stage === PricingFlowStage.CALCULATE_PRICE ? (
            <Step2Calculator
              pricingFlow={pricingFlow}
              updateFlow={updateFlow}
              setStage={(stage) => setStage({ stage, customStage: null })}
            />
          ) : null}
        </>
      );
      break;
    case PricingFlowType.HAMSTER:
    case PricingFlowType.HAMSTER_FOR_DEMO:
      pricingFlowPage = (
        <HamsterPricingFlowPage
          organization={props.organization}
          user={props.user}
          viewMode={props.viewMode}
        />
      );
      break;
    default:
      pricingFlowPage = (
        <div>Unknown pricing flow type: {pricingFlow.type}</div>
      );
  }

  return (
    <PricingFlowContext.Provider
      value={{
        pricingFlow,
        updateFlow,
        setStage,
        loading,
        restartInteractionTracking,
        editMode,
        currentApprovalRequest,
        modelType,
      }}
    >
      {pricingFlowPage}
    </PricingFlowContext.Provider>
  );
}

export default function PricingFlowPageRoot(props: {
  user: User;
  organization: Organization;
}) {
  const { pricingFlowId } = useParams();
  return isNil(pricingFlowId) ? (
    <Navigate to="/app/opportunity" />
  ) : (
    <PricingFlowPage
      user={props.user}
      organization={props.organization}
      pricingFlowOrSnapshotId={pricingFlowId}
      modelType={'pricingFlow'}
    />
  );
}

export function PricingFlowSnapshotPageRoot(props: {
  user: User;
  organization: Organization;
}) {
  const { pricingFlowSnapshotId } = useParams();
  return isNil(pricingFlowSnapshotId) ? (
    <Navigate to="/app/opportunity" />
  ) : (
    <PricingFlowPage
      user={props.user}
      organization={props.organization}
      pricingFlowOrSnapshotId={pricingFlowSnapshotId}
      modelType={'pricingFlowSnapshot'}
    />
  );
}
